import React, { useState } from "react";

const SetNetWorthGoal = ({ budget, handleNext, state, handleChange }) => {
  function formatNumberWithCommas(number) {
    if (number != 0) {
      return parseFloat(number).toLocaleString();
    }
  }

  return (
    <div className="net-worth-calculator">
      <h1>Select net worth goal and time to reach it</h1>
      <div className="input-row">
        <label htmlFor="goal">Goal</label>
        <input
          id="goal"
          type="number"
          value={state.goal}
          onChange={handleChange}
        />
        {budget.currency}
      </div>
      <div className="input-row">
        <label htmlFor="time">Time</label>
        <input
          id="time"
          type="number"
          value={state.time}
          onChange={handleChange}
        />
        <select id="timeframe" value={state.timeframe} onChange={handleChange}>
          <option value="days">days</option>
          <option value="weeks">weeks</option>
          <option value="months">months</option>
          <option value="years">years</option>
        </select>
      </div>
      <button onClick={handleNext}>Next</button>
    </div>
  );
};

export default SetNetWorthGoal;
