import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePopUp } from "../../contexts/PopUpContext";

export default function CreateBusinessForm() {
  const { openPopUp } = usePopUp();
  const navigate = useNavigate();
  const [state, setState] = useState();
  const [companyLogo, setCompanyLogo] = useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    openPopUp(`Redirecting to Stripe set up form. Please wait.`);
    let uploadedImg = await uploadImage(companyLogo);
    console.log(uploadedImg);
    let res = await axios.post(
      "/api/v1/erp/business",
      {
        sector: state.sector,
        type: state.type,
        roles: ["Founder", "Director", "Employee"],
        name: state.name,
        description: state.description,
        domain: state.domain,
        address: state.address,
        email: state.email,
        logo: uploadedImg,
      },
      {
        withCredentials: true,
        credentials: "include",
      }
    );
    console.log(res.data);
    if (res.data.redirect) {
      //   navigate(res.data.redirect);
      window.location.href = res.data.redirect;
    }
  };

  async function uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const res = await axios.post(`/api/v1/upload-file`, formData, {
        withCredentials: true,
        credentials: "include",
      });
      return res.data.file;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(file);
    }
  };

  const imagePreview = companyLogo && (
    <div className="flex justify-around items-center m-2">
      <img
        src={URL.createObjectURL(companyLogo)}
        style={{ width: "70px", height: "auto" }}
      />
      <button
        className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
        onClick={() => removeImage(companyLogo)}
      >
        Remove
      </button>
    </div>
  );

  const removeImage = () => {
    setCompanyLogo("");
  };

  return (
    <div className="sm:h-screen mb-36">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <div>
          <h2 className="text-[#8e2421] font-extrabold text-xl md:text-3xl">
            Create new Business
          </h2>
          <form onSubmit={submit} className="mt-4">
            <div className="md:flex flex-row md:space-x-4 w-full">
              <div className="mb-3 space-y-2 w-full">
                <label className="font-semibold text-gray-600 py-2">
                  Business Name
                </label>
                <input
                  placeholder="Business Name"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                  type="text"
                  id="name"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="font-semibold text-gray-600">
                Business Logo
              </label>
              <div className="flex items-center py-2">
                {!companyLogo && (
                  <label htmlFor="logo" className="cursor-pointer">
                    <span className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg">
                      Choose File
                    </span>
                    <input
                      id="logo"
                      type="file"
                      required
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                )}
                {imagePreview}
              </div>
            </div>

            <div className="mb-3 space-y-2 w-full">
              <label className="font-semibold text-gray-600 py-2">
                Business Email
              </label>
              <input
                placeholder="business@email.com"
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                required="required"
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
              />
            </div>
            <div className="w-full flex flex-col mb-3">
              <label className="font-semibold text-gray-600 py-2">
                Business Address
              </label>
              <input
                placeholder="74 Stamford St, London, SE1 9NH"
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                type="text"
                name="address"
                onChange={handleChange}
                id="address"
                required
              />
            </div>
            <div className="w-full flex flex-col mb-3">
              <label className="font-semibold text-gray-600 py-2">
                Business Website
              </label>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <span className="text-gray-500 dark:text-gray-400 p2.5">
                    https://
                  </span>
                </div>
                <input
                  type="text"
                  onChange={handleChange}
                  id="domain"
                  className="border text-gray-900 rounded-lg focus:ring-[#8e2421] focus:border-[#8e2421] w-full ps-20 p-2.5"
                  placeholder="www.example.com"
                />
              </div>
              {/* <div className="w-full flex flex-col mb-3">
                <label className="font-semibold text-gray-600 py-2">
                  Business Type
                </label>
                <input
                  id="type"
                  placeholder="Type"
                  type="text"
                  onChange={handleChange}
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                  required
                />
              </div>
              <div className="w-full flex flex-col mb-3">
                <label className="font-semibold text-gray-600 py-2">
                  Business Sector
                </label>
                <input
                  id="sector"
                  placeholder="Sector"
                  type="text"
                  className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                  onChange={handleChange}
                  required
                />
              </div> */}
            </div>

            <div className="flex-auto w-full mb-1 space-y-2">
              <label className="font-semibold text-gray-600 py-2">
                Description
              </label>
              <textarea
                required
                id="description"
                className="min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg  py-4 px-4"
                placeholder="Enter your company info"
                spellCheck="true"
              ></textarea>
            </div>

            <div className="flex flex-row gap-4 items-center justify-center mt-6">
              <button
                className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                type="submit"
              >
                Confirm
              </button>
              <button
                onClick={() => navigate(-1)}
                className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
