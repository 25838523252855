import React, { useEffect, useState } from "react";
import { useCart } from "../../contexts/CartContext";
import {
  addPaymentMethod,
  attachPaymentMethod,
  confirmPaymentIntent,
  createCustomer,
  updatePaymentMethod,
} from "../../apiCalls/stripeCalls";
import { Link, useNavigate, useParams } from "react-router-dom";
import { usePopUp } from "../../contexts/PopUpContext";
import { useUser } from "../../contexts/UserContext";
import CreditCard from "../CreditCard/CreditCard";
import { bookSlots } from "../../apiCalls/bookingsCalls";
import axios from "axios";
import { useSocket } from "../../contexts/SocketContext";
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  AddressElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const { NODE_ENV, REACT_APP_PUBLIC_URL, REACT_APP_STRIPE_PK_KEY } = process.env;

const STRIPE_PK =
  NODE_ENV === "production"
    ? REACT_APP_STRIPE_PK_KEY
    : "pk_test_51JJBvLAlUKnrAXrlCRcnmrHowBvbotbZE0QvE7lw3wVDKagOWXhP89eR6B9hSDmak37gvXW1WeSVWIaAC33CgrH400rVd2IAmt";

const DOMAIN =
  NODE_ENV === "production" ? REACT_APP_PUBLIC_URL : "http://localhost:3000/";
console.log(DOMAIN);

const stripePromise = loadStripe(STRIPE_PK);

// Customize the appearance of Elements using the Appearance API.
const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#8e2421",
    borderRadius: "20px",
    fontFamily:
      "--body-font-family: -apple-system, BlinkMacSystemFont, sans-serif",
    colorBackground: "#fafafa",
  },
};

const PaymentForm = ({ email, shippingType }) => {
  const stripe = useStripe();
  const { clientSecret, handleAddress } = useUser();
  const elements = useElements();
  const navigate = useNavigate();
  const [messages, setMessages] = useState("");
  const [correctCountry, setCorrectCountry] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(correctCountry);
    console.log(shippingType);
    setMessages(`${messages} Submitting payment...`);
    console.log(DOMAIN);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${DOMAIN}checkout/4`,
      },
    });

    if (error) {
      // handle error
      setMessages(`${messages} ${error.message}`);
    }
    // if (correctCountry) {
    //   setMessages(`${messages} Submitting payment...`);
    //   console.log(DOMAIN);

    //   const { error } = await stripe.confirmPayment({
    //     elements,
    //     confirmParams: {
    //       return_url: `${DOMAIN}checkout/4`,
    //     },
    //   });

    //   if (error) {
    //     // handle error
    //     setMessages(`${messages} ${error.message}`);
    //   }
    // }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    // console.log("1- Checkout form use effect");
    // retrievePaymentIntent();
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      // console.log(paymentIntent);
      // console.log("2- Checkout form use effect");
      switch (paymentIntent.status) {
        case "succeeded":
          console.log("Payment succeeded");
          setMessages("Payment succeeded!");
          // deleteAllFromCart();
          // retrievePaymentIntent(paymentIntent.id);
          break;
        case "processing":
          // setMessages("Your payment is processing.");
          break;
        case "requires_payment_method":
          // setMessages("Your payment was not successful, please try again.");
          break;
        default:
          setMessages("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <div className="">
      <div className="sr-root w-full max-w-md mx-auto p-8">
        <div className="sr-main bg-white p-8 rounded-lg shadow-md border">
          <form onSubmit={handleSubmit}>
            <h3 className="text-xl text-gray-800 mb-4">Contact info</h3>
            <LinkAuthenticationElement
              // Access the email value like so:
              onChange={(event) => {
                console.log(event.value.email);
                // setEmail(event.value.email);
              }}
              //
              // Prefill the email field like so:
              options={{ defaultValues: { email: email } }}
            />

            {/* <h3 className="text-xl text-gray-800 my-4">Shipping address</h3>
            <AddressElement
              options={{ mode: "shipping", allowedCountries: ["UK"] }}
              // Access the address like so:
              onChange={(event) => {
                console.log(event);
                console.log(event.value.address.country);
                if (event.value.address.country != "GB") {
                  setMessages("Sorry we only deliver to UK.");
                } else {
                  setCorrectCountry(true);
                  handleAddress(event.value.address);
                }
              }}
            /> */}

            <h3 className="text-xl text-gray-800 my-4">Payment</h3>
            <PaymentElement />
            <hr className="m-2"></hr>
            <div className="flex justify-around items-center gap-4 my-4">
              <button
                className="flex flex-start bg-green-500 text-white text-sm leading-6 font-medium py-1 px-4 rounded-md hover:bg-green-400 z-0"
                type="submit"
              >
                Pay
              </button>
              <button
                className="flex flex-start bg-blue-500 text-white text-sm leading-6 font-medium py-1 px-4 rounded-md hover:bg-blue-400 z-0"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </div>
          </form>
          <hr></hr>
          <div id="messages">{messages}</div>
        </div>
      </div>
    </div>
  );
};

const Checkout = () => {
  const { step } = useParams();
  const { cart, emptyCart, removeOneFromCart, totalAmount } = useCart();
  const {
    user,
    updateUser,
    createGuestSession,
    signOut,
    createPaymentIntent,
    confirmPaymentIntent,
    clientSecret,
    order,
  } = useUser();
  const { openPopUp } = usePopUp();
  const socket = useSocket();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState();
  const [card, setCard] = useState();
  const [sale, setSale] = useState();
  const [transaction, setTransaction] = useState();
  const [billingDetails, setBillingDetails] = useState({
    name: "",
    email: "",
    address: {
      line1: "",
      city: "",
      state: "",
      postal_code: "",
    },
  });
  const [paymentIntent, setPaymentIntent] = useState();
  const [addNewCard, setAddNewCard] = useState(false);
  const [editBillingDetails, setEditBillingDetails] = useState(false);
  const [state, setState] = useState({
    type: "card",
    email: "", //"client@email.com",
    cardNumber: "4242424242424242",
    expMonth: 1,
    expYear: 2033,
    cvc: "314",
    line: "line",
    city: "city",
    country: "UK",
    postcode: "l123bw",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const payment_intent = params.get("payment_intent");

    console.log(payment_intent);

    if (payment_intent && step == 4) {
      // confirm payment intent
      confirmPaymentIntent(payment_intent);
    }
  }, []);

  const handleChange = (e) => {
    // console.log(e.target.id, e.target.value);
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod((prevPaymentMethod) => ({
      ...prevPaymentMethod,
      [event.target.id]: event.target.value,
    }));
  };

  const handleSelectPaymentMethod = (event) => {
    setPaymentMethod({ id: event.target.value });

    const selectedPaymentMethod = user.payment_methods.find(
      (method) => method.id === event.target.value
    );
    if (selectedPaymentMethod) {
      setBillingDetails(selectedPaymentMethod.billing_details);
      setCard(selectedPaymentMethod.card);
      setPaymentMethod(selectedPaymentMethod);
    }
  };

  const handleAddPaymentMethod = () => {
    setAddNewCard(!addNewCard);
  };

  const handleBillingDetailsChange = (event) => {
    const { id, value } = event.target;
    const updatedBillingDetails = { ...billingDetails };

    if (id.includes(".")) {
      const nestedProperties = id.split(".");
      let nestedObject = updatedBillingDetails;

      nestedProperties.forEach((property, index) => {
        if (index === nestedProperties.length - 1) {
          nestedObject[property] = value;
        } else {
          nestedObject = nestedObject[property];
        }
      });
    } else {
      updatedBillingDetails[id] = value;
    }

    // setBillingDetails((billingDetails) => ({
    //   ...billingDetails,
    //   [event.target.id]: event.target.value,
    // }));

    setBillingDetails(updatedBillingDetails);
  };

  const handleEditBillingDetails = () => {
    setEditBillingDetails(!editBillingDetails);
  };

  const checkPaymentMethod = async (e) => {
    e.preventDefault();
    if (
      isCreditCardValid(paymentMethod.number) &&
      paymentMethod.cvc.length === 3 &&
      (paymentMethod.exp_month.length === 2 ||
        paymentMethod.exp_month.length === 1) &&
      paymentMethod.exp_year.length === 4
    ) {
      let payment_method;
      let customerId;
      let res;
      // if there's no user OR user.demo, then create a new customer and save payment method to the customer
      if (!user || user.demo) {
        let { customer } = await createCustomer();
        customerId = customer.id;
      } else {
        customerId = user.customer_id;
      }
      res = await addPaymentMethod("card", paymentMethod);
      console.log(res);
      if (res.error) {
        openPopUp(res.error, 1000);
        handleAddPaymentMethod();
        return;
      }

      if (res.payment_method) {
        payment_method = res.payment_method;
        await attachPaymentMethod(payment_method.id, customerId);
        setPaymentMethod(payment_method);
        updateUser();
        // handleNextStep();
      } else {
        openPopUp("Sorry the card is not valid. Please try again", 1000);
      }
    } else {
      openPopUp("Sorry the card is not valid. Please try again", 1000);
    }
  };

  function isCreditCardValid(cardNumber) {
    // Remove any non-digit characters
    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");

    let sum = 0;
    let shouldDouble = false;

    // Start from the rightmost digit
    for (let i = sanitizedCardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(sanitizedCardNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;

        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    // Card number is valid if the sum is divisible by 10
    return sum % 10 === 0;
  }

  const checkBillingDetails = async (e) => {
    e.preventDefault();
    console.log(billingDetails);
    if (
      billingDetails.address.city &&
      billingDetails.address.line1 &&
      billingDetails.address.postal_code &&
      billingDetails.address.state &&
      billingDetails.email
    ) {
      let res = await updatePaymentMethod(paymentMethod.id, billingDetails);
      if (res.error) {
        openPopUp(res.error, 2000);
        return;
      }
      let { payment_method } = res;
      console.log(payment_method);
      setBillingDetails(billingDetails);
      setPaymentMethod(payment_method);
      updateUser();
      // handleNextStep();
    }
  };

  // const handleNextStep = () => {
  //   setStep((prevStep) => prevStep + 1);
  // };

  // const handlePreviousStep = () => {
  //   setStep((prevStep) => prevStep - 1);
  // };

  const handleConfirm = async () => {
    let paymentIntent, _sale, _transaction;
    let currency = "usd";
    let amount = (calculateTotal() * 100).toFixed(0);
    console.log(amount);
    if (user && !user.demo) {
      await attachPaymentMethod(paymentMethod.id, user.customer_id);
      let { payment_intent, sale, transaction } = await createPaymentIntent(
        amount,
        currency,
        paymentMethod,
        user.customer_id,
        cart
      );
      paymentIntent = payment_intent;
      _sale = sale;
      _transaction = transaction;
    } else {
      let { payment_intent, sale, transaction } = await createPaymentIntent(
        amount,
        currency,
        paymentMethod,
        paymentMethod.customer,
        cart
      );
      paymentIntent = payment_intent;
      _sale = sale;
      _transaction = transaction;
    }
    console.log(paymentIntent);
    setPaymentIntent(paymentIntent);

    // Perform the necessary actions for confirming the order
    let res = await confirmPaymentIntent(
      paymentIntent.id,
      _sale._id,
      _transaction._id
    );
    console.log(res);

    let response;
    let slot;
    if (res.payment_intent?.status === "succeeded") {
      console.log("Order confirmed!");
      let isBooking = false;
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].booking) {
          cart[i].data.push({ paymentIntent: res.payment_intent });
          cart[i].data.orderType = "booking";
          isBooking = true;
          for (let j = 0; j < cart[i].data.length; j++) {
            if (!cart[i].data[j].paymentIntent) {
              slot = cart[i].data[j];
            }
          }
          // bookSlots(cart[i].data);
          let { data } = await axios.get(`/api/bookings/get-active`);
          let booking = data;
          console.log(booking);
          let new_res = await axios.post(
            `/api/bookings/${booking._id}/book-slots`,
            {
              data: cart[i].data,
            }
          );
          console.log(new_res.data);
          if (new_res.data.ok) {
            socket.emit("bookingUpdate", new_res.data);
            openPopUp("Booking confirmed! Thank you for the purchase.", 3000);
          }
        }
      }
      if (order) {
        console.log(order);
        order.paymentIntents.push({
          id: res.payment_intent.id,
          paymentStep: order.currentStep,
        });
        await axios.post(`/api/orders/${order._id}/update-payment-intents`, {
          newOrder: order,
        });
        await axios.put(`/api/orders/${order._id}/client-accepts`);
        socket.emit("orderUpdate");
        openPopUp("Payment confirmed!", 3000);
      } else {
        let newOrder = {
          email: billingDetails.email,
          totalAmount: calculateTotal(),
          orderType: isBooking ? "booking" : "template",
          paymentIntents: [
            {
              id: res.payment_intent.id,
              paymentStep: 1,
            },
          ],
          slot,
        };
        response = await axios.post("/api/custom-order", {
          order: newOrder,
        });
        console.log(response);
        socket.emit("orderUpdate");
        if (!isBooking) {
          openPopUp(
            "Order confirmed! Thank you for the purchase. We hope you enjoy it!",
            3000
          );
        }
      }
      updateUser();
      setTimeout(() => {
        navigate(`/orders/${order ? order._id : response.data.new_order._id}`);
        emptyCart();
      }, 3000);
    } else {
      openPopUp("Something went wrong. Please try again", 3000);

      setTimeout(() => navigate("/checkout/1"), 3000);
    }
  };

  function calculateTotal() {
    let total = 0;
    cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total.toFixed(2);
  }

  // useEffect(() => {
  //   console.log(cart);
  //   // if (order) {
  //   //   console.log(order);
  //   //   cart.push(order);
  //   // } else
  //   if (cart.length == 0) {
  //     navigate("/marketplace");
  //   }
  // }, []);

  const renderStepContent = () => {
    switch (step) {
      case "1":
        return (
          <>
            {!user && (
              <div className="w-full max-w-md mx-auto p-8">
                <div className="bg-white p-8 rounded-lg shadow-md border">
                  {/* <div className="flex justify-around flex-col bg-white p-8 rounded-lg shadow-md border"> */}
                  <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    Checkout
                  </h1>
                  <div className="flex flex-col justify-center items-center p-6 bg-slate-50/40 rounded-lg">
                    <h2 className="text-lg font-semibold text-gray-700">
                      Please enter your Email
                    </h2>
                    <form
                      className="flex flex-col justify-center items-center"
                      onSubmit={async (e) => {
                        e.preventDefault();
                        createGuestSession(state.email);
                      }}
                    >
                      <input
                        className="w-48 sm:w-72"
                        id="email"
                        placeholder="Email"
                        onChange={handleChange}
                        type="email"
                        value={state.email}
                        required
                      />
                      <button
                        type="submit"
                        className="bg-green-500 text-white text-sm leading-6 font-medium py-1 px-4 rounded-md hover:bg-green-400 z-0"
                      >
                        Next
                      </button>
                    </form>
                  </div>
                  <h2 className="text-sm font-semibold text-gray-700 m-4">
                    - OR -
                  </h2>
                  <div className="flex justify-center items-center mx-8 bg-slate-50/40 rounded-xl">
                    <Link
                      to={"/sign-in"}
                      className="text-md p-4 text-[#8e2421] hover:text-[#8a4a47]"
                    >
                      sign in / up
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {user && (
              <div className="w-full max-w-md mx-auto p-8">
                <div className="bg-white p-8 rounded-lg shadow-md border">
                  <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    Checkout
                  </h1>
                  <p className="m-8">
                    Please make sure this is the right email:{" "}
                    <span className="text-[#8e2421] text-xl underline underline-offset-3 decoration-4 decoration-[#8e2421]">
                      {user.email}
                    </span>
                  </p>
                  <div className="flex flex-col justify-center items-center m-8 sm:flex-row sm:gap-4">
                    <button
                      onClick={async () => {
                        let currency = "gbp";
                        createPaymentIntent(cart, currency);
                        navigate("/checkout/2");
                      }}
                      className="bg-green-500 text-white text-sm leading-6 font-medium py-1 px-4 mt-4 rounded-md hover:bg-green-400 z-0"
                    >
                      Yes, next
                    </button>
                  </div>
                  <button
                    className="bg-blue-500 text-white text-sm leading-6 font-medium py-1 px-4 mt-4 rounded-md hover:bg-blue-400 z-0"
                    onClick={signOut}
                  >
                    Change email
                  </button>
                </div>
              </div>
            )}
          </>
        );
      case "2":
        return (
          <div className="w-full max-w-3xl mx-auto p-8">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <div className="mb-6">
                <p className="text-lg mb-2">
                  Email:{" "}
                  <span className="text-[#8e2421] font-semibold">
                    {user.email}
                  </span>
                </p>
                <p className="text-lg mb-4">
                  Total Cart:
                  <span className="text-[#8e2421] font-semibold">
                    £ {(Number(totalAmount) / 100).toFixed(2)}
                  </span>
                </p>
                <Link
                  to="/cart"
                  className="inline-block bg-white border border-[#8e2421] rounded-lg text-[#8e2421] px-4 py-2 hover:text-white hover:bg-[#8e2421] transition-colors duration-300"
                >
                  Edit Cart
                </Link>
              </div>
              <div className="space-y-4">
                {cart &&
                  cart.map((item) => (
                    <div
                      key={item.id}
                      className="bg-gray-50 p-4 rounded-md shadow-sm"
                    >
                      <p className="text-lg mb-1">
                        Item:{" "}
                        <span className="text-[#8e2421] font-semibold">
                          {item.name}
                        </span>
                      </p>
                      <p className="text-lg">
                        Quantity:{" "}
                        <span className="text-[#8e2421] font-semibold">
                          {item.quantity}
                        </span>
                      </p>
                      <p className="text-lg">
                        Price:
                        <span className="text-[#8e2421] font-semibold">
                          £ {(Number(item.price) / 100).toFixed(2)}
                        </span>
                      </p>
                    </div>
                  ))}
              </div>
              <div className="mt-6 text-right">
                <button
                  onClick={async () => {
                    navigate("/checkout/3");
                  }}
                  className="bg-[#8e2421] text-white text-sm leading-6 font-medium py-2 px-4 rounded-md hover:bg-[#6c1b1b] transition-colors duration-300"
                >
                  Confirm Cart
                </button>
              </div>
            </div>
          </div>
        );
      case "3":
        if (clientSecret) {
          console.log(clientSecret);
          return (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance }}
            >
              <PaymentForm email={state.email} />
            </Elements>
          );
        }
      case "4":
        return (
          <div className="h-96 flex justify-center items-center mt-20 md:mt-0 text-center">
            <div className="w-full max-w-md mx-auto p-8">
              <div className="bg-white p-8 rounded-lg shadow-md border">
                <p className="text-xl font-bold text-gray-800 mb-4">
                  Thank you for your purchase.
                </p>
                <p>
                  Keep an eye on the order {" =>"}
                  <Link
                    className="bg-white-500 border-2 border-[#8e2421] rounded-lg text-[#8e2421] px-3 ml-4 hover:text-[#6ac174] hover:border-[#6ac174]"
                    to={`/orders/${order._id}`}
                  >
                    here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  return <div className="text-center m-4 md:mt-12">{renderStepContent()}</div>;
};

export default Checkout;
