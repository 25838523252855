import React from "react";
import { usePopUp } from "../../contexts/PopUpContext";
import { deleteUser } from "../../apiCalls/userCalls";
import { useUser } from "../../contexts/UserContext";

const PopUp = () => {
  const {
    message,
    isPopupOpen,
    cookieSettings,
    confirmation,
    handleConfirmation,
    closePopUp,
  } = usePopUp();
  const { updateUser } = useUser();

  return (
    <>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6">
            <div className="text-center">
              <p className="text-lg font-medium mb-4">{message}</p>
              {cookieSettings && (
                <button
                  className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold hover:bg-[#8b4341] transition duration-300"
                  onClick={() => {
                    localStorage.setItem(
                      "userAcceptedNecessaryCookies",
                      "true"
                    );
                    closePopUp();
                  }}
                >
                  Accept
                </button>
              )}
              {confirmation && (
                <div className="flex justify-center space-x-4">
                  <button
                    className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold  hover:bg-[#8b4341] transition duration-300"
                    onClick={() => {
                      handleConfirmation();
                      // deleteUser();
                      // updateUser();
                      closePopUp();
                    }}
                  >
                    Confirm
                  </button>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-full font-bold hover:bg-blue-600 transition duration-300"
                    onClick={closePopUp}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PopUp;
