import React from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function FilePage() {
  const { filePath } = useParams();
  return (
    <div className="content">
      <img src={filePath} style={{ height: "70vh" }}></img>
    </div>
  );
}
