import React, { useEffect, useRef, useState } from "react";

const { REACT_APP_PUBLIC_URL } = process.env;

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : REACT_APP_PUBLIC_URL;

const EditDesignArea = ({
  handleSetDesignArea,
  img,
  designAreaSet,
  side,
  handleAddCustomItem,
  designArea,
}) => {
  const [state, setState] = useState({
    front: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    back: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    right: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    left: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
  });
  const [src, setSrc] = useState("");

  const handleClick = () => {
    handleSetDesignArea(side);
  };

  const handleSaved = (bool, coords, percCoords) => {
    setState((prevState) => ({
      ...prevState,
      [side]: {
        backgroundImg: img,
        saved: bool,
        designArea: coords,
        percCoords,
      },
    }));
    const customItem = {
      side,
      backgroundImg: img,
      saved: bool,
      designArea: coords,
      percCoords,
    };
    handleSetDesignArea(side);
    handleAddCustomItem(customItem);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      [side]: {
        backgroundImg: img,
        saved: true,
        designArea: designArea,
      },
    }));
  }, [img, designArea, side]);

  const imagePreview = img && (
    <div className="flex justify-around items-center m-2 h-full w-full">
      <DrawRect handleSaved={handleSaved} designArea={state[side].designArea}>
        <img
          src={PUBLIC_URL + img}
          alt="Preview"
          className="h-full w-full object-cover"
        />
      </DrawRect>
    </div>
  );

  return (
    <div>
      <button
        type="button"
        onClick={handleClick}
        className={`${
          img ? "" : "cursor-not-allowed disabled"
        } font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg`}
      >
        Set Design Area
      </button>
      <CustomModal
        open={designAreaSet}
        handleClose={handleClick}
        imagePreview={imagePreview}
      />
    </div>
  );
};

export default EditDesignArea;

function CustomModal({ open, handleClose, imagePreview }) {
  return (
    <>
      {open && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="flex flex-col flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                  <h5
                    className="text-xl font-medium leading-normal text-surface dark:text-white"
                    id="exampleModalCenteredScrollableLabel"
                  >
                    Set Design Area
                  </h5>
                  <div className="h-full w-full">{imagePreview}</div>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                  >
                    Close Design Area
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const DrawRect = ({ children, handleSaved, designArea }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawingActive, setDrawingActive] = useState(false);
  const [rectCoords, setRectCoords] = useState({
    startX: 0,
    startY: 0,
    endX: 0,
    endY: 0,
  });
  const [percCoords, setPercCoords] = useState(
    designArea || {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    }
  );
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);

  const setCanvasSize = (canvas) => {
    if (canvas) {
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
    }
  };

  const drawRectangle = (rect, style) => {
    ctxRef.current.clearRect(
      0,
      0,
      ctxRef.current.canvas.width,
      ctxRef.current.canvas.height
    );
    ctxRef.current.beginPath();
    ctxRef.current.strokeStyle = style.borderColor;
    ctxRef.current.lineWidth = style.borderWidth;
    ctxRef.current.strokeRect(
      rect.startX,
      rect.startY,
      rect.endX - rect.startX,
      rect.endY - rect.startY
    );
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      ctxRef.current = canvas.getContext("2d");
      setCanvasSize(canvas);
      const parentWidth = canvas.clientWidth;
      const parentHeight = canvas.clientHeight;
      const top = (rectCoords.startY / parentHeight) * 100;
      const left = (rectCoords.startX / parentWidth) * 100;
      const width = ((rectCoords.endX - rectCoords.startX) / parentWidth) * 100;
      const height =
        ((rectCoords.endY - rectCoords.startY) / parentHeight) * 100;
      setPercCoords({ top, left, width, height });
      const style = { borderColor: "#ff0000", borderWidth: 2 };
      drawRectangle(rectCoords, style);
    }
  }, [rectCoords]);

  /**
    startx 119.5  => left 119.5/432 = 27%
    starty 44.5   => top  44.5/432  = 10%
    endx 309.5
    endy 403.5

    width 190     => 190/432 = 44%
    height 359    => 359/432 = 83%

    canvas 432x432
   */

  useEffect(() => {
    const canvas = canvasRef.current;
    ctxRef.current = canvas.getContext("2d");
    setCanvasSize(canvas);
    if (designArea) {
      const style = { borderColor: "#ff0000", borderWidth: 2 };
      const parentWidth = canvas.clientWidth;
      const parentHeight = canvas.clientHeight;
      const height = (designArea.height / 100) * parentHeight;
      const top = (designArea.top / 100) * parentHeight;
      const left = (designArea.left / 100) * parentWidth;
      const width = (designArea.width / 100) * parentWidth;
      console.log(top, left, width, height);
      ctxRef.current.clearRect(
        0,
        0,
        ctxRef.current.canvas.width,
        ctxRef.current.canvas.height
      );
      ctxRef.current.beginPath();
      ctxRef.current.strokeStyle = style.borderColor;
      ctxRef.current.lineWidth = style.borderWidth;
      ctxRef.current.strokeRect(left, top, width, height);
    }
  }, [designArea]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setRectCoords({
        startX: offsetX,
        startY: offsetY,
        endX: offsetX,
        endY: offsetY,
      });
      setIsDrawing(true);
      setDrawingActive(true);
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setRectCoords((prevCoords) => ({
        ...prevCoords,
        endX: offsetX,
        endY: offsetY,
      }));
    }
  };

  return (
    <>
      <div className="absolute top-5 flex flex-row gap-2">
        <button
          type="button"
          className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          onClick={() => {
            setRectCoords({
              startX: 0,
              startY: 0,
              endX: 0,
              endY: 0,
            });
            setDrawingActive(true);
          }}
        >
          Clear area
        </button>
        <button
          type="button"
          className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          onClick={() => {
            handleSaved(true, rectCoords, percCoords);
            setDrawingActive(false);
          }}
        >
          Save area
        </button>
      </div>
      <div className="relative min-h-full w-full">
        {children}
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className="absolute top-0 left-0 cursor-crosshair h-full w-full"
        />
      </div>
      <div className="absolute flex flex-col bottom-0 left-0 p-2 bg-[#fff]">
        {drawingActive ? (
          <span>Drawing . . .</span>
        ) : (
          <span>Not Drawing . . .</span>
        )}
        <span>Start X: {rectCoords.startX}</span>
        <span>Start Y: {rectCoords.startY}</span>
        <span>End X: {rectCoords.endX}</span>
        <span>End Y: {rectCoords.endY}</span>
      </div>
    </>
  );
};
