import React, { useEffect, useState } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import { usePopUp } from "../../contexts/PopUpContext";
import { useUser } from "../../contexts/UserContext";
import AdminCalendar from "./AdminCalendar";
import axios from "axios";
import moment from "moment";
import { useSocket } from "../../contexts/SocketContext";

const BookingCalendar = () => {
  const { addMultipleToCart } = useCart();
  const { user } = useUser();
  const { openPopUp } = usePopUp();
  const socket = useSocket();
  const navigate = useNavigate();

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState();
  const [weekDays, setWeekDays] = useState([]);

  const [booking, setBooking] = useState();
  const [availableSlots, setAvailableSlots] = useState();

  const [totalAmount, setTotalAmount] = useState(0);

  const allSlots = [
    {
      day: "Monday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Tuesday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Wednesday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Thursday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Friday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Saturday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Sunday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
  ];

  async function getBooking() {
    let res = await axios.get(`/api/bookings/get-active`);
    console.log(res.data.notAvailableSlots);
    setBooking(res.data);
    if (res.data.availableSlots) {
      setAvailableSlots(res.data.availableSlots);
    }
  }

  const getCurrentWeek = () => {
    const today = new Date();
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const slicedDays = daysLabels
      .slice(startOfWeek)
      .concat(daysLabels.slice(0, startOfWeek));
    const monthLength = getMonthLength(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth()
    );
    const diff = monthLength - startOfWeek.getDate();
    let endOfWeek,
      weekDays = [],
      days = [];

    switch (true) {
      case diff >= 7:
        endOfWeek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 7
        );
        for (let i = 0; i < 7; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        break;
      case diff === 6:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        for (let i = 0; i < 6; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        days.push({
          day: 6,
          date: new Date(today.getFullYear(), today.getMonth() + 1, 1),
        });
        break;
      case diff === 5:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 2);
        for (let i = 0; i < 5; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 2; i++) {
          days.push({
            day: i + 5,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 4:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 3);
        for (let i = 0; i < 4; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 3; i++) {
          days.push({
            day: i + 4,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 3:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 4);
        for (let i = 0; i < 3; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 4; i++) {
          days.push({
            day: i + 3,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 2:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 5);
        for (let i = 0; i < 2; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 5; i++) {
          days.push({
            day: i + 2,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 1:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 6);
        for (let i = 0; i < 1; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 6; i++) {
          days.push({
            day: i + 2,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      default:
        console.log(`Default diff: ${diff}`);
        break;
    }

    let mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  function getMonthLength(year, month) {
    const date = new Date(year, month, 1);
    date.setMonth(date.getMonth() + 1, 0);
    const monthLength = date.getDate();

    return monthLength;
  }

  const getNextWeekEndStart = (prevEnd) => {
    let startDate = calculateNextStartDate(prevEnd);
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let days = [],
      weekDays = [];

    const firstDayOfWeek = startDate.getDay();
    const slicedDays = daysLabels
      .slice(firstDayOfWeek)
      .concat(daysLabels.slice(0, firstDayOfWeek));

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + i
      );
      days.push({
        day: i + 1,
        date: currentDate,
      });
    }

    const mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  const getPrevWeekEndStart = (prevStart) => {
    let startDate = calculatePrevStartDate(prevStart);
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let days = [],
      weekDays = [];
    const firstDayOfWeek = prevStart.getDay();
    const slicedDays = daysLabels
      .slice(firstDayOfWeek)
      .concat(daysLabels.slice(0, firstDayOfWeek));

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + i
      );
      days.push({
        day: i + 1,
        date: currentDate,
      });
    }

    const mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  const calculatePrevStartDate = (prevStart) => {
    const startDate = new Date(
      prevStart.getFullYear(),
      prevStart.getMonth(),
      prevStart.getDate() - 7
    );
    return startDate;
  };

  const calculateNextStartDate = (prevEnd) => {
    const startDate = new Date(
      prevEnd.getFullYear(),
      prevEnd.getMonth(),
      prevEnd.getDate() + 1
    );
    return startDate;
  };

  const handleSlotClick = (day, slot, date, formattedDate) => {
    const [hourStr, minuteStr] = slot.split(":");
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const modifiedDate = new Date(date);
    modifiedDate.setHours(hour);
    modifiedDate.setMinutes(minute);

    const selectedSlot = {
      day,
      slot,
      date,
      formattedDate: formatDate(modifiedDate) + " " + slot,
    };
    const isSlotSelected = selectedSlots.some(
      ({ day: selectedDay, slot: selectedSlot, date: selectedDate }) =>
        day === selectedDay &&
        slot === selectedSlot &&
        date.getTime() === selectedDate.getTime()
    );
    if (isSlotSelected) {
      // If the slot is already selected, remove it from the selectedSlots
      setSelectedSlots((prevSelectedSlots) => {
        return prevSelectedSlots.filter(
          ({ day: selectedDay, slot: selectedSlot, date: selectedDate }) => {
            return (
              day !== selectedDay ||
              slot !== selectedSlot ||
              date.getTime() !== selectedDate.getTime()
            );
          }
        );
      });
    } else {
      // If the slot is not selected, add it to the selectedSlots
      setSelectedSlots((prevSelectedSlots) => [
        ...prevSelectedSlots,
        selectedSlot,
      ]);
    }
  };

  const calculateTotalAmount = () => {
    setTotalAmount(selectedSlots.length * 50);
  };

  const handlePreviousWeek = () => {
    const weekDays = getPrevWeekEndStart(selectedWeek.start);
    const newSelectedWeek = {
      start: weekDays.start,
      end: weekDays.end,
    };

    setSelectedWeek(newSelectedWeek);
    setWeekDays(weekDays);
  };

  const handleNextWeek = () => {
    const weekDays = getNextWeekEndStart(selectedWeek.end);
    const newSelectedWeek = {
      start: weekDays.start,
      end: weekDays.end,
    };

    setSelectedWeek(newSelectedWeek);
    setWeekDays(weekDays);
  };

  const isDatePassed = (date) => {
    const today = new Date();
    // today.setHours(19);
    // console.log(today, date);
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      date
    );
    return date.getTime() < today.getTime();
  };

  const formatDate = (date) => {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayName = dayNames[date.getDay()];
    const dayNumber = date.getDate();
    const suffix = getOrdinalSuffix(dayNumber);

    const abbreviatedDayName = dayName.slice(0, 3);
    const formattedDate = `${abbreviatedDayName} ${dayNumber}${suffix}`;

    return formattedDate;
  };

  const getOrdinalSuffix = (number) => {
    const lastDigit = Number(number) % 10;
    const lastTwoDigits = Number(number) % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return "th";
    }

    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const renderCalendar = () => {
    return (
      <div className="calendar">
        {weekDays.map(({ day, date, slots, formattedDate }) => (
          <div key={day} className="day">
            <span className="day-label">{formatDate(date)}</span>
            <div className="day-column">
              {slots.map((slot) => {
                const [hourStr, minuteStr] = slot.split(":");
                const hour = parseInt(hourStr, 10);
                const minute = parseInt(minuteStr, 10);
                date.setHours(hour);
                date.setMinutes(minute);

                const isSelected = selectedSlots.find(
                  ({
                    day: selectedSlotDay,
                    slot: selectedSlot,
                    date: selectedSlotDate,
                  }) =>
                    selectedSlotDate.getTime() === date.getTime() &&
                    slot === selectedSlot &&
                    day === selectedSlotDay
                );

                let isAvailable = availableSlots?.find(
                  ({ day: availableDay, slots: availableSlots }) =>
                    day === availableDay && availableSlots.includes(slot)
                );

                booking?.notAvailableSlots?.forEach((notAvSlot) => {
                  let d = new Date(notAvSlot.date);
                  if (date.toString() === d.toString()) {
                    isAvailable = false;
                  }
                });

                const colorClass =
                  isSelected && !isDatePassed(date)
                    ? "green"
                    : isAvailable && !isDatePassed(date)
                    ? "blue"
                    : "red";

                const handleClick = () => {
                  const [hourStr, minuteStr] = slot.split(":");
                  const hour = parseInt(hourStr, 10);
                  const minute = parseInt(minuteStr, 10);
                  date.setHours(hour);
                  date.setMinutes(minute);
                  if (isAvailable && !isDatePassed(date)) {
                    handleSlotClick(day, slot, date, formattedDate);
                  }
                };

                return (
                  <div
                    key={`${day}-${slot}`}
                    className={`slot ${colorClass}`}
                    onClick={handleClick}
                  >
                    {slot}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleConfirm = () => {
    if (selectedSlots.length >= 1) {
      addMultipleToCart(3, selectedSlots.length, selectedSlots);
      openPopUp(
        "Your slots have been added to the cart. Please follow checkout process to finally book them.",
        1000
      );
      navigate("/checkout/1");
    } else {
      openPopUp("Please select a slot first.", 750);
    }
  };

  const clearSlots = () => {
    setSelectedSlots([]);
  };

  const removeSlot = (index) => {
    if (index > -1 && index < selectedSlots.length) {
      const updatedSlots = [...selectedSlots];
      updatedSlots.splice(index, 1);
      setSelectedSlots(updatedSlots);
    }
  };

  useEffect(() => {
    getBooking();
    socket?.on("booking_update", (data) => {
      // Handle the updated order status here
      getBooking();
    });
    calculateTotalAmount();
    if (!selectedWeek) {
      const week = getCurrentWeek();
      setSelectedWeek(week);
      setWeekDays(week);
      // console.log(week);
    } else {
      // console.log(selectedSlots);
      // console.log(weekDays);
    }
  }, [selectedWeek, selectedSlots]);

  const DateComponent = ({ date }) => {
    const today = moment().startOf("day");
    const givenDate = moment(date).startOf("day");
    const isToday = today.isSame(givenDate);

    return (
      <h3
        className={`text-lg font-medium p-1 rounded-xl ${
          isToday ? "bg-[#8e2421] text-white pl-3" : "text-gray-700"
        }`}
      >
        {moment(date).format("D") +
          getOrdinalSuffix(moment(date).format("D")) +
          " " +
          moment(date).format("MMM")}
      </h3>
    );
  };

  if (!user || !user.admin) {
    return (
      <div className="p-6 mr-4 md:mx-6 bg-white rounded-lg shadow-lg mt-4 mb-24">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          Booking Calendar
        </h2>
        <div className="flex justify-between items-center mb-4">
          <button
            className="text-[#8e2421] hover:text-[#8b4341]"
            onClick={handlePreviousWeek}
          >
            <BsFillArrowLeftCircleFill size={30} />
          </button>
          <button
            className="text-[#8e2421] hover:text-[#8b4341]"
            onClick={handleNextWeek}
          >
            <BsFillArrowRightCircleFill size={30} />
          </button>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-4">
          {weekDays.map(({ day, date, slots }, index) => (
            <div
              key={index}
              className="border p-4 rounded-lg shadow-sm flex flex-col"
            >
              <div className="mb-2">
                <DateComponent date={date} />
              </div>
              <div className="flex flex-wrap gap-2">
                {slots.map((slot, slotIndex) => {
                  const [hourStr, minuteStr] = slot.split(":");
                  const hour = parseInt(hourStr, 10);
                  const minute = parseInt(minuteStr, 10);
                  date.setHours(hour);
                  date.setMinutes(minute);

                  const isSelected = selectedSlots.find(
                    (s) =>
                      s.date.getTime() === date.getTime() &&
                      s.slot === slot &&
                      s.day === day
                  );

                  const colorClass = isSelected
                    ? "bg-[#8e2421] text-white"
                    : "bg-[#fff] text-[#8e2421] border-solid border-2 border-[#8e2421] hover:text-white hover:bg-[#8e2421]";

                  const handleClick = () => {
                    handleSlotClick(day, slot, date, date.toLocaleDateString());
                  };

                  return (
                    <button
                      key={slotIndex}
                      className={`min-w-full sm:w-auto py-2 px-4 rounded font-extrabold ${colorClass}`}
                      onClick={handleClick}
                    >
                      {slot}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 flex place-items-start justify-end space-x-4">
          <div className="flex flex-wrap">
            {selectedSlots.map((slot, index) => (
              <div className="p-2 m-2 rounded-3xl font-extrabold bg-[#fff] text-[#8e2421] border-solid border-2 border-[#8e25217d]">
                <span>{slot.formattedDate}</span>
                <button
                  className="bg-[#8e2421] m-2 text-[#fff] p-2 rounded-xl border-solid border-2 border-[#8e2421] hover:text-white hover:bg-[#8e2421]"
                  onClick={() => removeSlot(index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          <button
            className="bg-[#8e2421] text-white py-2 px-4 rounded hover:bg-[#8b4341]"
            onClick={handleConfirm}
          >
            Proceed to Pay (£ {totalAmount})
          </button>
        </div>
      </div>
    );
  } else {
    return <AdminCalendar weekDays={weekDays} />;
  }
};

export default BookingCalendar;
