import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { signIn } from "../../apiCalls/userCalls";
import CreditCard from "../CreditCard/CreditCard";
import PayPalCheckout from "./PayPalCheckout";
import { updatePaymentMethod } from "../../apiCalls/stripeCalls";
import { usePopUp } from "../../contexts/PopUpContext";
import Checkout from "./Checkout";
import { useCart } from "../../contexts/CartContext";

const CustomCheckout = () => {
  const { orderId, step } = useParams();
  const { user } = useUser();
  const { cart, addToCart } = useCart();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  // const [step, setStep] = useState(1);
  const [signInForm, setSignInForm] = useState(false);
  const [signUpForm, setSignUpForm] = useState(false);
  const [state, setState] = React.useState();
  const [stepSignUp, setStepSignUp] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [connectMetamask, setConnectMetamask] = useState(false);
  const [username, setUsername] = useState("");

  const handleNext = () => {
    setStepSignUp(stepSignUp + 1);
  };

  const handleBack = () => {
    setStepSignUp(stepSignUp - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can perform form validation or submit the data to an API here
    // For simplicity, this example does not include form validation logic
    if (stepSignUp === 1) {
      handleNext();
    } else if (stepSignUp === 2) {
      if (connectMetamask) {
        handleNext();
      } else {
        // Handle case when user doesn't want to connect Metamask
        // You can add your logic here
      }
    } else if (stepSignUp === 3) {
      // Handle form submission for the third step
      // You can add your logic here
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    // Simulate fetching the order details from the backend using orderId
    // For demonstration purposes, assume the server returns an order object
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`/api/orders/${orderId}`);
        // response.data.price = 1;
        response.data.totalAmount = response.data.totalAmount / 3;
        addToCart(4, response.data);
        setOrder(response.data);
        console.log(cart);
      } catch (error) {
        // Handle error
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handlePaymentMethodSelection = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);

    // Assuming here you'll send the order and payment details to the backend
    // for final processing and payment confirmation.
    // Make an API call to complete the checkout process.
    // The backend should handle order and payment validation.
    // Once the payment is successful, the user will be redirected to a success page.
    // The actual implementation of the backend is beyond the scope of this example.
    // You may use tools like Axios to make API calls.
    // Example: axios.post('/api/checkout', { orderId, paymentMethod });
  };

  const handleConfirmOrder = () => {
    // Assuming the order is already saved in the database and associated with the user.
    // Proceed to payment processing and complete the checkout.
    // The actual implementation of the backend is beyond the scope of this example.
    // You may use tools like Axios to make API calls.
    // Example: axios.post('/api/checkout', { orderId, paymentMethod });
    // Once the payment is successful, the user will be redirected to a success page.
  };

  if (!order) {
    return (
      <div className="content">
        Order can't be found. Please
        <Link to="/contact-us" className="button">
          Contact Us
        </Link>
      </div>
    );
  }

  return (
    <div className="content">
      {step == 1 && (
        <div className="custom-checkout-container">
          {user?.demo ? (
            signInForm ? (
              <>
                <form
                  onSubmit={async () => {
                    await signIn(state.email, state.password);
                    navigate(`/checkout/${order._id}/1`);
                  }}
                >
                  <div className="input-row">
                    <h1>email</h1>
                    <input
                      id="email"
                      onChange={handleChange}
                      type="email"
                      className="email-input"
                      required
                    />
                  </div>
                  <div className="input-row">
                    <h1>password</h1>
                    <input
                      id="password"
                      type="password"
                      onChange={handleChange}
                      className="password-input"
                    />
                  </div>
                  <button type="submit">Sign In</button>
                </form>
                <div className="message-box">
                  <button
                    onClick={() => {
                      setSignUpForm(true);
                      setSignInForm(false);
                    }}
                  >
                    Sign Up Instead
                  </button>
                  <button
                    onClick={() => {
                      setSignInForm(false);
                      setSignUpForm(false);
                      navigate(`/checkout/${order._id}/1`);
                    }}
                    className="button"
                  >
                    Back
                  </button>
                </div>
              </>
            ) : signUpForm ? (
              <div className="signup">
                <h2>Sign Up</h2>
                {stepSignUp === 1 && (
                  <div>
                    <p>Please Enter Email and Password</p>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                        required
                      />
                      <button type="submit">Next</button>
                    </form>
                    <button
                      onClick={() => {
                        setSignInForm(false);
                        setSignUpForm(false);
                        navigate(`/checkout/${order._id}/1`);
                      }}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => {
                        setSignUpForm(false);
                        setSignInForm(true);
                      }}
                    >
                      Sign In Instead
                    </button>
                  </div>
                )}

                {stepSignUp === 2 && (
                  <div>
                    <h3>Step 2: Connect Metamask?</h3>
                    <label>
                      <input
                        type="checkbox"
                        checked={connectMetamask}
                        onChange={(e) => setConnectMetamask(e.target.checked)}
                      />
                      Connect Metamask
                    </label>
                    <button type="button" onClick={handleBack}>
                      Back
                    </button>
                    <button type="submit" onClick={handleSubmit}>
                      Next
                    </button>
                  </div>
                )}

                {stepSignUp === 3 && (
                  <div>
                    <h3>Step 3: Create NFT Profile</h3>
                    <form onSubmit={handleSubmit}>
                      <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <button type="button" onClick={handleBack}>
                        Back
                      </button>
                      <button type="submit">Confirm</button>
                    </form>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <h2>Choose an option</h2>
                <button onClick={() => navigate("/sign-in")}>Sign In</button>
                <button onClick={() => navigate("/sign-up")}>Sign Up</button>
                <button onClick={() => navigate(`/checkout/${order._id}/2`)}>
                  Proceed as Guest
                </button>
              </div>
            )
          ) : (
            <>
              You are logged in, please go
              <button onClick={() => navigate(`/checkout/${order._id}/2`)}>
                Next
              </button>
            </>
          )}
        </div>
      )}
      {step == 2 && <Checkout order={order} />}
    </div>
  );
};

export default CustomCheckout;
