import React, { createContext, useContext, useState } from "react";

// call products from DB
import ecomm_img1 from "../assets/ecommerce/ec-demo1.jpg";
import ecomm_img2 from "../assets/ecommerce/ec-demo3.jpg";
import ecomm_video from "../assets/ecommerce/hardwareshop.mp4";
import dapp_video from "../assets/dapp/dapp-demo-gig.mp4";
import dapp_img1 from "../assets/dapp/homepage.png";
import dapp_img2 from "../assets/dapp/nft-collection.png";
import dapp_img3 from "../assets/dapp/nft-mobile.png";
import dapp_img4 from "../assets/dapp/token-sale.png";
import consultancy_img from "../assets/b-w-consultancy.jpg";

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const products = [
    {
      id: 1,
      video: ecomm_video,
      name: "E-commerce template",
      price: 5000,
      image: ecomm_img1,
      imgs: [ecomm_img1, ecomm_img2],
      description:
        "This is an e-commerce template with everything functioning, from payments to order logic and admin panel.",
      details: [
        "Discover the power of this comprehensive template, designed to streamline your payment processing with ",
        <a href="https://stripe.com/">Stripe</a>,
        ", optimize order logistics (don't forget to explore our impressive ",
        <a href="https://werewolf.solutions/e-commerce-demo/">demo!</a>,
        "!), and effortlessly deploy scripts for a production-ready application. Not to mention, we'll provide you with a user-friendly tutorial on how to host it for free or at minimal cost. Experience the convenience and efficiency of our all-in-one solution today!",
      ],
      booking: false,
      demo: "https://werewolf.solutions/e-commerce-demo/",
      onMarketplace: true,
    },
    {
      id: 2,
      video: dapp_video,
      name: "Dapp Template",
      price: 5000,
      image: dapp_img1,
      imgs: [dapp_img1, dapp_img2, dapp_img3, dapp_img4],
      description: "This a Dapp template with token sale and NFT minting.",
      details: [
        "Discover our powerful template with smart contracts, deploy scripts, token sale, NFT minting, and a production-ready tutorial. Host for free or at minimal cost. Check out the ",
        <a href="https://dapp-demo1.netlify.app/">demo</a>,
        " now!",
      ],
      booking: false,
      onMarketplace: true,
      demo: "https://dapp-demo1.netlify.app/",
    },
    {
      id: 3,
      name: "Book consultancy",
      price: 500,
      image: consultancy_img,
      imgs: [dapp_img1, dapp_img2, dapp_img3, dapp_img4],
      description: "Book 1h consultancy.",
      details: [
        "This is a call/video of 1h where we can go trhough any problems or ideas you have. My focus is mainly on",
      ],
      booking: true,
      onMarketplace: true,
      totQuantity: 100,
    },
    {
      id: 4,
      name: "Custom order",
      image: consultancy_img,
      imgs: [dapp_img1, dapp_img2, dapp_img3, dapp_img4],
      description: "Make a custom order.",
      details: ["Make a custom order."],
      booking: false,
      onMarketplace: false,
      customOrder: true,
      price: 10000,
    },
    {
      _id: "66b4b23e169bcbbe44ddf81d",
      name: "Test",
      image: consultancy_img,
      imgs: [],
      description: "Test",
      details: ["Test"],
      booking: false,
      onMarketplace: true,
      customOrder: false,
      price: 500,
      test: true,
      totQuantity: 100,
    },
    // Add more products as needed
  ];
  return (
    <ProductContext.Provider
      value={{
        products,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
