import React from "react";
import { Dialog } from "@mui/material";
import { updateBudget } from "../../../apiCalls/accountingCalls";

import { utils } from "../../../js/utils";
import moment from "moment";
import { useUser } from "../../../contexts/UserContext";

export default function EditBudgetDialog(props) {
  const { updateUser } = useUser();
  const [state, setState] = React.useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <div className="input-row">
          <h1>name</h1>
          <input
            id="name"
            value={
              state?.name || state?.name === ""
                ? state.name
                : props.budget?.name
            }
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>currency</h1>
          <select name="currency" id="currency" onChange={handleChange}>
            {utils.currencies.map((currency) => (
              <option value={currency}>{currency}</option>
            ))}
          </select>
        </div>
        <div className="input-row">
          <h1>goal</h1>
          <input
            id="goal"
            value={
              state?.goal || state?.goal === ""
                ? state.goal
                : props.budget?.goal
            }
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <label htmlFor="time">Time</label>
          <input
            id="time"
            type="number"
            value={
              state?.time || state?.time === ""
                ? state.time
                : props.budget?.time
            }
            onChange={handleChange}
          />
          <select
            id="timeframe"
            value={
              state?.timeframe || state?.timeframe === ""
                ? state.timeframe
                : props.budget?.timeframe
            }
            onChange={handleChange}
          >
            <option value="days">days</option>
            <option value="weeks">weeks</option>
            <option value="months">months</option>
            <option value="years">years</option>
          </select>
        </div>
        <button
          onClick={() => {
            let updatedData = {};
            if (state.name) {
              updatedData.name = state.name;
            }
            if (state.currency) {
              updatedData.currency = state.currency;
            }
            if (state.goal) {
              updatedData.goal = state.goal;
            }
            if (state.time) {
              updatedData.time = state.time;
            }
            if (state.timeframe) {
              updatedData.timeframe = state.timeframe;
            }
            updateBudget(props.budget._id, updatedData);
            updateUser();
            props.onClose();
            // if (!state.name || !state.currency || !state.update_at) {
            //   console.log("pop up message: enter all fields");
            // } else {
            // }
          }}
        >
          save
        </button>
      </Dialog>
    </div>
  );
}
