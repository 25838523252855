import axios from "axios";

/**
 * ACCOUNTING
 */
export async function accounting(budgetId) {
  let res = await axios.post("/api/accounting", { budgetId });
  // console.log(res.data);
  return res.data;
}

/**
 * BUDGET
 */
export async function addBudget(budget) {
  let res = await axios.post("/api/accounting/create-budget", { budget });
  // console.log(res.data);
  return res.data;
}

export async function deleteBudget(budgetId) {
  let res = await axios.delete(`/api/accounting/${budgetId}`);
  // console.log(res.data);
  return res.data;
}

export async function updateBudget(budgetId, updatedData) {
  let res = await axios.put(`/api/accounting/${budgetId}`, {
    updatedData,
  });
  // console.log(res.data);
  return res.data;
}

export async function getBudgetHistory(budgetId) {
  let res = await axios.post("/api/get-budget-history", { budgetId });
  // console.log(res.data);
  return res.data;
}

/**
 *
 * FIXED COSTS
 */
export async function addFixedCost(budgetId, fixedCost) {
  let res = await axios.post(`/api/accounting/${budgetId}/add-fixed-cost`, {
    fixedCost,
  });
  // console.log(res.data);
  return res.data;
}

export async function deleteFixedCost(budgetId, fixedCostId) {
  let res = await axios.delete(
    `/api/accounting/${budgetId}/delete-fixed-cost/${fixedCostId}`
  );
  // console.log(res.data);
  return res.data;
}

/**
 *
 * VARIABLE COSTS
 */
export async function addVariableCost(budgetId, variableCost) {
  let res = await axios.post(`/api/accounting/${budgetId}/add-variable-cost`, {
    variableCost,
  });
  // console.log(res.data);
  return res.data;
}

export async function deleteVariableCost(budgetId, variableCostId) {
  let res = await axios.delete(
    `/api/accounting/${budgetId}/delete-variable-cost/${variableCostId}`
  );
  // console.log(res.data);
  return res.data;
}

/**
 *
 * FUTURE COSTS
 */
export async function addFutureCost(budgetId, futureCost) {
  let res = await axios.post(`/api/accounting/${budgetId}/add-future-cost`, {
    futureCost,
  });
  // console.log(res.data);
  return res.data;
}

export async function deleteFutureCost(budgetId, futureCostId) {
  let res = await axios.delete(
    `/api/accounting/${budgetId}/delete-future-cost/${futureCostId}`
  );
  // console.log(res.data);
  return res.data;
}

/**
 *
 * ICOMES
 */
export async function addIncome(budgetId, income) {
  let res = await axios.post(`/api/accounting/${budgetId}/add-income`, {
    income,
  });
  // console.log(res.data);
  return res.data;
}

export async function deleteIncome(budgetId, incomeId) {
  let res = await axios.delete(
    `/api/accounting/${budgetId}/delete-income/${incomeId}`
  );
  // console.log(res.data);
  return res.data;
}

/**
 *
 * BANK ACCOUNTS
 */
export async function addBankAccount(budgetId, bankAccount) {
  let res = await axios.post(`/api/accounting/${budgetId}/add-bank-account`, {
    bankAccount,
  });
  // console.log(res.data);
  return res.data;
}

export async function deleteBankAccount(budgetId, bankAccountId) {
  let res = await axios.post("/api/delete-bank-account", {
    budgetId,
    bankAccountId,
  });
  // console.log(res.data);
  return res.data;
}

export async function updateBankAccount(budgetId, bankAccount) {
  let res = await axios.post("/api/update-bank-account", {
    budgetId,
    bankAccount,
  });
  // console.log(res.data);
  return res.data;
}
