import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePopUp } from "../../contexts/PopUpContext";

const { REACT_APP_PUBLIC_URL } = process.env;

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : REACT_APP_PUBLIC_URL;

export default function BusinessDetails() {
  const { openConfirmation, confirmed, handleConfirmation } = usePopUp();
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [apiKey, setApiKey] = useState(null);

  const deleteBusiness = async () => {
    try {
      const response = await axios.delete(`/api/v1/erp/business/${businessId}`);
      console.log(response.data);
      if (response.status === 200) {
        setBusiness(null);
        navigate("/business");
      }
    } catch (error) {
      console.error("Error deleting business:", error);
    }
  };

  const generateApiKey = async () => {
    try {
      let res = await axios.get(
        `/api/v1/erp/business/${businessId}/generate-api-key`
      );
      console.log(res);
      if (res.data.apiKey) {
        setApiKey(res.data.apiKey);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBusinessDetails = async () => {
    try {
      const response = await axios.get(`/api/v1/erp/business/${businessId}`);
      console.log(response.data);
      if (response.data.business) {
        setBusiness(response.data.business);
      }
    } catch (error) {
      console.error("Error fetching business details:", error);
    }
  };

  useEffect(() => {
    fetchBusinessDetails();
  }, [businessId]);

  useEffect(() => {
    console.log(confirmed);
    if (confirmed) {
      deleteBusiness();
      handleConfirmation();
    }
  }, [confirmed]);

  return (
    <div className="min-h-screen">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <h1 className="text-[#8e2421] font-extrabold text-xl md:text-3xl">
          Business Details
        </h1>
        <div className="flex flex-row gap-2 flex-wrap mt-4">
          <button
            onClick={() => navigate(-1)}
            className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
          <button
            onClick={() => navigate(`/business/${businessId}/edit`)}
            className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          >
            Edit
          </button>
          <button
            onClick={() => {
              openConfirmation(
                `Are you sure you want to delete this business?`
              );
              console.log(confirmed);
              // deleteBusiness
            }}
            className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          >
            Delete
          </button>
          <button
            onClick={generateApiKey}
            className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          >
            Generate Api Key
          </button>
        </div>
        {business && (
          <div className="mt-6 flex flex-col items-center">
            {apiKey && <p>API KEY: {apiKey}</p>}
            <img src={PUBLIC_URL + business.logo} className="h-12" />
            <p>
              <span className="font-extrabold text-xl md:text-2xl">Name:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.name}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">ID:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business._id}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">Owner:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.owner}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Accounts:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.accounts["stripe"]._id}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Domain:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.domain}
              </span>
            </p>
            {/* <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Sector:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.sector}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">Type:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.type}
              </span>
            </p> */}
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Currency:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.currency.toUpperCase()}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Payment Fee:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.paymentFee}
              </span>
            </p>
            {/* <p>
              <span className="font-extrabold text-xl md:text-2xl">Roles:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.roles.join(", ")}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">Teams:</span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.teams.join(", ")}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Departments:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.departments.join(", ")}
              </span>
            </p> */}
            {/* <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Clients:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.clients.length}
              </span>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Employees:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.employees.length}
              </span>
              <button
                onClick={() => navigate(`/business/${businessId}/employees`)}
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                details
              </button>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Reports:
              </span>
              <span className="font-extrabold md:text-xl ml-2 text-[#555454]">
                {business.reports.length}
              </span>
              <button
                onClick={() => navigate(`/business/${businessId}/reports`)}
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                details
              </button>
            </p> */}
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Inventory Items:
              </span>
              <span className="font-extrabold md:text-xl mx-2 text-[#555454]">
                {business.inventoryItems.length}
              </span>
              <button
                onClick={() =>
                  navigate(`/business/${businessId}/inventory-items`)
                }
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                details
              </button>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">Sales:</span>
              <span className="font-extrabold md:text-xl mx-2 text-[#555454]">
                {business.sales.length}
              </span>
              <button
                onClick={() => navigate(`/business/${businessId}/sales`)}
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                details
              </button>
            </p>
            <p>
              <span className="font-extrabold text-xl md:text-2xl">
                Transactions:
              </span>
              <span className="font-extrabold md:text-xl mx-2 text-[#555454]">
                {business.transactions.length}
              </span>
              <button
                onClick={() => navigate(`/business/${businessId}/transactions`)}
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                details
              </button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

/* <button
  className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
  onClick={async () => {
    try {
      const API_URL =
        process.env.NODE_ENV === "production"
          ? "https://werewolf.solutions/api/v1/"
          : "http://localhost:5000/api/v1/";
      let res = await axios.post(
        `${API_URL}plugin/stripe/test`,
        {
          test: "test",
        },
        { withCredentials: true, credentials: "include" }
      );
      console.log(res);
      return res;
    } catch (error) {
      console.log(error.response.data.error);
      console.log(error.response.data.message);
      console.log("Response headers:", error.response.headers);
      return error;
    }
  }}
>
  Test POST plugin/stripe/test
</button> */
