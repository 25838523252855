import React, { useState } from "react";

const _commonColours = [
  "Red",
  "White",
  "Blue",
  "Green",
  "Yellow",
  "Black",
  "Purple",
];

const ColourSelector = ({
  handleColourSelected,
  customItems,
  setCustomItems,
}) => {
  const [customColour, setCustomColour] = useState("");
  const [commonColours, setCommonColours] = useState([
    "Red",
    "White",
    "Blue",
    "Green",
    "Yellow",
    "Black",
    "Purple",
  ]);
  const [selectedColour, setSelectedColour] = useState(customItems.colour);
  const [selectedCommonColour, setSelectedCommonColour] = useState(
    commonColours[0]
  );

  const handleSelectChange = (event) => {
    setSelectedColour(event.target.value);
    handleColourSelected(event.target.value);
  };

  const handleSelectCommonChange = (event) => {
    setSelectedCommonColour(event.target.value);
  };

  const handleCustomColourChange = (event) => {
    setCustomColour(event.target.value);
  };

  const addCustomColour = () => {
    if (
      customColour &&
      !customItems.some((colourObj) => colourObj.colour === customColour)
    ) {
      const newColour = {
        colour: customColour,
        sizes: [{ size: "None", quantity: 0 }],
        customImages: [],
      };
      const updatedColours = customItems.filter(
        (colourObj) => colourObj.colour !== "" && colourObj.colour !== "None"
      );
      setCustomItems([...updatedColours, newColour]);
      setCustomColour("");
      setSelectedColour(customColour);
      handleColourSelected(customColour);
    }
  };

  const addCommonColour = () => {
    if (
      selectedCommonColour &&
      commonColours.includes(selectedCommonColour) &&
      !customItems.some(
        (colourObj) => colourObj.colour === selectedCommonColour
      )
    ) {
      const newColour = {
        colour: selectedCommonColour,
        sizes: [{ size: "None", quantity: 0 }],
        customImages: [],
      };
      const updatedColours = customItems.filter(
        (colourObj) => colourObj.colour !== "" && colourObj.colour !== "None"
      );
      setCustomItems([...updatedColours, newColour]);
      setSelectedColour(selectedCommonColour);
      setSelectedCommonColour(commonColours[1]);
      handleColourSelected(selectedCommonColour);

      // Remove the selected common color from the commonColours array
      setCommonColours(
        commonColours.filter((color) => color !== selectedCommonColour)
      );
    }
  };

  const removeSelectedColour = () => {
    if (selectedColour) {
      const updatedColours = customItems.filter(
        (colourObj) => colourObj.colour !== selectedColour
      );

      if (updatedColours.length === 0) {
        updatedColours.push({
          colour: "None",
          sizes: [{ size: "None", quantity: 0 }],
        });
      }

      const newSelectedColour =
        updatedColours.length > 0 ? updatedColours[0].colour : "None";

      setCustomItems(updatedColours);
      setSelectedColour(newSelectedColour);
      handleColourSelected(newSelectedColour);

      // Ensure that commonColours is not empty or undefined
      if (commonColours && commonColours.length > 0) {
        setSelectedCommonColour(commonColours[0]);
      } else {
        setSelectedCommonColour(""); // or handle appropriately
      }

      const isCommon = _commonColours.find(
        (colour) => colour === selectedColour
      );
      // Add the removed color back to the commonColours array
      if (selectedColour !== "None" && commonColours && isCommon) {
        setCommonColours([...commonColours, selectedColour]);
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex flex-col mb-4">
        <label
          htmlFor="colourSelect"
          className="font-medium text-gray-700 mb-2"
        >
          Select Colour
        </label>
        <div className="flex flex-row flex-wrap items-center gap-4">
          <select
            id="colourSelect"
            value={selectedColour}
            onChange={handleSelectChange}
            required
            className="p-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            {customItems?.map((colour, index) => (
              <option key={index} value={colour.colour}>
                {colour.colour}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={removeSelectedColour}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-200"
          >
            Remove Selected Colour
          </button>
        </div>
      </div>

      <div className="flex flex-row flex-wrap  gap-2 items-center">
        <input
          type="text"
          placeholder="Custom colour"
          value={customColour}
          onChange={handleCustomColourChange}
          className="p-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
        <button
          type="button"
          onClick={addCustomColour}
          className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200"
        >
          Add Custom Colour
        </button>
      </div>

      <div className="flex flex-col mt-4">
        <label
          htmlFor="commonColourSelect"
          className="font-medium text-gray-700"
        >
          Select from Common Colours
        </label>
        <div className="flex flex-row items-center gap-2">
          <select
            id="commonColourSelect"
            value={selectedCommonColour}
            onChange={handleSelectCommonChange}
            className="p-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            {commonColours.map((colour, index) => (
              <option key={index} value={colour}>
                {colour}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={addCommonColour}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
          >
            Add Common Colour
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColourSelector;
