import axios from "axios";

const { NODE_ENV, REACT_APP_API_URL, REACT_APP_BUSINESS_API_KEY } = process.env;

const BUSINESS_API_KEY =
  NODE_ENV === "production"
    ? REACT_APP_BUSINESS_API_KEY
    : "Axq3u8lVEEgjE3piKMs9x6iFenUur0XC";
const API_URL =
  NODE_ENV === "production"
    ? REACT_APP_API_URL
    : "http://localhost:5000/api/v1/";

/**
 *
 * @desc    create payment intent
 * @route   GET /stripe/pay
 * @access  Public
 * @params  -
 * @returns clientSecret
 */

export async function addPaymentMethod(type, card, billingDetails) {
  let res = await axios.post("/api/v1/plugin/stripe/create-payment-method", {
    type,
    card,
    billingDetails,
  });
  console.log(res.data);
  return res.data;
}

export async function createPaymentIntent(cart, currency, shippingType) {
  try {
    const res = await axios.post(
      `${API_URL}plugin/stripe/create-payment-intent`,
      {
        items: cart,
        currency,
        shippingType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "business-api-key": BUSINESS_API_KEY,
        },
        withCredentials: true,
        credentials: "include",
      }
    );
    console.log(res);
    console.log("Api create payment intent");
    return res;
  } catch (error) {
    return error;
  }
}

export async function confirmPaymentIntent(
  paymentIntent,
  saleId,
  transactionId
) {
  let res = await axios.post("/api/v1/plugin/stripe/confirm-payment-intent", {
    paymentIntent,
    saleId,
    transactionId,
  });
  console.log(res.data);
  return res.data;
}

export async function attachPaymentMethod(paymentMethodId, customerId) {
  let res = await axios.post("/api/v1/plugin/stripe/attach-payment-method", {
    paymentMethodId,
    customerId,
  });
  console.log(res.data);
  return res.data;
}

export async function updatePaymentMethod(paymentMethodId, billingDetails) {
  let res = await axios.post("/api/v1/plugin/stripe/update-payment-method", {
    paymentMethodId,
    billingDetails,
  });
  console.log(res.data);
  return res.data;
}

export async function createCustomer() {
  let res = await axios.post("/api/v1/plugin/stripe/create-customer");
  console.log(res.data);
  return res.data;
}

export async function deletePaymentMethod(paymentMethodId) {
  let res = await axios.post("/api/v1/plugin/stripe/delete-payment-method", {
    paymentMethodId,
  });
  console.log(res.data);
  return res.data;
}
