import React, { useState, useEffect } from "react";

const { REACT_APP_PUBLIC_URL, NODE_ENV } = process.env;
const PUBLIC_URL =
  NODE_ENV === "production" ? REACT_APP_PUBLIC_URL : "http://localhost:5000/";

const ImageCarouselWithUploader = ({
  images: initialImages,
  handleUploadImages,
}) => {
  const [images, setImages] = useState(initialImages);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setImages(initialImages);
  }, [initialImages]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const newImages = [...images, selectedFile];
      setImages(newImages);
      handleUploadImages(newImages);
    }
  };

  const removeImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
    handleUploadImages(newImages);
    if (currentIndex >= newImages.length) {
      setCurrentIndex(newImages.length - 1);
    }
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const renderImageSrc = (image) => {
    if (typeof image === "string" || !image) {
      return PUBLIC_URL + image;
    } else {
      return URL.createObjectURL(image);
    }
  };

  const renderChooseFileButton = () => (
    <label
      htmlFor="image-upload"
      className="flex justify-center items-center cursor-pointer shadow-lg rounded-lg"
      style={{
        width: "120px",
        height: "120px",
        border: "1px dashed #ccc",
        minHeight: "120px",
      }}
    >
      <input
        id="image-upload"
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
      <span className="text-sm text-stone-500">Choose File</span>
    </label>
  );

  return (
    <div className="relative p-2">
      {images?.length > 0 && (
        <>
          <button
            onClick={handlePrev}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            &lt;
          </button>
          <img
            className="max-h-60"
            src={renderImageSrc(images[currentIndex])}
            alt={`carousel-${currentIndex}`}
          />
          <button
            onClick={handleNext}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
          >
            &gt;
          </button>
        </>
      )}
      <div className="flex flex-col items-center mt-4">
        <div className="grid grid-cols-3 gap-4 items-center">
          {images?.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={renderImageSrc(image)}
                className="w-40 h-40 rounded-lg object-contain"
                alt={`upload-${index}`}
              />
              <button
                className="absolute bottom-2 left-1/2 transform -translate-x-1/2 font-extrabold cursor-pointer text-[#ffff] text-sm py-1 px-2 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                onClick={() => removeImage(index)}
              >
                Remove
              </button>
            </div>
          ))}
          <div className="mt-4">{renderChooseFileButton()}</div>
        </div>
      </div>
    </div>
  );
};

export default ImageCarouselWithUploader;
