import React from "react";
import { Dialog } from "@mui/material";
import {
  updateBankAccount,
  updateBudget,
} from "../../../../apiCalls/accountingCalls";

import { utils } from "../../../../js/utils";
import moment from "moment";
import { useUser } from "../../../../contexts/UserContext";

export default function EditBankAccount(props) {
  const { updateUser } = useUser();
  const [state, setState] = React.useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <h1>Edit bank account</h1>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="name"
            onChange={handleChange}
            className="name-input"
            value={
              state?.name || state?.name === ""
                ? state.name
                : props.bankAccount?.name
            }
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="amount"
            onChange={handleChange}
            className="name-input"
            value={
              state?.amount || state?.amount === ""
                ? state.amount
                : props.bankAccount?.amount
            }
          />
        </div>
        <span className="save-btn">
          <button
            onClick={async () => {
              let date = new Date();
              if (state.name) {
                props.bankAccount.name = state.name;
              }
              if (state.amount) {
                props.bankAccount.amount = state.amount;
              }
              let updatedData = { bank_account: props.bankAccount };
              await updateBudget(props.budget._id, updatedData);
              updateUser();
              props.onClose();
            }}
          >
            save
          </button>
        </span>
      </Dialog>
    </div>
  );
}
