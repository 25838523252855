import React, { useState } from "react";
import { Dialog } from "@mui/material";
import AddVariableCostDialog from "./AddVariableCostDialog";
import { deleteVariableCost } from "../../../../apiCalls/accountingCalls";

import moment from "moment";
import EditVariableCost from "./EditVariableCost";

const formatDate = function (date, format_from, format_to) {
  // let time = moment(date, format_from).format("YYYT-MM-DD");
  // let time_db = moment(date, format_from).format("YYYY-MM-DD hh:mm:ss");
  return moment(date, format_from).format(format_to);
};

export default function VariableCostsDialog(props) {
  const { budget } = props;

  const [addVariableCostDialog, setAddVariableCostDialog] = useState(false);

  const [editVariableCostDialog, setEditVariableCostDialog] = useState(false);
  const [variableCostSelected, setVariableCostSelected] = useState(false);

  const handleEditVariableCostDialog = (cost) => {
    if (cost) {
      setVariableCostSelected(cost);
    }
    setEditVariableCostDialog(!editVariableCostDialog);
  };

  const handleAddVariableCostDialog = () => {
    setAddVariableCostDialog(!addVariableCostDialog);
  };
  return (
    <div>
      <AddVariableCostDialog
        open={addVariableCostDialog}
        onClose={handleAddVariableCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        updateBudget={props.updateBudget}
      />
      <EditVariableCost
        open={editVariableCostDialog}
        onClose={handleEditVariableCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        variableCost={variableCostSelected}
        updateBudget={props.updateBudget}
      />
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <span className="table-title">
          <h1>
            Variable costs {budget?.tot_variable_costs} {budget?.currency}
          </h1>
          <button onClick={handleAddVariableCostDialog}>add</button>
        </span>
        {budget?.variable_costs?.map((cost) => (
          <>
            <span className="table-name">
              {cost?.name}
              <button
                onClick={async () => {
                  await deleteVariableCost(budget._id, cost._id);
                  props.updateUser();
                  props.updateBudget();
                }}
              >
                delete
              </button>
              <button onClick={() => handleEditVariableCostDialog(cost)}>
                edit
              </button>
            </span>
            <span className="table-sub-name">
              Amount:
              <span className="table-props-text">
                {cost?.amount} {cost?.currency}
              </span>
            </span>
            <span className="table-sub-name">
              Date:
              <span className="table-props-text">{cost?.formatted_date}</span>
            </span>
            {/* <span className="table-sub-name">
              To: <span className="table-props-text">{cost?.to}</span>
            </span> */}
            <span className="table-sub-name">
              From:
              <span className="table-props-text">{cost?.from?.name}</span>
            </span>
            {/* <span className="table-sub-name">
              Notes: <span className="table-props-text">{cost?.notes}</span>
            </span> */}
            <br />
          </>
        ))}
      </Dialog>
    </div>
  );
}
