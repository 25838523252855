import React, { useState } from "react";
import axios from "axios";
import moment from "moment";
import { useUser } from "../../contexts/UserContext";
import { useSocket } from "../../contexts/SocketContext";

export default function EditOrder({ handleEditOrder, order }) {
  const { user } = useUser();
  const socket = useSocket();
  const [updatedOrder, setUpdatedOrder] = useState({
    totalAmount: order.totalAmount,
    description: order.description,
    timeframe: { ...order.timeframe },
    orderType: order.orderType,
  });
  const [file, setFile] = useState();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTimeframeChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      timeframe: { ...prevState.timeframe, [name]: value },
    }));
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/orders/${order._id}`, {
        newOrder: updatedOrder,
      });
      console.log("Order updated successfully:", response);
      // You can perform any additional actions or show a success message here.
    } catch (error) {
      console.error("Error updating order:", error);
      // Handle the error, show an error message, etc.
    }
    socket.emit("orderUpdate");
    // Close the edit form
    handleEditOrder();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.log("No file selected.");
      return;
    }
    setFile(file);
  };

  return (
    <div className="order-details">
      <h2>Edit Order</h2>
      <form onSubmit={handleConfirm}>
        <div className="form-group">
          <label htmlFor="totalAmount">Total Amount:</label>
          <input
            type="number"
            id="totalAmount"
            name="totalAmount"
            value={updatedOrder.totalAmount}
            onChange={handleInputChange}
            min="100"
            required
          />
        </div>
        {user?.admin && (
          <div className="form-group">
            <input
              type="link"
              id="demoLink"
              name="demoLink"
              value={updatedOrder.demoLink}
              onChange={handleInputChange}
            />
            <button
              onClick={async () => {
                let res = await axios.post(
                  `/api/orders/${order._id}/add-demo-link`,
                  { demoLink: updatedOrder.demoLink }
                );
                console.log(res);
                // fetchOrderDetails();
                socket.emit("orderUpdate");
              }}
            >
              Add Demo Link
            </button>
            {/* <input
              type="file"
              onChange={handleFileUpload}
              label="Upload file"
            />
            <button
              className="button"
              onClick={async () => {
                try {
                  const formData = new FormData();
                  formData.append("file", file);
                  console.log(formData);
                  const res = await axios.post(
                    `/api/orders/${order._id}/upload-file`,
                    { file: formData }
                  );
                  console.log(res);
                } catch (error) {
                  console.error("Error uploading the file:", error);
                }
              }}
            >
              Upload File
            </button> */}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            name="description"
            value={updatedOrder.description}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="timeframe">Timeframe (min 3 days):</label>
          <input
            type="number"
            id="duration"
            name="duration"
            value={updatedOrder.timeframe.duration}
            onChange={handleTimeframeChange}
            min="3"
            required
          />
          <select
            id="unit"
            name="unit"
            value={updatedOrder.timeframe.unit}
            onChange={handleTimeframeChange}
            required
          >
            <option value="days">Days</option>
            <option value="weeks">Weeks</option>
            <option value="months">Months</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="orderType">Order Type:</label>
          <select
            id="orderType"
            name="orderType"
            value={updatedOrder.orderType}
            onChange={handleInputChange}
            required
          >
            <option value="">Select an option</option>
            <option value="single-page">Single Page Website</option>
            <option value="multi-page">Multiple Pages Website</option>
            <option value="saas">SaaS</option>
            <option value="ecommerce">Ecommerce</option>
            <option value="custom">Custom</option>
          </select>
        </div>

        <div className="form-group">
          <p>
            Creation Date:{" "}
            <span>{moment(order.date).format("DD MMM YY @ HH:mm")}</span>
          </p>
        </div>

        <div className="buttons">
          <button className="button" onClick={handleEditOrder}>
            Cancel
          </button>
          <button type="submit" className="button">
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
}
