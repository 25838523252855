import React, { createContext, useContext, useState, useEffect } from "react";
import Web3 from "web3";
import axios from "axios";

import { api, signOut } from "../apiCalls/userCalls";
import { useCart } from "./CartContext";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const savedSaleId = JSON.parse(localStorage.getItem("saleId")) || null;
  const savedOrder = JSON.parse(localStorage.getItem("order")) || null;
  const [user, setUser] = useState();

  const [account, setAccount] = useState();
  const [balance, setBalance] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const [networkId, setNetworkId] = useState();
  const [web3, setWeb3] = useState();
  const [tokenContract, setTokenContract] = useState();
  const [token, setToken] = useState({});
  const [stakableTokens, setStakableTokens] = useState();
  const [saleId, setSaleId] = useState(savedSaleId);
  const [transactionId, setTransactionId] = useState();
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [order, setOrder] = useState(savedOrder);

  const updateUser = async () => {
    let res = await axios.get("/api/v1/auth/session", {
      withCredentials: true,
      credentials: "include",
    });
    console.log(res);
    if (typeof window.ethereum !== "undefined") {
      let web3 = new Web3(Web3.givenProvider);
      let account = await web3.eth.getAccounts();
      if (account.length != 0) {
        let bal = await web3.eth.getBalance(account[0]);
        let balance = web3.utils.fromWei(bal);
        let networkId = await web3.eth.net.getId();
        res.data.user.account = account[0];
        res.data.user.balance = balance;
        res.data.user.networkId = networkId;
      }
    }
    if (res.data.user) {
      setUser(res.data.user);
    } else setUser(null);
  };

  const connectMetamask = async () => {
    if (typeof window.ethereum !== "undefined") {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      let web3 = new Web3(Web3.givenProvider);
      setWeb3(web3);
      let account = await web3.eth.getAccounts();
      let bal = await web3.eth.getBalance(account[0]);
      let balance = web3.utils.fromWei(bal);
      let networkId = await web3.eth.net.getId();
      setAccount(account[0]);
      setBalance(balance);
      setNetworkId(networkId);
      updateUser();
      window.ethereum.on("accountsChanged", async (accounts) => {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        updateUser();
      });

      window.ethereum.on("chainChanged", (chainId) => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        window.location.reload();
      });
      // window.ethereum.removeListener("accountsChanged", () =>
      //   console.log("stop listening")
      // );
    }
  };

  const signOut = async () => {
    let res = await api.signOut();
    // console.log(res.data)
    setUser(null);
    return res.data;
  };

  const signUp = async (email, password, password2) => {
    let res = await api.signUp(email, password, password2);
    //   console.log(res.data);
    await api.signIn(email, password);
    updateUser();
    return res;
  };

  const editUser = async (user) => {
    let res = await api.editUser(user);
    console.log(res);
    if (res.status === 200) {
      await updateUser();
    }
  };

  const createGuestSession = async (email) => {
    const res = await api.createGuestSession(email);
    console.log(res);
    if (res.data.user) {
      setUser(res.data.user);
    }
  };

  const createPaymentIntent = async (cart, currency, shippingType) => {
    const res = await api.createPaymentIntent(cart, currency, shippingType);
    console.log(res);
    const { data } = res;
    console.log("User context create payment intent");
    console.log(data);

    if (
      data &&
      data.clientSecret &&
      data.saleId &&
      data.transactionId &&
      data.order &&
      data.paymentIntent
    ) {
      setClientSecret(data.clientSecret);
      setSaleId(data.saleId);
      setTransactionId(data.transactionId);
      setOrder(data.order);
      setPaymentIntent(data.paymentIntent);
      // user.sale = res.data.sale;
      // user.paymentIntent = res.data.payment_intent;
      // setUser(user);
      localStorage.setItem("order", JSON.stringify(data.order));
      localStorage.setItem("saleId", JSON.stringify(data.saleId));
    }
  };

  const confirmPaymentIntent = async (paymentIntentId) => {
    const res = await api.confirmPaymentIntent(paymentIntentId, saleId);
    // console.log(res);
    const { data } = res;
    // console.log(data);
    if (data.paymentIntent) {
      // console.log(data.paymentIntent);
      setPaymentIntent(data.paymentIntent);
    }
  };

  const customOrder = async (order) => {
    const res = await api.customOrder(order);
    console.log(res);
    return res;
  };

  return (
    <UserContext.Provider
      value={{
        updateUser,
        user,
        connectMetamask,
        signOut,
        signUp,
        editUser,
        createGuestSession,
        createPaymentIntent,
        confirmPaymentIntent,
        clientSecret,
        order,
        customOrder,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
