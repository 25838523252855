import React from "react";
import moment from "moment";

export default function AddFixedCost({
  handleChange,
  handleDateSelect,
  handleNext,
  date,
}) {
  return (
    <div>
      <h1>Add fixed cost</h1>
      <form onSubmit={handleNext}>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="fixed_name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="fixed_amount"
            type="number"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row-date">
          <h1>Date*</h1>
          <input
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={handleDateSelect}
          />
        </div>
        {/* <div className="input-row">
          <h1>frequency*</h1>
          <select name="frequency" id="frequency" onChange={handleChange}>
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="other">Other</option>
          </select>
        </div> */}
        <button type="submit">Next</button>
      </form>
    </div>
  );
}
