import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../../../contexts/UserContext";
import { updateBudget } from "../../../apiCalls/accountingCalls";

export default function SelectWorkingDays({ handleNext, budget }) {
  const { updateUser } = useUser();
  const modalRef = useRef(null);
  console.log(budget);

  const [workingDays, setWorkingDays] = useState(budget?.working_days);

  const handleSelectWorkingDays = async (e) => {
    const selectedDay = e.target.value;
    const newWorkingDays = workingDays.includes(selectedDay)
      ? workingDays.filter((day) => day !== selectedDay)
      : [...workingDays, selectedDay];

    setWorkingDays(newWorkingDays);
    budget.working_days = newWorkingDays;
    let updatedData = { working_days: newWorkingDays };

    // Assuming updateBudget is an asynchronous function
    await updateBudget(budget._id, updatedData);

    updateUser();
  };

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  return (
    <div>
      <h2>Select Working Days</h2>
      <div className="checkbox-box">
        {weekDays.map((day) => (
          <span className="checkbox-input-wrapper" key={day}>
            <input
              type="checkbox"
              value={day}
              id={`checkbox-${day}`}
              onChange={handleSelectWorkingDays}
              checked={workingDays?.includes(day)}
            />
            <label htmlFor={`checkbox-${day}`} className="working-days-label">
              {day}
            </label>
          </span>
        ))}
      </div>
      <button onClick={handleNext}>Next</button>
    </div>
  );
}
