import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { addIncome } from "../../../../apiCalls/accountingCalls";

import moment from "moment";

import { useUser } from "../../../../contexts/UserContext";

export default function AddIncomeDialog(props) {
  const { updateUser } = useUser();
  const [state, setState] = useState();
  const [date, setDate] = useState(new Date());
  const [bankAccount, setBankAccount] = useState();

  const handleDateSelect = (e) => {
    setDate(e.target.value);
  };

  const handleBankAccountSelect = (e) => {
    for (let i = 0; i < props.budget?.bank_accounts?.length; i++) {
      if (props.budget.bank_accounts[i]._id == e.target.value) {
        setBankAccount(props.budget.bank_accounts[i]);
      }
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <h1>Add income</h1>
        <div className="input-row">
          <h1>Name*</h1>
          <input
            id="name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>Amount*</h1>
          <input
            id="amount"
            type="number"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row-date">
          <h1>Date*</h1>
          <input
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={handleDateSelect}
          />
        </div>
        {/* <div className="input-row-date">
          <h1>Shift*</h1>
          <input
            type="time"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={handleShiftSelect}
          />
        </div> */}
        {/* <div className="input-row">
          <h1>Frequency*</h1>
          <input
            id="frequency"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div> */}
        <div className="input-row">
          <h1>To*</h1>
          <select name="to" id="to" onChange={handleBankAccountSelect}>
            {props.budget?.bank_accounts?.map((bank) => (
              <option value={bank._id}>{bank.name}</option>
            ))}
          </select>
        </div>
        {/* <div className="input-row">
          <h1>From*</h1>
          <input
            id="from"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div> */}
        <div className="input-row">
          <h1>Hours*</h1>
          <input
            id="hours"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>Minutes*</h1>
          <input
            id="minutes"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>Notes*</h1>
          <input
            id="notes"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <span className="save-btn">
          <button
            onClick={async () => {
              let income = {
                name: state.name,
                amount: state.amount,
                currency: props.budget?.currency,
                date: date,

                to: bankAccount ? bankAccount : props.budget?.bank_accounts[0],
                from: state.from,
                notes: state.notes,
                shift: {
                  hours: state.hours,
                  minutes: state.minutes,
                },
              };
              await addIncome(props.budget._id, income);
              updateUser();
              props.updateBudget();
              props.onClose();
            }}
          >
            Save
          </button>
        </span>
      </Dialog>
    </div>
  );
}
