import React, { useState, useEffect } from "react";
import { accounting } from "../../apiCalls/accountingCalls";
import CreateNewBudget from "./CreateNewBudget/CreateNewBudget";
import ExpandedBudget from "./Budget/ExpandedBudget";
import EditBudgetDialog from "./Budget/EditBudgetDialog";
import { useUser } from "../../contexts/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FixedCostsDialog from "./Budget/FixedCosts/FixedCostsDialog";
import VariableCostsDialog from "./Budget/VariableCosts/VariableCostsDialog";
import FutureCostsDialog from "./Budget/FutureCosts/FutureCostsDialog";
import IncomesDialog from "./Budget/Incomes/IncomesDialog";
import BankAccountsDialog from "./Budget/BankAccounts/BankAccountsDialog";
import SelectWorkingDays from "./Budget/SelectWorkingDays";
import SelectWorkingDaysModal from "./Budget/SelectWorkingDaysModal";

export default function Accounting() {
  const { updateUser, user } = useUser();
  const navigate = useNavigate();
  const [show, setShow] = useState("list-of-budgets");
  const [budgetSelected, setBudgetSelected] = useState();
  const [budgets, setBudgets] = useState([]);
  const [editBudgetDialog, setEditBudgetDialog] = useState(false);
  const [fixedCostsDialog, setFixedCostsDialog] = useState(false);
  const [variableCostsDialog, setVariableCostsDialog] = useState(false);
  const [futureCostsDialog, setFutureCostsDialog] = useState(false);
  const [incomesDialog, setIncomesDialog] = useState(false);
  const [bankAccountsDialog, setBankAccountsDialog] = useState(false);
  const [workingDaysDialog, setWorkingDaysDialog] = useState(false);

  const handleFixedCostsDialog = () => {
    setFixedCostsDialog(!fixedCostsDialog);
  };
  const handleVariableCostsDialog = () => {
    setVariableCostsDialog(!variableCostsDialog);
  };
  const handleFutureCostsDialog = () => {
    setFutureCostsDialog(!futureCostsDialog);
  };
  const handleIncomesDialog = () => {
    setIncomesDialog(!incomesDialog);
  };
  const handleBankAccountsDialog = () => {
    setBankAccountsDialog(!bankAccountsDialog);
  };
  const handleWorkingDaysDialog = () => {
    setWorkingDaysDialog(!workingDaysDialog);
  };

  const showListBudgets = () => {
    setShow("list-of-budgets");
  };

  const handleShow = (elm) => {
    setShow(elm);
  };

  const handleAddBudget = () => {
    console.log("set selected => add new budget");
    setShow("create-new-budget");
  };

  const handleEditBudget = (budget) => {
    setShow("edit-budget");
    setBudgetSelected(budget);
  };

  const expandBudget = (budget) => {
    setShow("expand-budget");
    setBudgetSelected(budget);
  };

  const handleSelectBudget = (budget) => {
    setBudgetSelected(budget);
  };

  const handleEditBudgetDialog = (budget) => {
    setEditBudgetDialog(!editBudgetDialog);
    setBudgetSelected(budget);
  };

  const grabUserBudgets = async () => {
    try {
      let res = await axios.get(`/api/accounting/get-budgets`);
      console.log(res.data);
      if (res.data.ok) {
        let { budgets } = res.data;
        setBudgets(budgets);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    grabUserBudgets();
  }, [user]);

  let component;
  if (show === "expand-budget") {
    component = (
      <div>
        <h2>{budgetSelected.name}</h2>
        <div className="budget-card">
          <div>
            <button onClick={() => setShow("list-of-budgets")}>back</button>
          </div>
          <ExpandedBudget budget={budgetSelected} updateUser={updateUser} />
        </div>
      </div>
    );
  }

  if (show === "list-of-budgets") {
    component = (
      <div>
        <FixedCostsDialog
          open={fixedCostsDialog}
          onClose={handleFixedCostsDialog}
          budget={budgetSelected}
        />
        <VariableCostsDialog
          open={variableCostsDialog}
          onClose={handleVariableCostsDialog}
          budget={budgetSelected}
        />
        <FutureCostsDialog
          open={futureCostsDialog}
          onClose={handleFutureCostsDialog}
          budget={budgetSelected}
        />
        <IncomesDialog
          open={incomesDialog}
          onClose={handleIncomesDialog}
          budget={budgetSelected}
        />
        <BankAccountsDialog
          open={bankAccountsDialog}
          onClose={handleBankAccountsDialog}
          budget={budgetSelected}
        />
        {workingDaysDialog && (
          <SelectWorkingDaysModal
            open={workingDaysDialog}
            onClose={handleWorkingDaysDialog}
            budget={budgetSelected}
          />
        )}
        <h2>list of budgets</h2>
        <div className="budgets--wrapper">
          {budgets?.map((budget) => (
            <div className="budget-card">
              <button
                className="button"
                onClick={async () => {
                  await accounting(budget._id);
                  grabUserBudgets();
                }}
              >
                accounting
              </button>
              <div>
                <EditBudgetDialog
                  open={editBudgetDialog}
                  onClose={handleEditBudgetDialog}
                  budget={budgetSelected}
                  updateUser={updateUser}
                />
                {budget.name}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleEditBudgetDialog(budget);
                  }}
                >
                  edit
                </button>
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleWorkingDaysDialog();
                  }}
                >
                  Select Working Days
                </button>
                <button
                  onClick={async () => {
                    /**
                     * TODO:
                     *
                     * pop up confirm button
                     */
                    // deleteBudget(budget._id);
                    let res = await axios.delete(
                      `/api/accounting/${budget._id}`
                    );
                    console.log(res);
                    updateUser();
                  }}
                >
                  delete
                </button>
              </div>
              <div>
                To earn = {budget.currency} {budget.to_earn} per day
              </div>
              <div>
                To save = {budget.currency} {budget.to_save} per day
              </div>
              {/* <div>
                Spendable = {budget.currency} {budget.spendable}
              </div> */}
              <div>
                Goal = {budget.currency}
                <span> </span>
                {parseFloat(budget.goal).toLocaleString()} in {budget.time}{" "}
                {budget.timeframe}
              </div>
              <div>
                Fixed Costs = {budget.currency} {budget.tot_fixed_costs}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleFixedCostsDialog();
                  }}
                >
                  show
                </button>
              </div>
              <div>
                Variable Costs = {budget.currency}
                {budget.tot_variable_costs}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleVariableCostsDialog();
                  }}
                >
                  show
                </button>
              </div>
              <div>
                Future Costs = {budget.currency} {budget.tot_future_costs}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleFutureCostsDialog();
                  }}
                >
                  show
                </button>
              </div>
              <div>
                Incomes = {budget.currency} {budget.tot_incomes}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleIncomesDialog();
                  }}
                >
                  show
                </button>
              </div>
              <div>
                Bank accounts = {budget.currency} {budget.tot_bank_accounts}
                <button
                  onClick={() => {
                    handleSelectBudget(budget);
                    handleBankAccountsDialog();
                  }}
                >
                  show
                </button>
              </div>
              <h1>total costs debug</h1>
              {budget.total_costs?.map((cost) => (
                <>
                  <span className="tot-costs-debug">
                    Date:
                    <span className="tot-costs-debug-details">
                      {cost?.formatted_date}
                    </span>
                  </span>
                  <span className="tot-costs-debug">
                    Amount:
                    <span className="tot-costs-debug-details">
                      {cost?.amount} {budget?.currency}
                    </span>
                  </span>
                  <span className="tot-costs-debug">
                    Days left:
                    <span className="tot-costs-debug-details">
                      {cost?.days_left}
                    </span>
                  </span>
                  <span className="tot-costs-debug">
                    To earn:
                    <span className="tot-costs-debug-details">
                      {cost?.to_earn} {budget?.currency}
                    </span>
                  </span>
                  <br />
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (show === "create-new-budget")
    component = (
      <CreateNewBudget
        showListBudgets={showListBudgets}
        updateUser={updateUser}
        handleShow={handleShow}
      />
    );

  return (
    <div className="content">
      <div className="budget-card">
        <h2>Accounting</h2>
        <div className="add-budget-btn--wrapper">
          {show === "create-new-budget" ? (
            <button
              className="button"
              onClick={() => setShow("list-of-budgets")}
            >
              back
            </button>
          ) : (
            <button
              className="button"
              onClick={() => navigate("/create-new-budget")}
            >
              create new budget
            </button>
          )}
        </div>
        <div className="budgets--wrapper">{component}</div>
      </div>
    </div>
  );
}
