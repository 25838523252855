import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function EditBusinessDetails() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [business, setBusiness] = useState(null);
  const [companyLogo, setCompanyLogo] = useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const submit = async (e) => {
    e.preventDefault();
    let uploadedImg;
    if (companyLogo) {
      uploadedImg = await uploadImage(companyLogo);
      console.log(uploadedImg);
    }
    let res = await axios.put(`/api/v1/erp/business/${businessId}`, {
      sector: state.sector ? state.sector : business.sector,
      type: state.type ? state.type : business.type,
      name: state.name ? state.name : business.name,
      description: state.description ? state.description : business.description,
      currency: state.currency ? state.currency : business.currency,
      domain: state.domain ? "https://" + state.domain : business.domain,
      roles: state.roles
        ? state.roles.split(",").map((role) => role.trim())
        : business.roles,
      teams: state.teams
        ? state.teams.split(",").map((team) => team.trim())
        : business.teams,
      departments: state.departments
        ? state.departments.split(",").map((department) => department.trim())
        : business.departments,
      logo: uploadedImg ? uploadedImg : business.logo,
    });
    if (res.status === 200) {
      navigate(-1);
    }
  };

  async function uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const res = await axios.post(`/api/v1/upload-file`, formData, {
        withCredentials: true,
        credentials: "include",
      });
      return res.data.file;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const fetchBusinessDetails = async () => {
    try {
      const response = await axios.get(`/api/v1/erp/business/${businessId}`);
      console.log(response.data);
      if (response.data.business) {
        setBusiness(response.data.business);
      }
    } catch (error) {
      console.error("Error fetching business details:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCompanyLogo(file);
    }
  };

  const imagePreview = companyLogo && (
    <div className="flex justify-around items-center m-2">
      <img
        src={URL.createObjectURL(companyLogo)}
        style={{ width: "70px", height: "auto" }}
      />
      <button
        className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
        onClick={() => removeImage(companyLogo)}
      >
        Remove
      </button>
    </div>
  );

  const removeImage = () => {
    setCompanyLogo("");
  };

  useEffect(() => {
    fetchBusinessDetails();
  }, []);

  return (
    <div className="min-h-screen mb-20">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <h2 className="text-[#8e2421] font-extrabold text-xl md:text-3xl mb-4">
          Edit Business Details
        </h2>
        {business && (
          <form onSubmit={submit} className="space-y-4">
            <div className="mb-3">
              <label className="font-semibold text-gray-600">
                Business Logo
              </label>
              <div className="flex items-center py-2">
                {!companyLogo && (
                  <label htmlFor="logo" className="cursor-pointer">
                    <span className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg">
                      Choose File
                    </span>
                    <input
                      id="logo"
                      type="file"
                      required
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </label>
                )}
                {imagePreview}
              </div>
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="name"
              >
                Name:
              </label>
              <input
                id="name"
                placeholder={business.name}
                value={state.name || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="sector"
              >
                Sector:
              </label>
              <input
                id="sector"
                placeholder={business.sector}
                value={state.sector || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="type"
              >
                Type:
              </label>
              <input
                id="type"
                placeholder={business.type}
                value={state.type || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="currency"
              >
                Currency:
              </label>
              <select
                id="currency"
                value={state.currency || business.currency || "gbp"}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              >
                <option value="gbp">GBP</option>
                <option value="usd">USD</option>
                <option value="eur">EUR</option>
                {/* Add more currencies as needed */}
              </select>
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="roles"
              >
                Roles (comma separated):
              </label>
              <input
                id="roles"
                placeholder={
                  business.roles ? business.roles.join(", ") : "Roles"
                }
                value={state.roles || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="teams"
              >
                Teams (comma separated):
              </label>
              <input
                id="teams"
                placeholder={
                  business.teams ? business.teams.join(", ") : "Teams"
                }
                value={state.teams || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="departments"
              >
                Departments (comma separated):
              </label>
              <input
                id="departments"
                placeholder={
                  business.departments
                    ? business.departments.join(", ")
                    : "Departments"
                }
                value={state.departments || ""}
                onChange={handleChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="w-full flex flex-col mb-3">
              <label className="font-semibold text-gray-600 py-2">
                Business Website
              </label>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <span className="text-gray-500 dark:text-gray-400 p2.5">
                    https://
                  </span>
                </div>
                <input
                  type="text"
                  id="domain"
                  placeholder={business.domain}
                  value={state.domain || ""}
                  onChange={handleChange}
                  className="border text-gray-900 rounded-lg focus:ring-[#8e2421] focus:border-[#8e2421] w-full ps-20 p-2.5"
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                className="font-semibold text-gray-600 py-2"
                htmlFor="description"
              >
                Description:
              </label>
              <textarea
                id="description"
                placeholder={business.description}
                value={state.description || ""}
                onChange={handleChange}
                className="min-h-[100px] max-h-[300px] h-28 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg py-4 px-4"
              ></textarea>
            </div>
            <div className="flex flex-row gap-4 items-center justify-center mt-6">
              <button
                className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                type="submit"
              >
                Confirm
              </button>
              <button
                onClick={() => navigate(-1)}
                className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                type="button"
              >
                Back
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
