import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSocket } from "../../../contexts/SocketContext";
import { useUser } from "../../../contexts/UserContext";
import EditSale from "./EditSale";

const PUBLIC_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PUBLIC_URL
    : "http://localhost:5000/";

export default function SaleDetails() {
  const { businessId, saleId } = useParams();
  const navigate = useNavigate();
  const [sale, setSale] = useState();
  const [editSale, setEditSale] = useState(false);
  const [downloadedFile, setDownloadedFile] = useState(null);

  const fetchSale = async () => {
    try {
      const res = await axios.get(`/api/v1/erp/${businessId}/sales/${saleId}`);
      if (res.data.sale) {
        setSale(res.data.sale);
      }
    } catch (error) {
      console.error("Error fetching sale details:", error);
    }
  };

  const handleDownload = async (files) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const response = await fetch(`${PUBLIC_URL}uploads/${files[i]}`);
        const parts = response.url.split("/");
        const name = parts[parts.length - 1];
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setDownloadedFile(blobUrl);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `${name}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    fetchSale();
  }, [saleId]);

  const renderStepButtons = () => {
    if (sale) {
      switch (sale.currentStep) {
        case 1:
          return (
            <div>
              {sale.status === "paid" && (
                <div>
                  <span className="text-green-600">Prepare delivery</span>
                  <button
                    className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-[#8d4b49] hover:shadow-lg ml-2"
                    onClick={async () => {
                      await axios.put(
                        `/api/v1/erp/${businessId}/sales/${saleId}`,
                        {
                          status: "processing",
                          currentStep: sale.currentStep + 1,
                        }
                      );
                      fetchSale();
                    }}
                  >
                    Mark as Preparing
                  </button>
                </div>
              )}
              {sale.status === "unpaid" && (
                <div>
                  <span className="text-green-600">
                    Waiting for client's payment
                  </span>
                </div>
              )}
            </div>
          );
        case 2:
          return (
            <div>
              {sale.status === "processing" && (
                <div>
                  <span className="text-green-600">Ship order</span>
                  <button
                    className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-[#8d4b49] hover:shadow-lg ml-2"
                    onClick={async () => {
                      await axios.put(
                        `/api/v1/erp/${businessId}/sales/${saleId}`,
                        {
                          status: "shipped",
                          currentStep: sale.currentStep + 1,
                        }
                      );
                      fetchSale();
                    }}
                  >
                    Mark as Shipped
                  </button>
                </div>
              )}
            </div>
          );
        case 3:
          return (
            <div>
              {sale.status === "shipped" && (
                <div>
                  <span className="text-green-600">
                    Waiting for courier to drop delivery
                  </span>
                  <button
                    className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-[#8d4b49] hover:shadow-lg ml-2"
                    onClick={async () => {
                      await axios.put(
                        `/api/v1/erp/${businessId}/sales/${saleId}`,
                        {
                          status: "delivered",
                          currentStep: sale.currentStep + 1,
                        }
                      );
                      fetchSale();
                    }}
                  >
                    Mark as Delivered
                  </button>
                </div>
              )}
            </div>
          );
        case 4:
          return (
            <div>
              {sale.status === "delivered" && (
                <div>
                  <span className="text-green-600">
                    Courier delivered. Order is completed!
                  </span>
                </div>
              )}
            </div>
          );
        default:
          return null;
      }
    }
  };

  // Function to extract sizes and quantities
  const getFilteredQuantities = () => {
    if (!sale || !sale.items || sale.items.length === 0) return [];

    // Iterate through each item in the sale
    return sale.items.flatMap((item, itemIndex) => {
      if (!item.outputImages || item.outputImages.length === 0) return [];

      // Iterate through each output image in the item
      return item.outputImages.flatMap((image, imageIndex) => {
        const { colour, quantities } = image;
        // Filter quantities where quantity is not 0
        const filteredQuantities = quantities.filter(
          (item) => item.quantity !== 0
        );

        // Map filtered quantities to JSX
        return filteredQuantities.map((item, index) => (
          <div key={`${itemIndex}-${imageIndex}-${index}`} className="mb-2">
            <strong>Colour:</strong> {colour} <br />
            <strong>Size:</strong> {item.size} <br />
            <strong>Quantity:</strong> {item.quantity} <br />
          </div>
        ));
      });
    });
  };

  return (
    <div className="bg-gray-100 py-8 px-4 mt-4">
      <div className=" bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-[#8e2421]">Sale Details</h1>
          <button
            onClick={() => navigate(-1)}
            className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
        </div>
        {sale && !editSale ? (
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Sale Information
            </h2>
            <p className="mb-2">
              <strong>Sale ID:</strong> {sale._id}
            </p>
            <p className="mb-2">
              <strong>Status:</strong>{" "}
              <span className="text-green-600">{sale.status}</span>
            </p>
            <p className="mb-2">
              <strong>Amount:</strong> {sale.currency}{" "}
              {Number(sale.amount / 100).toFixed(2)}
            </p>
            <p className="mb-2">
              <strong>Fees:</strong> {Number(sale.paymentFee / 100).toFixed(2)}
            </p>
            <p className="mb-2">
              <strong>Payout:</strong> {sale.currency}{" "}
              {Number((sale.amount - sale.paymentFee) / 100).toFixed(2)}
            </p>
            <p className="mb-2">
              <strong>Creation Date:</strong>{" "}
              {moment(sale.date).format("DD MMM YY @ HH:mm")}
            </p>
            {sale.client_email && (
              <p className="mb-2">
                <strong>Client Email:</strong> {sale.client_email}
              </p>
            )}
            {sale.shippingDetails && (
              <div className="mb-2">
                <strong>Shipping Address:</strong>
                <p>
                  {sale.shippingDetails.address?.line1},{" "}
                  {sale.shippingDetails.address?.city},{" "}
                  {sale.shippingDetails.address?.country},{" "}
                  {sale.shippingDetails.address?.state},{" "}
                  {sale.shippingDetails.address?.postal_code}
                </p>
              </div>
            )}
            {sale.order_url && (
              <div className="mb-2">
                <strong>Order URL:</strong>
                <p>{sale.order_url}</p>
              </div>
            )}
            {sale.file && (
              <div className="mb-2">
                <strong>File:</strong>
                <p>{sale.file.split("/")[1]}</p>
                <img src={`/${sale.file}`} alt="File" className="mt-2" />
                <a
                  className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-[#8b403d] hover:shadow-lg mt-2 inline-block"
                  href={`/${sale.file}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Open in New Tab
                </a>
              </div>
            )}
            <div className="mb-4">
              <strong>Files:</strong>
              <button
                className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-[#8d4b49] hover:shadow-lg ml-2"
                onClick={() => handleDownload(sale.files)}
              >
                Download Design
              </button>
            </div>
            {getFilteredQuantities().length > 0 && (
              <div className="mt-4">{getFilteredQuantities()}</div>
            )}
            <div className="steps-wrapper">
              <strong>Current Step #{sale.currentStep}</strong>
              <div className="step-buttons mt-2">{renderStepButtons()}</div>
            </div>
          </div>
        ) : (
          <EditSale handleEditSale={() => setEditSale(!editSale)} sale={sale} />
        )}
      </div>
    </div>
  );
}
