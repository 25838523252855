import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { addBankAccount } from "../../../../apiCalls/accountingCalls";

import moment from "moment";
import { useUser } from "../../../../contexts/UserContext";

export default function AddBankAccountDialog(props) {
  const { updateUser } = useUser();
  const [state, setState] = useState();

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <h1>Add bank account</h1>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="amount"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <span className="save-btn">
          <button
            onClick={async () => {
              let date = new Date();
              let bankAccount = {
                name: state.name,
                amount: state.amount,
                start_amount: state.amount,
                currency: props.budget?.currency,
                date,
              };
              await addBankAccount(props.budget._id, bankAccount);
              updateUser();
              props.onClose();
            }}
          >
            save
          </button>
        </span>
      </Dialog>
    </div>
  );
}
