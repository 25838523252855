import React, { useEffect, useState } from "react";
import ImageUploader from "../ImageUploader/ImageUploader";
import DesignAreaSetter from "../DesignAreaSetter/DesignAreaSetter";

const CustomizableProductForm = ({
  side,
  onImageUpload,
  onDesignAreaSet,
  img,
  customItems,
  selectedColour,
  selectedCustomImage,
}) => {
  const [image, setImage] = useState(null);
  const [designAreaSet, setDesignAreaSet] = useState(false);

  console.log(selectedCustomImage);

  useEffect(() => {
    // console.log(customItems);
    // console.log(selectedCustomImage);

    const customItem = customItems.find(
      (item) => item.colour === selectedColour
    );

    if (customItem) {
      // console.log("Matching custom item found:", customItem);

      // Check if customImages exists and has at least one element
      if (customItem.customImages && customItem.customImages.length > 0) {
        // console.log(customItem.customImages[0]);

        const customImage = customItem.customImages.find(
          (image) => image.name === side
        );

        if (customImage) {
          console.log(
            `Matching custom image found for ${side} and ${selectedColour}:`,
            customImage
          );

          const selectedCustomImage = customImage.backgroundImg
            ? customImage.backgroundImg
            : customImage.url;

          console.log(selectedCustomImage);
          setImage(customImage);
        } else {
          console.log(
            "No image found matching side:",
            side,
            "and colour:",
            selectedColour
          );
        }
      } else {
        console.log("No customImages found for custom item:", customItem);
      }
    } else {
      console.log("No custom item found matching colour:", selectedColour);
    }
  }, [customItems, selectedColour, side]);

  const handleImageUpload = (file) => {
    console.log(file);
    onImageUpload(side, file);
  };

  const handleSetDesignArea = (designArea) => {
    console.log("Design are set");
    // Implement functionality to set the design area
    setDesignAreaSet(!designAreaSet);
  };

  const handleAddCustomItem = (customItem) => {
    console.log("Add custom item");
    console.log(image, side);
    console.log(customItem);
    onDesignAreaSet(customItem, image);
  };

  return (
    <div>
      <div className="flex flex-row gap-4 items-center flex-wrap">
        <h2>Upload Image for {side}</h2>
        <ImageUploader
          onFileChange={handleImageUpload}
          image={image} //{selectedCustomImage}
        />
        <DesignAreaSetter
          handleSetDesignArea={handleSetDesignArea}
          handleAddCustomItem={handleAddCustomItem}
          img={image}
          designAreaSet={designAreaSet}
          side={side}
          selectedColour={selectedColour}
        />
      </div>
    </div>
  );
};

export default CustomizableProductForm;
