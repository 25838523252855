import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import { usePopUp } from "../../contexts/PopUpContext";
import { useUser } from "../../contexts/UserContext";
import { deleteProduct } from "../../apiCalls/adminCalls";
import { useProduct } from "../../contexts/ProductsContext";

const Marketplace = () => {
  const { addToCart } = useCart();
  const { openPopUp } = usePopUp();
  const { user } = useUser();
  const { products } = useProduct();
  const navigate = useNavigate();

  const [hoveredVideo, setHoveredVideo] = useState(null);

  const [editProduct, setEditProduct] = useState(false);

  const handleEditProduct = () => {
    console.log(editProduct);
    setEditProduct(!editProduct);
  };

  const handleHover = (index) => {
    setHoveredVideo(index);
  };

  const handleLeave = () => {
    setHoveredVideo(null);
  };

  const handleAddToCart = (productId) => {
    addToCart(productId);
    openPopUp("Item added to cart.", 500);
  };

  if (user && user.isAdmin) {
    return (
      <div className="px-8 py-4 bg-gray-100 min-h-screen">
        <button className="bg-[#8e2421] text-white font-semibold px-4 py-2 rounded-md mb-4 hover:bg-[#8b4f4d]">
          Add Product
        </button>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {products.map((product, index) => (
            <div
              className="bg-white shadow-lg rounded-lg overflow-hidden"
              key={product.id}
            >
              <div className="p-4">
                <button
                  onClick={handleEditProduct}
                  className="bg-yellow-500 text-white font-semibold px-3 py-1 rounded-md mb-2 hover:bg-yellow-600"
                >
                  Edit
                </button>
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-48 object-cover mb-4"
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={handleLeave}
                />
                <div className="product-details">
                  <h3 className="text-lg font-semibold mb-2">{product.name}</h3>
                  <p className="text-gray-700 mb-2">${product.price}</p>
                  <p className="text-gray-600 mb-4">{product.description}</p>
                  <button
                    className="bg-red-500 text-white font-semibold px-4 py-2 rounded-md mb-2 hover:bg-red-600"
                    onClick={() => deleteProduct(product.id)}
                  >
                    Delete
                  </button>
                  <Link
                    to={`/marketplace/${product.id}`}
                    className="bg-[#8e2421] text-white font-semibold px-4 py-2 rounded-md hover:bg-[#8b4f4d]"
                  >
                    Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="px-8 py-4 bg-gray-100 mt-4 mr-4 md:m-8 md:mb-32 rounded-md">
        <div className="mb-4 text-center">
          <Link
            to="/custom-order"
            className="bg-[#8e2421]  text-white font-semibold px-4 py-2 rounded-md mb-4 hover:bg-[#8b4f4d]"
          >
            Make a Custom Order
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {products.map(
            (product, index) =>
              product.onMarketplace && (
                <div
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={handleLeave}
                  className="bg-white shadow-lg rounded-lg overflow-hidden"
                  key={product.id}
                >
                  <div className="p-4">
                    {hoveredVideo === index && product.video ? (
                      <video
                        src={product.video}
                        alt={product.name}
                        autoPlay
                        muted
                        loop
                        className="w-full h-48 object-cover mb-4"
                        onClick={(event) => {
                          const element = event.target;
                          if (element.requestFullscreen) {
                            element.requestFullscreen();
                          } else if (element.mozRequestFullScreen) {
                            element.mozRequestFullScreen();
                          } else if (element.webkitRequestFullscreen) {
                            element.webkitRequestFullscreen();
                          } else if (element.msRequestFullscreen) {
                            element.msRequestFullscreen();
                          }
                        }}
                      />
                    ) : (
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-48 object-cover mb-4"
                      />
                    )}
                    <div className="product-details">
                      <h3 className="text-lg font-semibold mb-2">
                        {product.name}
                      </h3>
                      <p className="text-gray-700 mb-2">
                        £{(product.price / 100).toFixed(2)}
                      </p>
                      <p className="text-gray-600 mb-4">
                        {product.description}
                      </p>
                      <div className="product-buttons">
                        {product.booking ? (
                          <Link
                            className="bg-[#8e2421] text-white font-semibold px-4 py-2 rounded-md hover:bg-[#8b4f4d]"
                            onClick={() => navigate("/book-consultancy")}
                          >
                            Book
                          </Link>
                        ) : (
                          <>
                            {product.test ? (
                              <button
                                className="bg-[#8e2421] text-white font-semibold px-4 py-2 rounded-md mb-2 hover:bg-[#8b4f4d]"
                                onClick={() => handleAddToCart(product.id)}
                              >
                                Add to Cart
                              </button>
                            ) : (
                              <span className="text-gray-500">
                                Coming Soon!
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
};

export default Marketplace;
