import React from "react";
import { useCart } from "../../contexts/CartContext";
import { Link } from "react-router-dom";

export default function Cart() {
  const { cart, removeOneFromCart, removeAllFromCart, emptyCart } = useCart();

  const handleRemoveOne = (productId) => {
    removeOneFromCart(productId);
  };

  const handleRemoveAll = (productId) => {
    removeAllFromCart(productId);
  };

  const handleEmptyCart = () => {
    emptyCart();
  };

  return (
    <div className="min-h-screen mt-4">
      <div className="bg-white shadow-lg rounded-lg p-6 max-w-2xl mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Cart</h2>
        {cart.length === 0 ? (
          <p className="text-gray-700">Your cart is empty.</p>
        ) : (
          <>
            <div className="space-y-4">
              {cart.map((item) => (
                <div
                  key={item.id}
                  className="items flex justify-between items-center bg-gray-50 p-4 rounded-md shadow-sm"
                >
                  <div>
                    <p className="text-lg font-medium">{item.name}</p>
                    <p className="text-gray-700">
                      Quantity: {item.quantity} - Total: £
                      {((item.quantity * item.price) / 100).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleRemoveOne(item.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                    >
                      Remove One
                    </button>
                    <button
                      onClick={() => handleRemoveAll(item.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600"
                    >
                      Remove All
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center mt-6">
              <button
                onClick={handleEmptyCart}
                className="bg-yellow-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-yellow-600"
              >
                Empty Cart
              </button>
              <Link
                to="/checkout/1"
                className="bg-blue-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Checkout
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
