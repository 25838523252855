import React, { useState, useEffect } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";
import {
  createBooking,
  deleteBooking,
  getBookings,
  setAvailability,
} from "../../apiCalls/bookingsCalls";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import { useUser } from "../../contexts/UserContext";
import { usePopUp } from "../../contexts/PopUpContext";
import axios from "axios";
import { useSocket } from "../../contexts/SocketContext";

export default function AdminCalendar() {
  const { addMultipleToCart } = useCart();
  const { user } = useUser();
  const socket = useSocket();
  const { openPopUp } = usePopUp();
  const navigate = useNavigate();

  const [bookings, setBookings] = useState();
  const [bookingSelected, setBookingSelected] = useState();
  const [openBookingDetails, setOpenBookingDetails] = useState(false);

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState();
  const [weekDays, setWeekDays] = useState();

  const [booking, setBooking] = useState();
  const [availableSlots, setAvailableSlots] = useState([]);

  const allSlots = [
    {
      day: "Monday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Tuesday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Wednesday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Thursday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Friday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Saturday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
    {
      day: "Sunday",
      slots: [
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
    },
  ];

  async function getBooking() {
    let res = await axios.get(`/api/bookings/get-active`);
    setBooking(res.data);
    if (res.data.availableSlots) {
      setAvailableSlots(res.data.availableSlots);
    }
  }

  const handleGetBookings = async () => {
    let bookings = await getBookings();
    setBookings(bookings);
  };

  const handleOpenBookingDetails = (booking) => {
    if (!openBookingDetails) {
      setBookingSelected(booking);
      setOpenBookingDetails(!openBookingDetails);
    } else setOpenBookingDetails(!openBookingDetails);
  };

  const getCurrentWeek = () => {
    const today = new Date();
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const slicedDays = daysLabels
      .slice(startOfWeek)
      .concat(daysLabels.slice(0, startOfWeek));
    const monthLength = getMonthLength(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth()
    );
    const diff = monthLength - startOfWeek.getDate();
    let endOfWeek,
      weekDays = [],
      days = [];

    switch (true) {
      case diff >= 7:
        endOfWeek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 7
        );
        for (let i = 0; i < 7; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        break;
      case diff === 6:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        for (let i = 0; i < 6; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        days.push({
          day: 6,
          date: new Date(today.getFullYear(), today.getMonth() + 1, 1),
        });
        break;
      case diff === 5:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 2);
        for (let i = 0; i < 5; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 2; i++) {
          days.push({
            day: i + 5,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 4:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 3);
        for (let i = 0; i < 4; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 3; i++) {
          days.push({
            day: i + 4,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 3:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 4);
        for (let i = 0; i < 3; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 4; i++) {
          days.push({
            day: i + 3,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 2:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 5);
        for (let i = 0; i < 2; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 5; i++) {
          days.push({
            day: i + 2,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      case diff === 1:
        endOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 6);
        for (let i = 0; i < 1; i++) {
          days.push({
            day: i,
            date: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + i
            ),
          });
        }
        for (let i = 0; i < 6; i++) {
          days.push({
            day: i + 2,
            date: new Date(today.getFullYear(), today.getMonth() + 1, i),
          });
        }
        break;
      default:
        console.log(`Default diff: ${diff}`);
        break;
    }

    let mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  function getMonthLength(year, month) {
    const date = new Date(year, month, 1);
    date.setMonth(date.getMonth() + 1, 0);
    const monthLength = date.getDate();

    return monthLength;
  }

  const getNextWeekEndStart = (prevEnd) => {
    let startDate = calculateNextStartDate(prevEnd);
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let days = [],
      weekDays = [];

    const firstDayOfWeek = startDate.getDay();
    const slicedDays = daysLabels
      .slice(firstDayOfWeek)
      .concat(daysLabels.slice(0, firstDayOfWeek));

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + i
      );
      days.push({
        day: i + 1,
        date: currentDate,
      });
    }

    const mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  const getPrevWeekEndStart = (prevStart) => {
    let startDate = calculatePrevStartDate(prevStart);
    const daysLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let days = [],
      weekDays = [];
    const firstDayOfWeek = prevStart.getDay();
    const slicedDays = daysLabels
      .slice(firstDayOfWeek)
      .concat(daysLabels.slice(0, firstDayOfWeek));

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + i
      );
      days.push({
        day: i + 1,
        date: currentDate,
      });
    }

    const mappedWeekDays = slicedDays.map((day) => {
      const slots = allSlots.find((slot) => slot.day === day)?.slots || [];

      return { day, slots, days };
    });

    mappedWeekDays.map(({ day, slots, days }, index) => {
      const [hourStr, minuteStr] = slots[index].split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      days[index].date.setHours(hour);
      days[index].date.setMinutes(minute);

      weekDays.push({
        day,
        date: days[index].date,
        formattedDate: days[index].date,
        slots,
      });
    });
    weekDays.start = days[0].date;
    weekDays.end = days[days.length - 1].date;
    return weekDays;
  };

  const calculatePrevStartDate = (prevStart) => {
    const startDate = new Date(
      prevStart.getFullYear(),
      prevStart.getMonth(),
      prevStart.getDate() - 7
    );
    return startDate;
  };

  const calculateNextStartDate = (prevEnd) => {
    const startDate = new Date(
      prevEnd.getFullYear(),
      prevEnd.getMonth(),
      prevEnd.getDate() + 1
    );
    return startDate;
  };

  const handleSlotClick = (day, slot, date, formattedDate) => {
    const [hourStr, minuteStr] = slot.split(":");
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);
    const modifiedDate = new Date(date);
    modifiedDate.setHours(hour);
    modifiedDate.setMinutes(minute);

    const selectedSlot = {
      day,
      slot,
      date,
      formattedDate: formatDate(modifiedDate) + " " + slot,
    };
    const isSlotSelected = selectedSlots.some(
      ({ day: selectedDay, slot: selectedSlot, date: selectedDate }) =>
        day === selectedDay &&
        slot === selectedSlot &&
        date.getTime() === selectedDate.getTime()
    );
    if (isSlotSelected) {
      // If the slot is already selected, remove it from the selectedSlots
      setSelectedSlots((prevSelectedSlots) => {
        return prevSelectedSlots.filter(
          ({ day: selectedDay, slot: selectedSlot, date: selectedDate }) => {
            return (
              day !== selectedDay ||
              slot !== selectedSlot ||
              date.getTime() !== selectedDate.getTime()
            );
          }
        );
      });
    } else {
      // If the slot is not selected, add it to the selectedSlots
      setSelectedSlots((prevSelectedSlots) => [
        ...prevSelectedSlots,
        selectedSlot,
      ]);
    }
  };

  const calculateTotalAmount = () => {
    return selectedSlots.length * 50;
  };

  const handlePreviousWeek = () => {
    const weekDays = getPrevWeekEndStart(selectedWeek.start);
    const newSelectedWeek = {
      start: weekDays.start,
      end: weekDays.end,
    };

    setSelectedWeek(newSelectedWeek);
    setWeekDays(weekDays);
  };

  const handleNextWeek = () => {
    const weekDays = getNextWeekEndStart(selectedWeek.end);
    const newSelectedWeek = {
      start: weekDays.start,
      end: weekDays.end,
    };

    setSelectedWeek(newSelectedWeek);
    setWeekDays(weekDays);
  };

  const isDatePassed = (date) => {
    const today = new Date();
    // today.setHours(19);
    // console.log(today, date);
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      date
    );
    return date.getTime() < today.getTime();
  };

  const formatDate = (date) => {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const dayName = dayNames[date.getDay()];
    const dayNumber = date.getDate();
    const suffix = getOrdinalSuffix(dayNumber);

    const abbreviatedDayName = dayName.slice(0, 3);
    const formattedDate = `${abbreviatedDayName} ${dayNumber}${suffix}`;

    return formattedDate;
  };

  const getOrdinalSuffix = (number) => {
    const lastDigit = Number(number) % 10;
    const lastTwoDigits = Number(number) % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return "th";
    }

    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const renderCalendar = () => {
    return (
      <div className="calendar">
        {weekDays?.map(({ day, date, slots, formattedDate }) => (
          <div key={day} className="day">
            <span className="day-label">{formatDate(date)}</span>
            <div className="day-column">
              {slots.map((slot) => {
                const [hourStr, minuteStr] = slot.split(":");
                const hour = parseInt(hourStr, 10);
                const minute = parseInt(minuteStr, 10);
                date.setHours(hour);
                date.setMinutes(minute);

                const isSelected = selectedSlots.find(
                  ({
                    day: selectedSlotDay,
                    slot: selectedSlot,
                    date: selectedSlotDate,
                  }) =>
                    selectedSlotDate.getTime() === date.getTime() &&
                    slot === selectedSlot &&
                    day === selectedSlotDay
                );

                let isAvailable = availableSlots.find(
                  ({ day: availableDay, slots: availableSlots }) =>
                    day === availableDay && availableSlots.includes(slot)
                );

                booking?.notAvailableSlots.forEach((notAvSlot) => {
                  let d = new Date(notAvSlot.date);
                  if (date.toString() === d.toString()) {
                    isAvailable = false;
                  }
                });

                const colorClass =
                  isSelected && !isDatePassed(date)
                    ? "green"
                    : isAvailable && !isDatePassed(date)
                    ? "blue"
                    : "red";

                const handleClick = () => {
                  const [hourStr, minuteStr] = slot.split(":");
                  const hour = parseInt(hourStr, 10);
                  const minute = parseInt(minuteStr, 10);
                  date.setHours(hour);
                  date.setMinutes(minute);
                  if (!isDatePassed(date)) {
                    handleSlotClick(day, slot, date, formattedDate);
                  }
                };

                return (
                  <div
                    key={`${day}-${slot}`}
                    className={`slot ${colorClass}`}
                    onClick={handleClick}
                  >
                    {slot}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleConfirm = async () => {
    if (selectedSlots.length >= 1) {
      let res = await axios.put(
        `/api/bookings/${bookingSelected._id}/set-availability`,
        {
          availability: selectedSlots,
        }
      );
      getBooking();
      clearSlots();
      socket.emit("bookingUpdate");
      openPopUp("Availability updated", 1000);
      //   navigate("/checkout");
    } else {
      openPopUp("Please select a slot first.", 750);
    }
  };

  const clearSlots = () => {
    setSelectedSlots([]);
  };

  useEffect(() => {
    getBooking();
    handleGetBookings();
    if (!selectedWeek) {
      const week = getCurrentWeek();
      setSelectedWeek(week);
      setWeekDays(week);
      // console.log(week);
    } else {
      // console.log(selectedSlots);
      // console.log(weekDays);
    }
  }, [selectedWeek, selectedSlots]);

  return (
    <div className="content">
      <div className="booking-calendar">
        <h1>Book a slot</h1>
        <p className="timezone">Timezone: GMT</p>
        <div className="navigation">
          <button className="arrow-button" onClick={handlePreviousWeek}>
            <BsFillArrowLeftCircleFill className="arrow-icon" />
          </button>
          <div className="month-year">
            {selectedWeek?.start?.toLocaleString("default", {
              month: "long",
              year: "numeric",
            })}
          </div>
          <button className="arrow-button" onClick={handleNextWeek}>
            <BsFillArrowRightCircleFill className="arrow-icon" />
          </button>
        </div>
        <p>Set availability for this booking and then click confirm</p>
        <button className="button" onClick={createBooking}>
          create booking
        </button>
        <button className="button" onClick={handleGetBookings}>
          get bookings
        </button>
        <Link to="/marketplace" className="button">
          Back to Marketplace
        </Link>
        {openBookingDetails ? (
          <div>{bookingSelected ? renderCalendar() : null}</div>
        ) : (
          bookings?.map((booking) => (
            <div key={booking._id}>
              <button onClick={() => handleOpenBookingDetails(booking)}>
                {booking.name}
              </button>
              <button
                onClick={() => {
                  deleteBooking(booking._id);
                  handleGetBookings();
                }}
              >
                delete
              </button>
              <button
                onClick={async () => {
                  let res = await axios.put(
                    `/api/bookings/${booking._id}/set-active`
                  );
                  console.log(res);
                  handleGetBookings();
                }}
              >
                Mark as {booking.active ? "not active" : "active"}
              </button>
            </div>
          ))
        )}
        <div className="selected-slots">
          {selectedSlots?.map((slot) => (
            <div>{slot.formattedDate}</div>
          ))}
        </div>
        <div className="buttons">
          <Link to="/marketplace" className="button">
            Back to Marketplace
          </Link>
          <a className="button green" onClick={handleConfirm}>
            Confirm
          </a>
          <a className="button" onClick={clearSlots}>
            Clear Slots
          </a>
        </div>
      </div>
    </div>
  );
}
