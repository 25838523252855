import React from "react";
import Roadmap from "../Roadmap/Roadmap";
import whitePaper from "../../assets/white-paper.pdf";
import moment from "moment";
import { Link } from "react-router-dom";
import { BsDiscord, BsLinkedin, BsTelegram, BsGithub } from "react-icons/bs";

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <section className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>
        <p className="text-lg mb-4">
          Welcome to our innovative software development company, founded in
          2019 by Lorenzo Ghidoni. Our vision is to foster a truly open-sourced
          software company that addresses economic disparity and empowers
          individuals by providing the necessary tools to thrive in both their
          professional and daily lives.
        </p>
        <p className="text-lg mb-4">
          Our primary goal is to establish a dynamic network that connects
          entrepreneurs, businesses, makers, and like-minded individuals, all
          driven by a shared passion for creating and achieving. We firmly
          believe that software should be open source, as even prominent tech
          giants, while utilizing open source software, often fail to contribute
          back to the community.
        </p>
        <p className="text-lg mb-4">
          PS: As of today ({moment().format("DD MMM YYYY")}), I'm actively
          working on various smart contracts (including token, token sale,
          staking, DAO, and more), accounting software, and building a network
          between developers and clients.
        </p>
        <p className="text-lg mb-4">
          Please reach out if you want to help, collaborate, or explore
          partnership opportunities!
        </p>
        <p className="text-center text-xl font-semibold italic text-gray-600 mb-6">
          ...where everything begins
        </p>

        <div className="flex justify-center mb-6">
          <Link
            to="https://www.linkedin.com/company/werewolf-solutions/"
            className="mx-2 hover:text-[#8e2421]"
            target="_blank"
            aria-label="LinkedIn"
          >
            <BsLinkedin className="text-2xl" />
          </Link>
          <Link
            to="https://t.me/+IgwxaWzM-U9kUsG_"
            className="mx-2 hover:text-[#8e2421]"
            target="_blank"
            aria-label="Telegram"
          >
            <BsTelegram className="text-2xl" />
          </Link>
          <Link
            to="https://discord.gg/YfkW23nfUG"
            className="mx-2 hover:text-[#8e2421]"
            target="_blank"
            aria-label="Discord"
          >
            <BsDiscord className="text-2xl" />
          </Link>
          <Link
            to="https://github.com/Werewolf-Solutions"
            className="mx-2 text-gray-800 hover:text-gray-600"
            target="_blank"
            aria-label="GitHub"
          >
            <BsGithub className="text-2xl hover:text-[#8e2421]" />
          </Link>
        </div>

        <div className="text-center">
          <button
            className="bg-[#8e2421] text-white py-2 px-4 rounded-lg hover:bg-[#8e2421] transition"
            onClick={() => window.open(whitePaper, "_blank")}
          >
            Read Our White Paper
          </button>
        </div>

        {/* <section className="mt-8">
          <h2 className="text-2xl font-bold mb-4 text-center">Roadmap</h2>
          <Roadmap />
        </section> */}

        {/* <section className="mt-8">
          <h2 className="text-2xl font-bold mb-4 text-center">Meet The Team</h2>
          <div className="team">
            {/* Implement your team view here */}
        {/* </div>
        </section> */}
      </section>
    </div>
  );
};

export default AboutUs;
