import React, { useState, useRef, useEffect } from "react";
import AddFixedCostDialog from "./AddFixedCostDialog";
import { deleteFixedCost } from "../../../../apiCalls/accountingCalls";
import EditFixedCost from "./EditFixedCost";
import { useUser } from "../../../../contexts/UserContext";

export default function FixedCostsDialog(props) {
  const { updateUser } = useUser();
  const modalRef = useRef(null);
  const { budget } = props;

  const [addFixedCostDialog, setAddFixedCostDialog] = useState(false);
  const [editFixedCostDialog, setEditFixedCostDialog] = useState(false);
  const [fixedCostSelected, setFixedCostSelected] = useState(false);

  const handleEditFixedCostDialog = (cost) => {
    if (cost) {
      setFixedCostSelected(cost);
    }
    setEditFixedCostDialog(!editFixedCostDialog);
  };

  const handleAddFixedCostDialog = () => {
    setAddFixedCostDialog(!addFixedCostDialog);
  };

  // Add an event listener to handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        props.open && props.onClose();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [props.open]);
  return (
    <div>
      <AddFixedCostDialog
        open={addFixedCostDialog}
        onClose={handleAddFixedCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        updateBudget={props.updateBudget}
      />
      <EditFixedCost
        open={editFixedCostDialog}
        onClose={handleEditFixedCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        fixedCost={fixedCostSelected}
        updateBudget={props.updateBudget}
      />
      <div
        className="modal"
        style={{ display: props.open ? "block" : "none" }}
        ref={modalRef}
      >
        <span className="table-title">
          <h1>
            Fixed costs {budget?.tot_fixed_costs} {budget?.currency}
          </h1>
          <button onClick={handleAddFixedCostDialog}>add</button>
          <button onClick={props.onClose}>close</button>
        </span>
        <br />
        {budget?.fixed_costs?.map((cost) => (
          <>
            <span className="table-name">
              {cost?.name}
              <button
                onClick={async () => {
                  console.log(cost._id);
                  await deleteFixedCost(budget._id, cost._id);
                  updateUser();
                }}
              >
                delete
              </button>
              <button onClick={() => handleEditFixedCostDialog(cost)}>
                edit
              </button>
            </span>

            <span className="table-sub-name">
              Amount:
              <span className="table-props-text">
                {cost?.amount} {cost?.currency}
              </span>
            </span>
            {/* <span className="table-sub-name">
              Due day:
              <span className="table-props-text">{cost?.due_day}</span>
            </span> */}
            <span className="table-sub-name">
              Date:
              <span className="table-props-text">{cost?.formatted_date}</span>
            </span>
            {/* <span className="table-sub-name">
              Frequency:
              <span className="table-props-text">{cost?.frequency}</span>
            </span>
            <span className="table-sub-name">
              To: <span className="table-props-text">{cost?.to}</span>
            </span> */}
            <span className="table-sub-name">
              From: <span className="table-props-text">{cost?.from?.name}</span>
            </span>
            {/* <span className="table-sub-name">
              Notes: <span className="table-props-text">{cost?.notes}</span>
            </span> */}
            <br />
          </>
        ))}
      </div>
    </div>
  );
}
