import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CustomizableProductForm from "../../../Components/CustomizableProductForm/CustomizableProductForm";
import ImageUploader from "../../../Components/ImageUploader/ImageUploader";
import DesignAreaSetter from "../../../Components/DesignAreaSetter/DesignAreaSetter";
import EditDesignArea from "../../../Components/EditDesignArea/EditDesignArea";
import ImageCarouselWithUploader from "../../../Components/ImageCarouselWithUploader/ImageCarouselWithUploader";
import UpdateImageUploader from "../../../Components/UpdateImageUploader/UpdateImageUploader";
import ColourSelector from "../../../Components/ColourSelector/ColourSelector";
import SizeSelector from "../../../Components/SizeSelector/SizeSelector";
import { utils } from "../../../js/utils";

const { REACT_APP_PUBLIC_URL } = process.env;

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : REACT_APP_PUBLIC_URL;

export default function EditInventoryItem() {
  const { businessId, inventoryItemId } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    category: "",
    totQuantity: "",
    name: "",
    price: "",
    description: "",
  });

  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);
  const [newImg, setNewImg] = useState(false);
  const [inventoryItem, setInventoryItem] = useState(null);
  const [customItems, setCustomItems] = useState(null);
  const [image, setImage] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedColour, setSelectedColour] = useState("None");
  const [sizes, setSizes] = useState([]);
  const [selectedCustomImages, setSelectedCustomImages] = useState([]);
  const [displayPrice, setDisplayPrice] = useState("");
  const [quantityMap, setQuantityMap] = useState({});
  const [totQuantity, setTotQuantity] = useState(0);

  const sides = ["front", "back", "right", "left"];
  const selectedSides = selectedCustomImages.map((img) => img.name);

  const orderedSelectedCustomImages = sides
    .map((side) => selectedCustomImages.find((img) => img.name === side))
    .filter((img) => img); // remove undefined entries

  const handleColourSelected = (color) => {
    setSelectedColour(color);
    console.log(`Colour selected: ${color}`);
    console.log(customItems);
  };

  const handleSizeSelected = (size) => {
    setSelectedSize(size);
    console.log(`Size selected: ${size}`);
  };

  const handleChange = useCallback((e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    if (e.target.id === "price") {
      const formattedPrice = (Number(e.target.value) / 100).toFixed(2);
      setDisplayPrice(formattedPrice);
    }
  }, []);

  const handleUploadImages = (imgs) => {
    console.log(imgs);
    setImages(imgs);
  };

  const onFileChange = useCallback((event) => {
    console.log(event.target);
    if (event.target.files[0]) {
      setNewImg(true);
      setFile(event.target.files[0]);
      let image = URL.createObjectURL(event.target.files[0]);
      setImg(image);
    }
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    console.log(selectedColour);
    console.log(quantityMap);
    console.log(totQuantity);
    console.log(selectedSize);
    console.log(customItems);
    console.log(images);
    try {
      let uploadedImg;
      let uploadedImages = [];
      for (let i = 0; i < images.length; i++) {
        uploadedImg = await uploadImage(images[i]);
        console.log(uploadedImg);
        uploadedImages.push(uploadedImg);
      }
      console.log(uploadedImages);
      // Update the product with the image URL
      const updatedItem = {
        _id: inventoryItemId,
        category: state.category,
        totQuantity: totQuantity,
        name: state.name,
        price: state.price,
        description: state.description,
        imgs: uploadedImages.length != 0 ? uploadedImages : inventoryItem.imgs,
        // customItems: customItems,
      };

      // If custom items
      if (customItems) {
        // upload new images
        for (let i = 0; i < customItems.length; i++) {
          for (let j = 0; j < customItems[i].customImages.length; j++) {
            let image = customItems[i].customImages[j].backgroundImg;
            if (image) {
              uploadedImg = await uploadImage(image);
              customItems[i].customImages[j].url = uploadedImg;
            }
          }
        }
      }
      updatedItem.customItems = customItems;
      console.log(updatedItem);

      const res = await updateItem(updatedItem);
      if (res.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await axios.post(
        `/api/v1/erp/${businessId}/inventory/upload-img`,
        formData
      );
      return res.data.img;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const updateItem = async (item) => {
    try {
      const res = await axios.put(
        `/api/v1/erp/${businessId}/inventory/${item._id}`,
        item
      );
      return res;
    } catch (error) {
      console.error("Error updating item:", error);
      return null;
    }
  };

  const fetchInventoryItem = async () => {
    try {
      const res = await axios.get(
        `/api/v1/erp/${businessId}/inventory/${inventoryItemId}`
      );
      if (res.data.inventoryItem) {
        setInventoryItem(res.data.inventoryItem);
        if (res.data.inventoryItem.customItems.length != 0) {
          setCustomItems(res.data.inventoryItem.customItems);
          setCurrentItem(res.data.inventoryItem.customItems[0]);
          setSelectedColour(res.data.inventoryItem.customItems[0].colour);
          setSelectedSize(res.data.inventoryItem.customItems[0].sizes[0].size);
          setSizes(res.data.inventoryItem.customItems[0].sizes);
          setSelectedCustomImages(
            res.data.inventoryItem.customItems[0].customImages
          );
        }
        setState({
          category: res.data.inventoryItem.category,
          totQuantity: res.data.inventoryItem.customItems[0].sizes[0].quantity,
          name: res.data.inventoryItem.name,
          price: res.data.inventoryItem.price,
          description: res.data.inventoryItem.description,
        });
      }
    } catch (error) {
      console.error("Error fetching inventory item details:", error);
    }
  };

  const handleNewImageUpload = async (side, newImage) => {
    console.log(side, newImage);
    console.log(selectedColour);
    console.log(customItems);

    const newImageDetails = {
      backgroundImg: newImage,
      saved: false,
      designArea: null,
      percCoords: null,
      side: side,
    };
    console.log(newImageDetails);

    let customImg = {
      backgroundImg: newImage,
      name: side,
      url: "",
      designArea: {
        front: { top: "", left: "", width: "", height: "" },
        back: { top: "", left: "", width: "", height: "" },
        right: { top: "", left: "", width: "", height: "" },
        left: { top: "", left: "", width: "", height: "" },
      },
    };
    if (newImage === "") {
      setCustomItems((prevItems) => {
        return prevItems.map((prevItem) => {
          if (prevItem.colour === selectedColour) {
            if (prevItem.customImages.length === 0) {
              return {
                ...prevItem,
                customImages: [customImg],
              };
            } else {
              // Update the existing images or add new image if name does not match
              const updatedImages = prevItem.customImages.map((imageSet) => {
                if (imageSet.name === side) {
                  if (imageSet.backgroundImg) {
                    return {
                      ...imageSet,
                      backgroundImg: newImage,
                      url: newImage,
                    };
                  }
                  return {
                    ...imageSet,
                    url: newImage,
                  };
                }
                return imageSet;
              });

              return {
                ...prevItem,
                customImages: updatedImages,
              };
            }
          }
          return prevItem;
        });
      });
    } else {
      setCustomItems((prevItems) => {
        return prevItems.map((prevItem) => {
          if (prevItem.colour === selectedColour) {
            if (prevItem.customImages.length === 0) {
              return {
                ...prevItem,
                customImages: [customImg],
              };
            } else {
              // Update the existing images or add new image if name does not match
              let imageUpdated = false;
              const updatedImages = prevItem.customImages.map((imageSet) => {
                if (imageSet.name === side) {
                  imageUpdated = true;
                  return { ...imageSet, backgroundImg: newImage };
                }
                return imageSet;
              });

              // Add new image if no matching name was found
              if (!imageUpdated) {
                updatedImages.push(customImg);
              }

              return {
                ...prevItem,
                customImages: updatedImages,
              };
            }
          }
          return prevItem;
        });
      });
    }
  };

  const handleImageUpload = useCallback(
    async (file, imageId) => {
      console.log(file);
      setImage(file);
      const newUrl = await uploadImage(file);

      if (newUrl) {
        console.log(currentItem);

        const updatedCustomItems = inventoryItem.customItems.map(
          (customItem) => {
            const updatedCustomImages = customItem.customImages.map((image) =>
              image._id === imageId ? { ...image, url: newUrl } : image
            );

            return { ...customItem, customImages: updatedCustomImages };
          }
        );

        const updatedItem = {
          ...inventoryItem,
          customItems: updatedCustomItems,
        };
        console.log(updatedItem);

        const res = await updateItem(updatedItem);
        console.log(res);
      }
    },
    [inventoryItem]
  );

  const handleSetDesignArea = useCallback(
    (side) => {
      // const matchedObject = selectedCustomImages.find(
      //   (obj) => obj.name === side
      // );
      // setCurrentItem(matchedObject);
      setActiveModal((prev) => (prev === side ? null : side));
    },
    [inventoryItem]
  );

  const handleAddCustomItem = useCallback(
    (customItem) => {
      console.log("Add custom item");
      console.log(customItem.percCoords);
      // console.log(inventoryItem.customImages);

      // Transform percCoords properties from numbers to strings
      // Transform percCoords properties from numbers to strings
      function transformNumbersToString(obj) {
        const transformedObj = {};
        for (const key in obj) {
          if (typeof obj[key] === "number") {
            transformedObj[key] = obj[key].toString();
          } else {
            transformedObj[key] = obj[key];
          }
        }
        return transformedObj;
      }

      console.log(customItem.percCoords);

      const transformedPercCoords = transformNumbersToString(
        customItem.percCoords
      );
      console.log(transformedPercCoords);

      // Find the index of the item to update
      const itemIndex = inventoryItem.customImages.findIndex(
        (item) => item.name === customItem.side
      );

      if (itemIndex !== -1) {
        // Update the style of the matched item
        inventoryItem.customImages[itemIndex].style[customItem.side] =
          transformedPercCoords;
      } else {
        console.warn(
          `Custom item with name ${customItem.side} not found in customImages array.`
        );
      }

      console.log(inventoryItem);

      updateItem(inventoryItem);
    },
    [inventoryItem]
  );

  const handleDesignAreaSet = (customItem, imageRef) => {
    console.log(customItem);
    console.log(customItems);
    console.log(imageRef);

    const {
      backgroundImg,
      designArea,
      percCoords,
      saved,
      selectedColour,
      side,
    } = customItem;

    function convertValuesToString(obj) {
      Object.keys(obj).forEach(function (key) {
        obj[key] = String(obj[key]);
      });
      return obj;
    }
    console.log({ [side]: convertValuesToString(percCoords) });

    setCustomItems((prevItems) => {
      return prevItems.map((prevItem) => {
        if (prevItem.colour === selectedColour) {
          if (prevItem.customImages.length === 0) {
            throw new Error("customImages is empty");
          } else {
            // Update the existing images
            const updatedImages = prevItem.customImages.map((imageSet) => {
              if (imageSet.name === side) {
                return {
                  ...imageSet,
                  designArea: { [side]: convertValuesToString(percCoords) },
                };
              }
              return imageSet;
            });

            return {
              ...prevItem,
              customImages: updatedImages,
            };
          }
        }
        return prevItem;
      });
    });
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    const quantity = parseInt(value) || 0;
    const key = `${selectedColour}-${selectedSize}`;

    setQuantityMap((prevMap) => ({
      ...prevMap,
      [key]: quantity,
    }));

    setCustomItems((prevItems) =>
      prevItems.map((item) => {
        if (item.colour === selectedColour) {
          return {
            ...item,
            sizes: item.sizes.map((size) => {
              if (size.size === selectedSize) {
                return { ...size, quantity };
              }
              return size;
            }),
          };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    fetchInventoryItem();
    if (customItems) {
      for (let i = 0; i < customItems.length; i++) {
        for (let j = 0; j < customItems[i].sizes.length; j++) {
          const quantity = parseInt(customItems[i].sizes[j].quantity) || 0;
          const key = `${customItems[i].colour}-${customItems[i].sizes[j].size}`;

          setQuantityMap(() => ({
            [key]: quantity,
          }));
        }
      }
    }
  }, [businessId, inventoryItemId]);

  useEffect(() => {
    // Calculate total quantity whenever quantityMap changes
    console.log(quantityMap);
    const total = Object.values(quantityMap).reduce((sum, qty) => sum + qty, 0);
    setTotQuantity(total);
  }, [quantityMap, selectedSize]);

  useEffect(() => {
    if (customItems) {
      console.log(customItems);
      const colourObject = customItems.find(
        (colourObj) => colourObj.colour === selectedColour
      );
      console.log(colourObject);
      console.log(colourObject.customImages);
      setCurrentItem(colourObject);
      setSizes(colourObject ? colourObject.sizes : []);
      setSelectedCustomImages(
        colourObject && colourObject.customImages
          ? colourObject.customImages
          : []
      );

      let quantities = [];
      for (let i = 0; i < customItems.length; i++) {
        for (let j = 0; j < customItems[i].sizes.length; j++) {
          const quantity = parseInt(customItems[i].sizes[j].quantity) || 0;
          const key = `${customItems[i].colour}-${customItems[i].sizes[j].size}`;

          quantities.push({ [key]: quantity });
          // setQuantityMap((prevMap) => ({
          //   ...prevMap,
          //   [key]: quantity,
          // }));
        }
      }
      // console.log(quantities);

      console.log(orderedSelectedCustomImages);

      let map = transformArrayToObject(quantities);
      // console.log(map);
      setQuantityMap(map);
      // console.log(quantityMap);
      // console.log(selectedColour);
      // console.log(selectedSize);
      // console.log(quantityMap[`${selectedColour}-${selectedSize}`]);
      // console.log(currentItem.customImages);
      // for (let i = 0; i < currentItem.customImages.length; i++) {
      //   console.log(
      //     currentItem.customImages.some(
      //       (image) => image.name === currentItem.customImages[i].name
      //     )
      //   );
      // }
    }
  }, [selectedColour, selectedSize, customItems]);

  const transformArrayToObject = (array) => {
    return array.reduce((acc, curr) => {
      return { ...acc, ...curr };
    }, {});
  };

  return (
    <div className="min-h-screen mb-16">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <h1 className="text-[#8e2421] font-extrabold text-xl md:text-3xl">
          Edit Inventory Item
        </h1>
        <span className="text-gray-600 font-medium flex flex-row gap-1 mb-4">
          <strong className="block text-gray-800">Note:</strong>
          visit{" "}
          <a
            href="https://www.remove.bg/"
            target="_blank"
            className="text-blue-600 hover:text-blue-800 underline transition-colors duration-200"
          >
            this website
          </a>{" "}
          to remove background for free.
        </span>
        {customItems && (
          <>
            <ColourSelector
              handleColourSelected={handleColourSelected}
              customItems={customItems}
              setCustomItems={setCustomItems}
            />
            <SizeSelector
              handleSizeSelected={handleSizeSelected}
              customItems={customItems}
              selectedColour={selectedColour}
              setCustomItems={setCustomItems}
              sizes={sizes}
            />
            {selectedColour}-{selectedSize}
            {quantityMap[`${selectedColour}-${selectedSize}`]}
            <div>
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                id="quantity"
                value={quantityMap[`${selectedColour}-${selectedSize}`] || ""}
                onChange={handleQuantityChange}
                className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              />
            </div>
            <div className="flex flex-col gap-4 flex-wrap">
              {selectedCustomImages.length === 0 &&
                sides.map((side) => (
                  <CustomizableProductForm
                    key={side}
                    side={side}
                    onImageUpload={handleNewImageUpload}
                    onDesignAreaSet={handleDesignAreaSet}
                    customItems={customItems}
                    selectedCustomImages={selectedCustomImages}
                    selectedColour={selectedColour}
                  />
                ))}
              {selectedCustomImages.map((customImg, index) => (
                <div key={customImg._id || index} className="flex flex-col">
                  {customImg.url != "" && (
                    <CustomizableProductForm
                      key={customImg.name}
                      side={customImg.name}
                      onImageUpload={handleNewImageUpload}
                      onDesignAreaSet={handleDesignAreaSet}
                      customItems={customItems}
                      selectedCustomImages={selectedCustomImages}
                      selectedColour={selectedColour}
                    />
                  )}
                  {customImg.url === "" &&
                    customImg.backgroundImg != "" &&
                    sides.map((side) =>
                      customImg.name != side ? (
                        <CustomizableProductForm
                          key={side}
                          side={side}
                          onImageUpload={handleNewImageUpload}
                          onDesignAreaSet={handleDesignAreaSet}
                          customItems={customItems}
                          selectedCustomImages={selectedCustomImages}
                          selectedColour={selectedColour}
                        />
                      ) : (
                        <CustomizableProductForm
                          key={customImg.name}
                          side={customImg.name}
                          onImageUpload={handleNewImageUpload}
                          onDesignAreaSet={handleDesignAreaSet}
                          customItems={customItems}
                          selectedCustomImages={selectedCustomImages}
                          selectedColour={selectedColour}
                        />
                      )
                    )}
                </div>
              ))}
            </div>
          </>
        )}

        <div className="">
          <span className="font-semibold text-gray-600">Item images</span>
          {inventoryItem && (
            <ImageCarouselWithUploader
              images={inventoryItem.imgs}
              handleUploadImages={handleUploadImages}
            />
          )}
        </div>

        <form onSubmit={submit} className="flex flex-col space-y-2">
          {/* <div className="mb-3">
            <label className="font-semibold text-gray-600">Item images</label>
            <div className="flex justify-around items-center m-2">
              {img && newImg ? (
                <img src={img} className="h-60" alt="New uploaded" />
              ) : (
                <img
                  src={PUBLIC_URL + inventoryItem?.img}
                  className="h-60"
                  alt="Current"
                />
              )}
            </div>
          </div> */}
          <input
            id="name"
            placeholder="Name"
            value={state.name}
            onChange={handleChange}
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
          />
          <input
            id="category"
            placeholder="Category"
            value={state.category}
            onChange={handleChange}
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
          />
          <div className="flex flex-row gap-2 items-center mb-2">
            <span className="font-bold px-4">Price: </span>
            <input
              id="price"
              placeholder="0"
              type="number"
              step="any"
              value={state.price}
              onChange={handleChange}
              className="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              style={{
                width: `${state.price ? state.price.length + 7 : 7}ch`,
              }}
              required
            />
            <p>
              <span>
                {utils.normalizeCurrency("GBP")} {displayPrice}
              </span>
            </p>
          </div>
          {!customItems && (
            <input
              id="totQuantity"
              placeholder="Total Quantity"
              value={state.totQuantity}
              type="number"
              onChange={handleChange}
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
            />
          )}
          <textarea
            id="description"
            placeholder="Description"
            value={state.description}
            onChange={handleChange}
            className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-20 px-4"
          />
          <div className="flex justify-between">
            <button
              type="button"
              className="bg-[#8e2421] hover:bg-[#8b4341] text-white font-bold py-2 px-4 rounded-full"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#8e2421] hover:bg-[#8b4341] text-white font-bold py-2 px-4 rounded-full"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

// {sides.map((side) => (
//   <>
//     {selectedCustomImages.length === 0 && (
//       <CustomizableProductForm
//         key={side}
//         side={side}
//         onImageUpload={handleNewImageUpload}
//         onDesignAreaSet={handleDesignAreaSet}
//         customItems={customItems}
//         selectedCustomImages={selectedCustomImages}
//         selectedColour={selectedColour}
//       />
//     )}

//     {selectedCustomImages.length != 0 &&
//       selectedCustomImages.map((customImg, index) => (
//         <div
//           key={customImg._id || index}
//           className="flex flex-col"
//         >
//           {customImg.url != "" && (
//             <div className="flex flex-row items-center">
//               <h2>Upload image for {customImg.name}</h2>

//               <UpdateImageUploader
//                 onFileChange={(file) =>
//                   handleImageUpload(file, customImg._id)
//                 }
//                 image={
//                   customImg.url === ""
//                     ? customImg.backgroundImg
//                     : customImg.url
//                 }
//               />
//               <button
//                 className="bg-[#8e2421] hover:bg-[#8b4341] text-white font-bold py-2 px-4 rounded-full"
//                 onClick={() =>
//                   handleSetDesignArea(customImg.name)
//                 }
//               >
//                 Edit Design Area
//               </button>
//             </div>
//           )}
//           {customImg.url === "" && (
//             <>
//               <CustomizableProductForm
//                 side={customImg.name}
//                 onImageUpload={handleNewImageUpload}
//                 onDesignAreaSet={handleDesignAreaSet}
//                 customItems={customItems}
//                 selectedCustomImages={selectedCustomImages}
//                 selectedColour={selectedColour}
//               />

//               {/*
//         sides.map((side) => (

//             customImg.name != side && (
//        <CustomizableProductForm
//          key={side}
//          side={side}
//          onImageUpload={handleNewImageUpload}
//          onDesignAreaSet={handleDesignAreaSet}
//          customItems={customItems}
//          selectedCustomImages={selectedCustomImages}
//          selectedColour={selectedColour}
//        />) */}
//             </>
//           )}

//           {activeModal === customImg.name && (
//             <EditDesignArea
//               handleSetDesignArea={handleSetDesignArea}
//               handleAddCustomItem={handleAddCustomItem}
//               img={customImg?.url}
//               designAreaSet={activeModal === customImg.name}
//               side={customImg.name}
//               designArea={customImg?.style[customImg.name]}
//             />
//           )}
//         </div>
//       ))}
//   </>
// ))}

// {selectedCustomImages.length === 0 && (
//   <>
//     <CustomizableProductForm
//       side="front"
//       onImageUpload={handleNewImageUpload}
//       onDesignAreaSet={handleDesignAreaSet}
//       customItems={customItems}
//       selectedCustomImages={selectedCustomImages}
//       selectedColour={selectedColour}
//     />
//     <CustomizableProductForm
//       side="back"
//       onImageUpload={handleNewImageUpload}
//       onDesignAreaSet={handleDesignAreaSet}
//       customItems={customItems}
//       selectedCustomImages={selectedCustomImages}
//       selectedColour={selectedColour}
//     />
//     <CustomizableProductForm
//       side="right"
//       onImageUpload={handleNewImageUpload}
//       onDesignAreaSet={handleDesignAreaSet}
//       customItems={customItems}
//       selectedCustomImages={selectedCustomImages}
//       selectedColour={selectedColour}
//     />
//     <CustomizableProductForm
//       side="left"
//       onImageUpload={handleNewImageUpload}
//       onDesignAreaSet={handleDesignAreaSet}
//       customItems={customItems}
//       selectedCustomImages={selectedCustomImages}
//       selectedColour={selectedColour}
//     />
//   </>
// )}
// <div className="flex flex-col gap-4 flex-wrap">
//   {selectedCustomImages.map((customImg, index) => (
//     <div
//       key={customImg._id || index}
//       className="flex flex-row items-center"
//     >
//       <h2>Image for {customImg.name}</h2>
//       {/* <ImageUploader
//       onFileChange={(file) =>
//         handleImageUpload(file, customImg._id)
//       }
//       image={customImg.url}
//     /> */}
//       {/* <img className="w-32" src={PUBLIC_URL + customImg.url} /> */}
//       <UpdateImageUploader
//         onFileChange={(file) =>
//           handleImageUpload(file, customImg._id)
//         }
//         image={
//           customImg.url === ""
//             ? customImg.backgroundImg
//             : customImg.url
//         }
//       />
//       <button
//         className="bg-[#8e2421] hover:bg-[#8b4341] text-white font-bold py-2 px-4 rounded-full"
//         onClick={() => handleSetDesignArea(customImg.name)}
//       >
//         Edit Design Area
//       </button>
//       {activeModal === customImg.name && (
//         <EditDesignArea
//           handleSetDesignArea={handleSetDesignArea}
//           handleAddCustomItem={handleAddCustomItem}
//           img={customImg?.url}
//           designAreaSet={activeModal === customImg.name}
//           side={customImg.name}
//           designArea={customImg?.style[customImg.name]}
//         />
//       )}
//     </div>
//   ))}
// </div>

// {activeModal === customImg.name && (
//   <EditDesignArea
//     handleSetDesignArea={handleSetDesignArea}
//     handleAddCustomItem={handleAddCustomItem}
//     img={customImg?.url}
//     designAreaSet={activeModal === customImg.name}
//     side={customImg.name}
//     designArea={customImg?.style[customImg.name]}
//   />
// )}
