import React, { createContext, useContext, useState } from "react";
import { useProduct } from "./ProductsContext";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { products } = useProduct();
  const [cart, setCart] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const addToCart = (productId, data) => {
    console.log(data);
    const existingProduct = cart.find((item) => item.id === productId);
    console.log(existingProduct);
    let updatedCart;
    if (existingProduct) {
      updatedCart = cart.map((item) => {
        if (item.id === productId) {
          if (data) {
            return {
              ...item,
              quantity: item.quantity + 1,
              data: data,
              price: item.price,
            }; // Added price
          } else {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
        }
        return item;
      });
      setCart(updatedCart);
    } else {
      const product = products.find((p) => p.id === productId);
      console.log(product);
      if (product) {
        if (product.customOrder) {
          updatedCart = [
            ...cart,
            { ...product, quantity: 1, price: data.totalAmount }, // Used data.totalAmount as price
          ];
        } else {
          updatedCart = [
            ...cart,
            { ...product, quantity: 1, price: product.price },
          ]; // Added price
        }
        console.log(updatedCart);
        setCart(updatedCart);
      }
    }
    calculateTotalAmount(updatedCart);
  };

  const calculateTotalAmount = (cart) => {
    console.log(cart);
    let amount = 0;
    for (let i = 0; i < cart.length; i++) {
      console.log(cart[i]);
      amount += cart[i].price * cart[i].quantity;
    }
    console.log(amount);
    setTotalAmount(amount);
  };

  const addMultipleToCart = (productId, quantity, data) => {
    console.log(data);
    const existingProduct = cart.find((item) => item.id === productId);
    console.log(existingProduct);
    let updatedCart;
    if (existingProduct) {
      updatedCart = cart.map((item) => {
        if (item.id === productId) {
          if (data) {
            return {
              ...item,
              quantity: item.quantity + quantity,
              data: data,
              price: item.price,
            }; // Added price
          } else {
            return {
              ...item,
              quantity: item.quantity + quantity,
            };
          }
        }
        return item;
      });
      setCart(updatedCart);
    } else {
      const product = products.find((p) => p.id === productId);
      if (product) {
        if (data) {
          updatedCart = [
            ...cart,
            {
              ...product,
              quantity: quantity,
              data: data,
              price: product.price,
            }, // Added price
          ];
        } else {
          updatedCart = [
            ...cart,
            { ...product, quantity: quantity, price: product.price },
          ]; // Added price
        }
        setCart(updatedCart);
      }
    }
    calculateTotalAmount(updatedCart);
  };

  const removeOneFromCart = (productId) => {
    const updatedCart = cart
      .map((item) => {
        if (item.id === productId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    setCart(updatedCart);
    calculateTotalAmount(updatedCart);
  };

  const removeAllFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    setCart(updatedCart);
    calculateTotalAmount(updatedCart);
  };

  const emptyCart = () => {
    setCart([]);
  };

  function calculateTotalQuantity() {
    let totalQuantity = 0;

    // Iterate through each item in the cart
    for (let i = 0; i < cart.length; i++) {
      // Add the quantity of each item to the total
      totalQuantity += Number(cart[i].quantity);
    }

    setTotalQuantity(totalQuantity);
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCart,
        removeOneFromCart,
        removeAllFromCart,
        emptyCart,
        addMultipleToCart,
        totalQuantity,
        totalAmount,
        calculateTotalAmount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
