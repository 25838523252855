import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import { signIn, signUp } from "./apiCalls/userCalls";
import { useUser } from "./contexts/UserContext";
import { useProduct } from "./contexts/ProductsContext";
import { usePopUp } from "./contexts/PopUpContext";
import PopUp from "./Components/PopUp/PopUp";

function App() {
  const { updateUser, user, connectMetamask } = useUser();
  const { products } = useProduct();
  const { openCookieSettings } = usePopUp();

  useEffect(() => {
    /**
     * TODO:
     *
     * pop up message with error
     */
    // signUp("foo@email.com", "1234", "1234");
    // signIn("admin@email.com", "1234"); // sign in admin
    // signIn("businessO@email.com", "1234"); // sign in foo/client
    signIn("owner@gmail.com", "1234"); // sign in owner
    setTimeout(() => updateUser(), 1000);
    // updateUser();
    // Prod only
    const userAcceptedNecessaryCookies = localStorage.getItem(
      "userAcceptedNecessaryCookies"
    );

    if (!userAcceptedNecessaryCookies) {
      // Show the CookiePopUp if the user hasn't accepted cookies
      openCookieSettings(
        "We use only necessary cookies to ensure the best user experience and enable essential features of our website.",
        100000
      );
    }

    // Dev only
    // if (user?.demo || !user) {
    //   openCookieSettings(
    //     "We use only necessary cookies to ensure the best user experience and enable essential features of our website.",
    //     100000
    //   );
    // }
  }, []);

  return (
    <div>
      {/* <img src={backgroundImg} className="absolute h-full -z-40" /> */}
      <PopUp />
      <Header />
      <div className="bg-[#444] min-h-screen">
        <div className="flex items-center justify-center ml-20 mt-16">
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
