import React from "react";
import moment from "moment";

export default function AddFutureCost({
  handleChange,
  handleDateSelect,
  handleNext,
  date,
}) {
  return (
    <div>
      <h1>Add future cost</h1>
      <form onSubmit={handleNext}>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="future_name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="future_amount"
            type="number"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row-date">
          <h1>Date*</h1>
          <input
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={handleDateSelect}
          />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
}
