import React, { useEffect, useState } from "react";
import { BsPersonCircle, BsJustify } from "react-icons/bs";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import {
  AiOutlineShoppingCart,
  AiOutlineClose,
  AiOutlineMenu,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import Logo from "./Logo";
import { useUser } from "../../contexts/UserContext";
import { useCart } from "../../contexts/CartContext";

export default function Header() {
  const { user, signOut, connectMetamask } = useUser();
  const { totalQuantity, cart, totalAmount, calculateTotalAmount } = useCart();
  const navigate = useNavigate();
  const [menuDrawer, setMenuDrawer] = useState(false);

  const handleMenuDrawer = () => {
    console.log("menu drawer");
    setMenuDrawer(!menuDrawer);
  };

  function formatString(str) {
    if (str.length <= 6) {
      return str;
    }

    const firstThree = str.slice(0, 3);
    const lastThree = str.slice(-3);

    return `${firstThree}...${lastThree}`;
  }

  return (
    <div className="">
      <MenuDrawer isOpen={menuDrawer} handleMenuDrawer={handleMenuDrawer} />
      <div className="fixed top-0 w-full bg-[#e6e4e4] p-8 z-1">
        <div className="fixed right-2 top-0 flex flex-row gap-2 p-4">
          {!user && (
            <>
              <button
                className="bg-[#8e2421] text-white p-1.5 rounded-lg font-extrabold"
                onClick={() => navigate("/sign-in")}
              >
                Login
              </button>
              <button
                className="bg-[#8e2421] text-white p-1.5 rounded-lg font-extrabold"
                onClick={() => navigate("/sign-up")}
              >
                Sign up
              </button>
            </>
          )}
          {user && (
            <>
              <span className="p-1.5 font-extrabold">{user.username}</span>
              <button className="bg-[#8e2421] text-white p-1.5 rounded-lg font-extrabold">
                Metamask
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="md:static fixed inset-x-0 top-0 bg-[#ffffff] w-full flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-[#141514] font-bold text-xl z-50">
      <div className="">
        <MenuDrawer isOpen={menuDrawer} handleMenuDrawer={handleMenuDrawer} />
        {menuDrawer ? null : (
          <button className="menu-icon-btn" onClick={handleMenuDrawer}>
            <AiOutlineMenuUnfold className="menu-icon" />
          </button>
        )}
      </div>
      <h1 className="w-full text-3xl font-bold text-[#141514]">
        <Logo />
      </h1>
      <ul className="hidden md:flex items-center">
        <li className="p-4">
          <Link to="/">Home</Link>
        </li>
        <li className="p-4">
          <Link to="/shop">Shop</Link>
        </li>
        <li className="p-4">
          <Link to="/about">About</Link>
        </li>
        <li className="p-4">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="p-4 whitespace-nowrap">
          {user ? (
            <>
              <span>{user.username}</span>
              <a
                onClick={() => {
                  signOut();
                  navigate("/");
                }}
                className="ml-5"
              >
                Sign out
              </a>
            </>
          ) : (
            <Link to={"/sign-in"}>Sign in</Link>
          )}
        </li>
        <li className="p-4 whitespace-nowrap cart-icon">
          <Link to={"/cart"}>
            <CartIcon totalQuantity={totalQuantity} />
            {totalAmount != 0 && "£ " + totalAmount.toFixed(2)}
          </Link>
        </li>
      </ul>
      {/* Mobile menu */}
      <button onClick={handleMenuDrawer} className="block md:hidden">
        {menuDrawer ? (
          <AiOutlineClose size={20} />
        ) : (
          <AiOutlineMenu size={20} />
        )}
      </button>
      {/* <ul
        className={
          menuDrawer
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "ease-in-out duration-500 fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#eaeeea] m-4">
          <Logo />
        </h1>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          <Link to="/">Home</Link>
        </li>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          <Link to="/shop">Shop</Link>
        </li>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          <Link to="/">About</Link>
        </li>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          <Link to="/">Contact</Link>
        </li>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          {user ? (
            <>
              <span id="my-anchor" className="anchor">
                {user.username}
              </span>
              <div className="tooltip">
                <button onClick={signOut}>Sign out</button>
              </div>
            </>
          ) : (
            <Link to={"/sign-in"}>Sign in</Link>
          )}
        </li>
        <li className="p-4 border-b border-gray-600 text-[#eaeeea]">
          <Link to={"/cart"} className="flex items-center gap-1">
            {totalAmount != 0 && "£ " + totalAmount.toFixed(2)}
            <CartIcon totalQuantity={totalQuantity} />
          </Link>
        </li>
        <li className="p-4 text-[#eaeeea]">Contact</li>
      </ul> */}
    </div>
  );

  return (
    <div className="flex flex-row">
      <div className="logo-container">
        <Logo />
      </div>

      <div className="account-icon-container">
        {user && !user.demo ? (
          <span>
            {user.admin ? "admin" : user.guest ? "guest" : user.username}
          </span>
        ) : (
          // user?.account ? (
          //   <button className="user-account">
          //     {formatString(user.account)}
          //   </button>
          // ) : (
          //   <button className="account-icon-btn" onClick={connectMetamask}>
          //     <img
          //       className="metamask-icon"
          //       src={require("../../assets/metamask-logo-48.png")}
          //     />
          //   </button>
          // )
          <Link to="/sign-in" className="account-icon-btn">
            <BsPersonCircle className="account-icon" />
          </Link>
        )}
      </div>
    </div>
  );
}

const CartIcon = ({ totalQuantity }) => {
  return (
    <div className="cart-icon">
      <AiOutlineShoppingCart className="info-icon" />
      {totalQuantity > 0 && <div className="icon-badge">{totalQuantity}</div>}
    </div>
  );
};
