import React, { useEffect, useState } from "react";

const SizeSelector = ({
  handleSizeSelected,
  selectedColour,
  customItems,
  setCustomItems,
  sizes,
}) => {
  const [customSize, setCustomSize] = useState("");
  const [commonSizes, setCommonSizes] = useState([
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL",
  ]);
  const [selectedSize, setSelectedSize] = useState(
    sizes.length > 0 ? sizes[0].size : ""
  ); // Initialize with the first size
  const [selectedCommonSize, setSelectedCommonSize] = useState(commonSizes[0]);
  const [quantityMap, setQuantityMap] = useState({});
  const [totQuantity, setTotQuantity] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    // Calculate total quantity whenever quantityMap changes
    const total = Object.values(quantityMap).reduce((sum, qty) => sum + qty, 0);
    setTotQuantity(total);
  }, [quantityMap]);

  const handleSelectChange = (event) => {
    setSelectedSize(event.target.value);
    handleSizeSelected(event.target.value);
  };

  const handleSelectCommonChange = (event) => {
    setSelectedCommonSize(event.target.value);
  };

  const handleCustomSizeChange = (event) => {
    setCustomSize(event.target.value);
  };

  const addCustomSize = (e) => {
    e.preventDefault();
    if (customSize && !sizes.some((sizeObj) => sizeObj.size === customSize)) {
      const updatedSizes = [...sizes, { size: customSize, quantity: quantity }];
      updateColoursWithNewSizes(updatedSizes);
      setCustomSize("");
      setSelectedSize(customSize);
      handleSizeSelected(customSize);
    }
  };

  const addCommonSize = (e) => {
    e.preventDefault();
    console.log(quantity);

    if (
      selectedCommonSize &&
      commonSizes.includes(selectedCommonSize) &&
      !sizes.some((sizeObj) => sizeObj.size === selectedCommonSize)
    ) {
      const updatedSizes = [
        ...sizes,
        { size: selectedCommonSize, quantity: quantity },
      ];

      const filteredSizes = updatedSizes.filter(
        (sizeObj) => sizeObj.size !== "" && sizeObj.size !== "None"
      );

      // Update the sizes with the newly added size
      updateColoursWithNewSizes(filteredSizes);
      handleSizeSelected(selectedCommonSize);
      setSelectedSize(selectedCommonSize);

      // Remove the added size from the commonSizes array
      const updatedCommonSizes = commonSizes.filter(
        (size) => size !== selectedCommonSize
      );
      setCommonSizes(updatedCommonSizes);

      // Reset selectedCommonSize if the commonSizes array is empty or all sizes have been added
      if (updatedCommonSizes.length > 0) {
        setSelectedCommonSize(updatedCommonSizes[0]);
      } else {
        setSelectedCommonSize(""); // Or handle as appropriate for your use case
      }
    }
  };

  const removeSelectedSize = () => {
    if (
      selectedSize &&
      sizes.some((sizeObj) => sizeObj.size === selectedSize)
    ) {
      // Filter out the selected size from the sizes array
      let updatedSizes = sizes.filter(
        (sizeObj) => sizeObj.size !== selectedSize
      );

      // Set the new selected size to the first available size in updatedSizes or to an empty string
      const newSelectedSize =
        updatedSizes.length > 0 ? updatedSizes[0].size : "";

      // If no sizes are left, only then add "None"
      if (updatedSizes.length === 0) {
        updatedSizes = [{ size: "None" }];
      }

      // Update the sizes array and selected size in the state
      updateColoursWithNewSizes(updatedSizes);
      setSelectedSize(newSelectedSize);
      handleSizeSelected(newSelectedSize);

      // Optionally, add the removed size back to the commonSizes array
      if (selectedSize !== "" && selectedSize !== "None") {
        setCommonSizes([...commonSizes, selectedSize]);
      }
    }
  };

  const updateColoursWithNewSizes = (newSizes) => {
    const updatedItems = customItems.map((customItem) => {
      if (customItem.colour === selectedColour) {
        return { ...customItem, sizes: newSizes };
      }
      return customItem;
    });
    setCustomItems(updatedItems);
  };

  const handleQuantityChange = (event) => {
    const { value } = event.target;
    const newQuantity = parseInt(value) || 0;
    const key = `${selectedColour}-${selectedSize}`;
    setQuantity(newQuantity);

    setQuantityMap((prevMap) => ({
      ...prevMap,
      [key]: newQuantity,
    }));
  };

  return (
    <div className="p-6">
      <div className="flex flex-col mb-4">
        <label htmlFor="sizeSelect" className="font-medium text-gray-700 mb-2">
          Select Size
        </label>
        <div className="flex flex-row flex-wrap items-center gap-4">
          <select
            id="sizeSelect"
            value={selectedSize}
            onChange={handleSelectChange}
            required
            className="p-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            {sizes.map((size, index) => (
              <option key={index} value={size.size}>
                {size.size}
              </option>
            ))}
          </select>
          <button
            type="button"
            onClick={removeSelectedSize}
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-200"
          >
            Remove Selected Size
          </button>
        </div>
      </div>

      <form onSubmit={addCustomSize}>
        <div className="flex flex-row flex-wrap gap-2 items-center">
          <input
            type="text"
            placeholder="Custom size"
            value={customSize}
            onChange={handleCustomSizeChange}
            className="p-2 w-32 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
          <div className="flex flex-row flex-wrap items-center gap-2">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              id="quantity"
              value={quantityMap[`${selectedColour}-${selectedSize}`] || ""}
              onChange={handleQuantityChange}
              className="appearance-none w-20 block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              required
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-200"
          >
            Add Custom Size
          </button>
        </div>
      </form>

      <div className="flex flex-col mt-4">
        <label
          htmlFor="commonSizeSelect"
          className="font-medium text-gray-700 mb-2"
        >
          Select from Common Sizes
        </label>
        <form onSubmit={addCommonSize}>
          <div className="flex flex-row items-center gap-2">
            <select
              id="commonSizeSelect"
              value={selectedCommonSize}
              onChange={handleSelectCommonChange}
              className="p-2 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              {commonSizes.map((size, index) => (
                <option key={index} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <div className="flex flex-row flex-wrap items-center gap-2">
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                id="quantity"
                value={quantityMap[`${selectedColour}-${selectedSize}`] || ""}
                onChange={handleQuantityChange}
                className="appearance-none w-20 block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                required
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
            >
              Add Common Size
            </button>
          </div>
        </form>

        <p>Total Quantity: {totQuantity}</p>
      </div>
    </div>
  );
};

export default SizeSelector;
