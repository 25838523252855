import React, { useState, useEffect } from "react";

const TypingSimulator = ({ strings, typeSpeed, backSpeed }) => {
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const typeNextWord = () => {
      const currentIndex = currentStringIndex % strings.length;
      const fullWord = strings[currentIndex];

      if (!isDeleting) {
        setCurrentWord(fullWord.substring(0, currentWord.length + 1));
      } else {
        setCurrentWord(fullWord.substring(0, currentWord.length - 1));
      }

      let speed = typeSpeed;
      if (isDeleting) {
        speed = backSpeed;
      }

      const fullTextTyped = !isDeleting && currentWord === fullWord;

      setTimeout(
        () => {
          if (fullTextTyped && !isDeleting) {
            setIsDeleting(true);
          } else if (currentWord === "" && isDeleting) {
            setIsDeleting(false);
            setCurrentStringIndex(currentStringIndex + 1);
          }
        },
        fullTextTyped ? 1000 : speed
      );
    };

    const typingInterval = setInterval(typeNextWord, typeSpeed);

    return () => clearInterval(typingInterval);
  }, [
    currentWord,
    currentStringIndex,
    isDeleting,
    strings,
    typeSpeed,
    backSpeed,
  ]);

  return (
    <span className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-transparent bg-clip-text bg-gradient-to-r to-[#8e2421] from-[#c57573]">
      {currentWord}
    </span>
  );
};

export default TypingSimulator;
