import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { addFutureCost } from "../../../../apiCalls/accountingCalls";
import { useUser } from "../../../../contexts/UserContext";

import moment from "moment";

export default function AddFutureCostDialog(props) {
  const { updateUser } = useUser();
  const [state, setState] = useState();
  const [date, setDate] = useState(new Date());
  const [bankAccount, setBankAccount] = useState();

  const handleDateSelect = (e) => {
    setDate(e.target.value);
  };

  const handleBankAccountSelect = (e) => {
    for (let i = 0; i < props.budget.bank_accounts.length; i++) {
      if (props.budget.bank_accounts[i]._id === e.target.value) {
        setBankAccount(props.budget.bank_accounts[i]);
      }
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <h1>Add future cost</h1>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="amount"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row-date">
          <h1>Date*</h1>
          <input
            type="date"
            value={moment(date).format("YYYY-MM-DD")}
            onChange={handleDateSelect}
          />
        </div>
        <div className="input-row">
          <h1>to*</h1>
          <input
            id="to"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>From*</h1>
          <select name="from" id="from" onChange={handleBankAccountSelect}>
            {props.budget?.bank_accounts?.map((bank) => (
              <option value={bank._id}>{bank.name}</option>
            ))}
          </select>
        </div>
        <div className="input-row">
          <h1>notes*</h1>
          <input
            id="notes"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <span className="save-btn">
          <button
            onClick={async () => {
              let futureCost = {
                name: state.name,
                amount: state.amount,
                currency: props.budget?.currency,
                date: date,
                to: state.to,
                from: bankAccount
                  ? bankAccount
                  : props.budget?.bank_accounts[0],
                notes: state.notes,
              };
              await addFutureCost(props.budget._id, futureCost);
              updateUser();
              props.onClose();
            }}
          >
            save
          </button>
        </span>
      </Dialog>
    </div>
  );
}
