import React from "react";

import { utils } from "../../../js/utils";

export default function BudgetName({ handleChange, handleNext, state }) {
  return (
    <>
      <form onSubmit={handleNext}>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="budget_name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>currency*</h1>
          <select name="currency" id="currency" onChange={handleChange}>
            {state?.currency ? (
              utils.currencies.map((currency) => (
                <option value={currency}>{currency}</option>
              ))
            ) : (
              <option value="GBP">GBP</option>
            )}
          </select>
        </div>
        <button type="submit">Next</button>
      </form>
    </>
  );
}
