import React, { useEffect, useRef, useState } from "react";

const { REACT_APP_SOCKET_URL } = process.env;

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : REACT_APP_SOCKET_URL;

const DesignAreaSetter = ({
  handleSetDesignArea,
  img,
  designAreaSet,
  side,
  handleAddCustomItem,
  selectedColour,
}) => {
  const [state, setState] = useState({
    front: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    back: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    right: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
    left: {
      backgroundImg: "",
      saved: false,
      designArea: null,
      percCoords: null,
    },
  });
  const [src, setSrc] = useState("");
  const [designArea, setDesignArea] = useState("");

  const handleClick = () => {
    console.log(state[side]);
    console.log(state[side].designArea);
    handleSetDesignArea(side);
  };

  const handleSaved = (bool, coords, percCoords) => {
    setState((prevState) => ({
      ...prevState,
      [side]: {
        backgroundImg: img,
        saved: bool,
        designArea: coords,
        percCoords,
      },
    }));
    const customItem = {
      side,
      backgroundImg: img,
      selectedColour,
      saved: bool,
      designArea: coords,
      percCoords,
    };
    handleSetDesignArea(side);
    handleAddCustomItem(customItem);
  };

  useEffect(() => {
    // console.log(state);
    // console.log(img);
    if (img) {
      let imgURL = img.backgroundImg
        ? img.backgroundImg
        : img.url
        ? img.url
        : img;
      console.log(
        img.backgroundImg ? "img.backgroundImg" : "img.url" ? "img.url" : "img"
      );
      if (img.backgroundImg && img.backgroundImg.url) {
        imgURL = img.backgroundImg.url;
      }
      console.log(imgURL);
      console.log(imgURL.url);
      console.log(img.designArea);
      console.log(img.designArea ? img.designArea[side] : "undefined");

      setDesignArea(img.designArea);
      // ? img.designArea
      // : {
      //     front: { top: "", left: "", width: "", height: "" },
      //     back: { top: "", left: "", width: "", height: "" },
      //     right: { top: "", left: "", width: "", height: "" },
      //     left: { top: "", left: "", width: "", height: "" },
      //   }
      // console.log(typeof imgURL !== "string");

      if (imgURL.url === "") {
        console.log("Set url to empty");
        setSrc("");
      } else if (typeof imgURL !== "string" && imgURL instanceof File) {
        setSrc(URL.createObjectURL(imgURL));
      } else {
        setSrc(PUBLIC_URL + imgURL);
      }
    }
  }, [side, img, selectedColour, designAreaSet]);

  const imagePreview = img && (
    <div className="flex justify-around items-center m-2 h-full w-full">
      <DrawRect
        handleSaved={handleSaved}
        designArea={
          designArea && designArea[side] ? designArea[side] : designArea
        }
      >
        <img src={src} alt="Preview" className="h-full w-full object-cover" />
      </DrawRect>
    </div>
  );

  useEffect(() => {
    if (typeof img === "string") {
      setState((prevState) => ({
        ...prevState,
        [side]: {
          backgroundImg: img,
          saved: false,
          designArea: designArea,
        },
      }));
    }
  }, []);

  if (!img) {
    return (
      <button
        type="button"
        className="active:cursor-grabbing cursor-not-allowed disabled font-extrabold text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
      >
        Set Design Area
      </button>
    );
  } else {
    return (
      <div>
        {!designAreaSet && (
          <button
            type="button"
            onClick={handleClick}
            className={`${
              img ? "" : "cursor-not-allowed disabled"
            } font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg`}
          >
            Set Design Area
          </button>
        )}
        {img && designAreaSet && (
          <CustomModal
            open={designAreaSet}
            handleClose={handleClick}
            imagePreview={imagePreview}
          />
        )}
      </div>
    );
  }
};

export default DesignAreaSetter;

function CustomModal({ open, handleClose, imagePreview }) {
  return (
    <>
      {open && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 py-5 sm:px-6">
                <div className="flex flex-col flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 p-4 dark:border-white/10">
                  <h5
                    className="text-xl font-medium leading-normal text-surface dark:text-white"
                    id="exampleModalCenteredScrollableLabel"
                  >
                    Set Design Area
                  </h5>
                  <div className="h-full w-full">{imagePreview}</div>
                  <button
                    type="button"
                    onClick={handleClose}
                    className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                  >
                    Close Design Area
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const DrawRect = ({ children, handleSaved, designArea }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawingActive, setDrawingActive] = useState(false);
  const [rectCoords, setRectCoords] = useState(
    designArea || {
      startX: 0,
      startY: 0,
      endX: 0,
      endY: 0,
    }
  );
  const [percCoords, setPercCoords] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  let ctx;

  const setCanvasSize = (canvas) => {
    if (canvas) {
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
    }
  };

  const drawRectangle = (rect, style) => {
    // console.log(ctx);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    ctx.strokeStyle = style.borderColor;
    ctx.lineWidth = style.borderWidth;
    ctx.strokeRect(
      rect.startX,
      rect.startY,
      rect.endX - rect.startX,
      rect.endY - rect.startY
    );
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      ctx = canvas.getContext("2d");
      setCanvasSize(canvas);
      const parentWidth = canvas.clientWidth;
      const parentHeight = canvas.clientHeight;
      console.log(parentWidth, parentHeight);
      console.log(rectCoords);
      const top = (rectCoords.startY / parentHeight) * 100,
        left = (rectCoords.startX / parentWidth) * 100,
        width = ((rectCoords.endX - rectCoords.startX) / parentWidth) * 100,
        height = ((rectCoords.endY - rectCoords.startY) / parentHeight) * 100;
      console.log(top, left, width, height);
      setPercCoords({ top, left, width, height });
      const style = { borderColor: "#ff0000", borderWidth: 2 };
      drawRectangle(rectCoords, style);
      // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      // ctx.beginPath();
      // ctx.strokeStyle = style.borderColor;
      // ctx.lineWidth = style.borderWidth;
      // ctx.strokeRect(top, left, width, height);
    }
  }, [rectCoords]);

  useEffect(() => {
    const canvas = canvasRef.current;
    // console.log(canvas);
    ctx = canvas.getContext("2d");
    // const style = { borderColor: "#ff0000", borderWidth: 2 };
    // const rInfo = { startX: 150, startY: 240, endX: 235, endY: 320 };
    // drawRectangle(rInfo, style);
  }, []);

  useEffect(() => {
    console.log(designArea);
    if (designArea && !drawingActive) {
      const canvas = canvasRef.current;
      console.log(canvas);
      const parentWidth = canvas.clientWidth;
      const parentHeight = canvas.clientHeight;
      console.log(parentWidth, parentHeight);
      ctx = canvas.getContext("2d");
      const style = { borderColor: "#ff0000", borderWidth: 2 };
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.beginPath();
      ctx.strokeStyle = style.borderColor;
      ctx.lineWidth = style.borderWidth;
      console.log((designArea.top * parentHeight) / 100);
      console.log((designArea.left * parentWidth) / 100);
      console.log((designArea.width * parentWidth) / 100);
      console.log((designArea.height * parentHeight) / 100);
      ctx.strokeRect(
        (designArea.top * parentHeight) / 100,
        (designArea.left * parentWidth) / 100,
        (designArea.width * parentWidth) / 100,
        (designArea.height * parentHeight) / 100
      );
    }
  }, [designArea]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setRectCoords({
        startX: offsetX,
        startY: offsetY,
        endX: offsetX,
        endY: offsetY,
      });
      setIsDrawing(true);
      setDrawingActive(true);
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setRectCoords((prevCoords) => ({
        ...prevCoords,
        endX: offsetX,
        endY: offsetY,
      }));
    }
  };

  return (
    <>
      <div className="absolute top-5 flex flex-row gap-2">
        <button
          type="button"
          className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          onClick={() => {
            setRectCoords({
              startX: 0,
              startY: 0,
              endX: 0,
              endY: 0,
            });
            setDrawingActive(true);
          }}
        >
          Clear area
        </button>
        <button
          type="button"
          className="font-extrabold active:cursor-grabbing cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          onClick={() => {
            handleSaved(true, rectCoords, percCoords);
            setDrawingActive(false);
          }}
        >
          Save area
        </button>
      </div>
      <div className="relative min-h-full w-full">
        {children}
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          className="absolute top-0 left-0 cursor-crosshair h-full w-full" // border-2 border-[#070707]
        />
        {!drawingActive && designArea && (
          <RectangleCanvas
            rectCoords={rectCoords} //{{ startX: 160, startY: 80.5, endX: 259, endY: 242.5 }}
            color="#070707"
          />
        )}
      </div>
      <div className="absolute flex flex-col bottom-0 left-0 p-2 bg-[#fff]">
        {drawingActive ? (
          <span>Drawing . . .</span>
        ) : (
          <span>Not Drawing . . .</span>
        )}
        <span>Start X: {rectCoords.startX}</span>
        <span>Start Y: {rectCoords.startY}</span>
        <span>End X: {rectCoords.endX}</span>
        <span>End Y: {rectCoords.endY}</span>
      </div>
    </>
  );
};

function RectangleCanvas({ rectCoords, color }) {
  const canvas = useRef();
  const { startX, startY, endX, endY } = rectCoords;
  const width = endX - startX;
  const height = endY - startY;
  let ctx = null;

  // initialize the canvas context
  useEffect(() => {
    const canvasEle = canvas.current;

    // Ensure the parent element's dimensions are properly calculated
    const setCanvasSize = () => {
      // console.log(canvasEle.parentElement);
      // console.log(canvasEle.parentElement.clientWidth);
      // console.log(canvasEle.parentElement.clientHeight);
      const parentWidth = canvasEle.parentElement.clientWidth;
      const parentHeight = canvasEle.parentElement.clientHeight;

      // Set canvas dimensions based on parent dimensions
      canvasEle.width = parentWidth;
      canvasEle.height = parentHeight;

      // Get context of the canvas
      ctx = canvasEle.getContext("2d");

      // Draw the initial rectangle
      const r1Info = { x: startX, y: startY, w: width, h: height };
      const r1Style = { borderColor: color, borderWidth: 2 };
      drawRect(r1Info, r1Style);
    };

    // Set the size immediately and also after a delay
    setCanvasSize();
    setTimeout(setCanvasSize, 10);

    window.addEventListener("resize", setCanvasSize);

    return () => {
      window.removeEventListener("resize", setCanvasSize);
    };
  }, [rectCoords, color]);

  // Draw rectangle
  const drawRect = (info, style = {}) => {
    const { x, y, w, h } = info;
    const { borderColor = "black", borderWidth = 1 } = style;

    ctx.beginPath();
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth;
    ctx.rect(x, y, w, h);
    ctx.stroke();
  };

  return <canvas ref={canvas} className="absolute top-0 left-0" />;
}
