import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import img from "../../../assets/b-w-developer.jpg";
import ImageCarousel from "../../../Components/ImageCarousel/ImageCarousel";
import { utils } from "../../../js/utils";

const { REACT_APP_SOCKET_URL } = process.env;

export default function InventoryItemsPage() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [inventoryItems, setInventoryItems] = useState();

  const fetchInventoryItems = async () => {
    try {
      const res = await axios.get(`/api/v1/erp/${businessId}/inventory`);
      console.log(res.data);
      if (res.data.inventoryItems) {
        setInventoryItems(res.data.inventoryItems);
      }
    } catch (error) {
      // Handle error
      console.error("Error fetching order details:", error);
    }
  };

  const deleteItem = async (item) => {
    try {
      const res = await axios.delete(
        `/api/v1/erp/${businessId}/inventory/${item._id}`
      );
      console.log(res.data);
      fetchInventoryItems();
    } catch (error) {
      // Handle error
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    fetchInventoryItems();
  }, []);

  return (
    <div className=" mb-20">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8">
        <h1 className="text-[#8e2421] font-extrabold text-xl md:text-3xl">
          Inventory Items
        </h1>
        <div className="flex flex-row gap-4 my-4">
          <button
            className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
            onClick={() =>
              navigate(`/business/${businessId}/inventory-items/create-new`)
            }
          >
            Add new one
          </button>
          <button
            onClick={() => navigate(-1)}
            className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
        </div>
        <div className="flex flex-row flex-wrap gap-4 justify-center items-center">
          {inventoryItems &&
            inventoryItems.map((item) => (
              <div className="flex flex-row flex-wrap border p-4 border-[#8e2421] rounded-3xl mb-4">
                <ImageCarousel images={item.imgs} />
                {/* <img
                  className="max-h-60 p-2"
                  src={
                    process.env.NODE_ENV === "development" && item.img
                      ? "http://localhost:5000/" + item.img
                      : REACT_APP_SOCKET_URL + item.img
                  }
                />
                {item.imgs &&
                  item.imgs.map((img) => (
                    <img
                      className="max-h-60 p-2"
                      src={
                        process.env.NODE_ENV === "development" && img
                          ? "http://localhost:5000/" + img
                          : REACT_APP_SOCKET_URL + img
                      }
                    />
                  ))} */}
                <div className="item-details">
                  <div className="buttons-row">
                    <h1 className="font-bold capitalize">{item.name}</h1>
                    <p className="mt-4">
                      <span className="font-bold">Quantity</span>
                      <p>{item.totQuantity}</p>
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Category</span>
                      <p>{item.category}</p>
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Price: </span>
                      <span>
                        {utils.normalizeCurrency(item.currency)}{" "}
                        {(Number(item.price) / 100).toFixed(2)}
                      </span>
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Description</span>
                      <p>{item.description}</p>
                    </p>
                    <div className="mt-4 flex flex-row gap-4">
                      <button
                        onClick={() => deleteItem(item)}
                        className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                      >
                        delete
                      </button>
                      <button
                        onClick={() => navigate(`${item._id}`)}
                        className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                      >
                        edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
