import React from "react";

export default function AddBankAccount({
  state,
  handleChange,
  updateUser,
  handleNext,
}) {
  return (
    <div>
      <h1>Add bank account</h1>
      <form onSubmit={handleNext}>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="bank_name"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="bank_amount"
            type="number"
            onChange={handleChange}
            className="name-input"
            required
          />
        </div>
        <button type="submit">Next</button>
      </form>
    </div>
  );
}
