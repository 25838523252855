import React from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import { signIn } from "../../apiCalls/userCalls";

import { useUser } from "../../contexts/UserContext";
import { usePopUp } from "../../contexts/PopUpContext";
import axios from "axios";

const { NODE_ENV, REACT_APP_API_URL } = process.env;

const API_URL =
  NODE_ENV === "production"
    ? REACT_APP_API_URL
    : "http://localhost:5000/api/v1/";

export default function SignInForm() {
  const { updateUser } = useUser();
  const { openPopUp } = usePopUp();
  // const history = useHistory();
  const navigate = useNavigate();
  const [state, setState] = React.useState();
  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  return (
    <div className="h-screen mb-12">
      <div className="flex flex-col justify-center px-6 py-12 lg:px-8 items-center bg-[#ffffff] rounded-lg shadow-md overflow-hidden p-4 mt-8 mr-4">
        <div className="flex flex-col sm:mx-auto sm:w-full sm:max-w-sm items-center justify-center">
          <img
            className="max-h-20"
            src={require("../../assets/ws-full-logo.png")}
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form
            className="space-y-6"
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                let res = await signIn(state.email, state.password);
                console.log(res);
                if (res.status === 200) {
                  updateUser();
                  openPopUp("You are logged in!", 2000);
                  navigate("/");
                } else {
                  openPopUp("Something went wrong. Please try again", 2000);
                }
              } catch (error) {
                console.log(error);
                openPopUp(error.response.data, 10000);
              }
            }}
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#8e2421] sm:text-sm sm:leading-6"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
                <div className="text-sm">
                  <button
                    type="button"
                    onClick={async () => {
                      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                      function isValidEmail(email) {
                        return emailPattern.test(email);
                      }
                      if (isValidEmail(state.email)) {
                        const res = await axios.post(
                          `${API_URL}reset-password`,
                          {
                            email: state.email,
                          }
                        );
                        console.log(res);
                      } else alert("Please enter valid email");
                    }}
                    className="font-semibold text-[#8e2421] hover:text-[#914644]"
                  >
                    Forgot password?
                  </button>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#99130c] sm:text-sm sm:leading-6"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-[#8e2421] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#914644] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#914644]"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{" "}
            <Link
              to="/sign-up"
              className="font-semibold leading-6 text-[#8e2421] hover:text-[#914644]"
            >
              Sign up for free
            </Link>
          </p>
        </div>
        {/* <div className="mt-8 mb-4">
          <span className="">Or continue with</span>
        </div>
        <div className="flex flex-row">
          <a href="#" className="">
            <svg className="" aria-hidden="true" viewBox="0 0 24 24">
              <path
                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                fill="#EA4335"
              ></path>
              <path
                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                fill="#4285F4"
              ></path>
              <path
                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                fill="#FBBC05"
              ></path>
              <path
                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                fill="#34A853"
              ></path>
            </svg>
            <span className="">Google</span>
          </a>
          <a href="#" className="">
            <svg
              className=""
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="">GitHub</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}
