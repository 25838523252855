import React, { useEffect, useState } from "react";
import { deleteUser } from "../../apiCalls/userCalls";
import { useUser } from "../../contexts/UserContext";
import { deletePaymentMethod } from "../../apiCalls/stripeCalls";
import { usePopUp } from "../../contexts/PopUpContext";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const { user, updateUser, editUser } = useUser();
  const { openConfirmation, confirmed } = usePopUp();
  const [editUserForm, setEditUserForm] = useState(false);
  const [state, setState] = useState(null);
  const [deleteCard, setDeleteCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditUser = () => {
    setEditUserForm(!editUserForm);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const confirmChanges = async () => {
    setIsLoading(true);
    let newUser = user;
    newUser.username =
      state.username != newUser.username && state.username != undefined
        ? state.username
        : newUser.username;
    // newUser.email =
    //   state.email != newUser.email && state.email != undefined
    //     ? state.email
    //     : newUser.email;
    await editUser(newUser);
    handleEditUser();
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <h3 className="text-xl font-semibold text-center mb-4">Profile</h3>
        {editUserForm ? (
          <EditUserForm
            handleChange={handleChange}
            confirmChanges={confirmChanges}
            handleEditUser={handleEditUser}
            isLoading={isLoading}
          />
        ) : (
          user && (
            <div className="flex flex-col gap-4">
              <p>
                <span className="mr-2">Username:</span>
                <span className="font-extrabold">{user?.username}</span>
              </p>
              <p>
                <span className="mr-2">Email:</span>
                <span className="font-extrabold">{user?.email}</span>
              </p>
              <p>
                <span>Cards:</span>
                {user?.payment_methods.length != 0 ? (
                  user?.payment_methods?.map((method) => (
                    <div key={method._id}>
                      <span>Last 4: </span>
                      {method.card.last4}
                      {deleteCard ? (
                        <>
                          <button
                            className="button"
                            onClick={() => {
                              deletePaymentMethod(method.id);
                              updateUser();
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            className="button green"
                            onClick={() => setDeleteCard(false)}
                          >
                            Cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className="button"
                          onClick={() => setDeleteCard(true)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <button className="bg-[#8e2421] text-white py-1 px-2 mx-4 rounded-md font-extrabold">
                    Add payment method
                  </button>
                )}
              </p>
              <p>
                <span className="mr-2">API usage:</span>
                <span className="font-extrabold">{user?.api_usage.count}</span>
              </p>
              <p className="">
                <button
                  onClick={() =>
                    openConfirmation(
                      "Are you sure you want to reset the password for this account?",
                      100000
                    )
                  }
                  className="bg-[#8e2421] text-white py-2 px-2 rounded-md font-extrabold"
                >
                  Reset password
                </button>
              </p>
              <div className="mt-6 flex flex-row justify-center items-center">
                <button
                  onClick={handleEditUser}
                  className="bg-[#8e2421] text-white py-2 px-2 mx-4 rounded-md font-extrabold"
                >
                  edit
                </button>
                <button
                  onClick={() => {
                    openConfirmation(
                      "Are you sure you want to delete the account?",
                      100000
                    );
                  }}
                  className="bg-[#8e2421] text-white py-2 px-2 rounded-md font-extrabold"
                >
                  delete
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

const EditUserForm = ({
  handleChange,
  confirmChanges,
  handleEditUser,
  isLoading,
}) => {
  const { user } = useUser();

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Username
        </label>
        <div className="mt-2">
          <input
            id="username"
            name="username"
            type="text"
            placeholder={user?.username}
            required
            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#8e2421] sm:text-sm sm:leading-6"
            onChange={handleChange}
          />
        </div>
      </div>
      {/* <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email address
        </label>
        <div className="mt-2">
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#8e2421] sm:text-sm sm:leading-6"
            onChange={handleChange}
          />
        </div>
      </div> */}
      <div className="flex flex-row items-center justify-center gap-4 mt-4">
        <button
          onClick={confirmChanges}
          disabled={isLoading}
          className="bg-[#8e2421] text-white py-2 px-2 rounded-md font-extrabold"
        >
          {isLoading ? "Updating..." : "Confirm"}
        </button>
        <button
          onClick={handleEditUser}
          className="bg-[#8e2421] text-white py-2 px-2 rounded-md font-extrabold"
        >
          Back
        </button>
      </div>
    </div>
  );
};
