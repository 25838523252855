import React from "react";

const CreditCard = ({ cardNumber, cardHolder, expMonth, expYear }) => {
  return (
    <div className="card">
      <div className="card-inner">
        <div className="card-front">
          <div className="card-bg"></div>
          <div className="card-glow"></div>
          <div className="card-contactless">
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="56">
              <path
                fill="none"
                stroke="#f9f9f9"
                strokeWidth="6"
                strokeLinecap="round"
                d="m35,3a50,50 0 0,1 0,50M24,8.5a39,39 0 0,1 0,39M13.5,13.55a28.2,28.5
                0 0,1 0,28.5M3,19a18,17 0 0,1 0,18"
              />
            </svg>
          </div>
          <div className="card-chip"></div>
          <div className="card-holder">{cardHolder}</div>
          <div className="card-number">{cardNumber}</div>
          <div className="card-valid">
            {expMonth}/{expYear}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
