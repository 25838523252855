import axios from "axios";

const { NODE_ENV, REACT_APP_API_URL, REACT_APP_BUSINESS_API_KEY } = process.env;

const BUSINESS_API_KEY =
  NODE_ENV === "production"
    ? REACT_APP_BUSINESS_API_KEY
    : "Axq3u8lVEEgjE3piKMs9x6iFenUur0XC";
const API_URL =
  NODE_ENV === "production"
    ? REACT_APP_API_URL
    : "http://localhost:5000/api/v1/";

export const api = {
  getUser: async () => {
    let res = await axios.get("/api/v1/users/user");
    // console.log(res.data)
    return res.data.user;
  },

  createGuestSession: async (email) => {
    try {
      let res = await axios.post(
        `${API_URL}auth/create-guest-session`,
        {
          email,
        },
        { withCredentials: true, credentials: "include" }
      );
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  signIn: async (email, password) => {
    let res = await axios.post(
      "/api/v1/auth/sign-in",
      { email, password },
      { withCredentials: true, credentials: "include" }
    );
    console.log(res);
    return res;
  },

  signUp: async (email, password, password2) => {
    let res = await axios.post("/api/v1/auth/sign-up", {
      email,
      password,
      password2,
    });
    //   console.log(res.data);
    return res;
  },

  signOut: async () => {
    let res = await axios.get("/api/v1/auth/sign-out");
    // console.log(res.data)
    return res.data;
  },

  editUser: async (user) => {
    let res = await axios.put(`/api/v1/users/`, user);
    // console.log(res.data)
    return res.data;
  },

  deleteUser: async () => {
    let res = await axios.get("/api/v1/users/remove");
    // console.log(res.data)
    return res.data;
  },

  createPaymentIntent: async (cart, currency, shippingType) => {
    try {
      const res = await axios.post(
        `${API_URL}plugin/stripe/create-payment-intent`,
        {
          items: cart,
          currency,
          shippingType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "business-api-key": BUSINESS_API_KEY,
          },
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log(res);
      console.log("Api create payment intent");
      return res;
    } catch (error) {
      return error;
    }
  },

  confirmPaymentIntent: async (paymentIntent, sale) => {
    try {
      console.log(paymentIntent);
      console.log(sale);
      let res = await axios.post(
        `${API_URL}plugin/stripe/confirm-payment-intent`,
        {
          paymentIntentId: paymentIntent,
          saleId: sale,
        },
        { withCredentials: true, credentials: "include" }
      );
      console.log(res);
      return res;
    } catch (error) {
      return error;
    }
  },

  customOrder: async (order) => {
    let res = await axios.post(
      "/api/custom-order",
      { order },
      {
        headers: {
          "Content-Type": "application/json",
          "business-api-key": BUSINESS_API_KEY,
        },
        withCredentials: true,
        credentials: "include",
      }
    );
    console.log(res);
    return res;
  },
};

/**
 *
 * @desc    get user logged in
 * @route   GET /users/
 * @access  Private
 * @params  -
 * @returns user
 */
export async function getUser() {
  let res = await axios.get("/api/v1/users/user");
  // console.log(res.data)
  return res.data.user;
}

/**
 *
 * @desc    sign in user
 * @route   POST /users/sign-in
 * @access  Private
 * @params  email, password
 * @returns user, msg
 */
export async function signIn(email, password) {
  let res = await axios.post(
    "/api/v1/auth/sign-in",
    { email, password },
    { withCredentials: true, credentials: "include" }
  );
  console.log(res);
  return res;
}

/**
 *
 * @desc    sign up user
 * @route   POST /users/sign-up
 * @access  Private
 * @params  email, password, password2
 * @returns user, msg
 */
export async function signUp(email, password, password2) {
  let res = await axios.post("/api/v1/auth/sign-up", {
    email,
    password,
    password2,
  });
  //   console.log(res.data);
  return res;
}

/**
 *
 * @desc    sign out user
 * @route   GET /users/sign-out
 * @access  Private
 * @params  -
 * @returns msg
 */
export async function signOut() {
  let res = await axios.get("/api/v1/auth/sign-out");
  // console.log(res.data)
  return res.data;
}

/**
 *
 * @desc    delete user
 * @route   GET /users/delete-user
 * @access  Private
 * @params  -
 * @returns msg
 */
export async function deleteUser() {
  let res = await axios.get("/api/v1/users/remove");
  // console.log(res.data)
  return res.data;
}
