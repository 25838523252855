import axios from "axios";

const availableSlots = [
  {
    day: "Monday",
    slots: [
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
    ],
  },
  {
    day: "Tuesday",
    slots: [
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
    ],
  },
  {
    day: "Wednesday",
    slots: [
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
    ],
  },
  {
    day: "Thursday",
    slots: [
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
    ],
  },
  {
    day: "Friday",
    slots: [
      "8:00",
      "9:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
    ],
  },
  {
    day: "Saturday",
    slots: [],
  },
  {
    day: "Sunday",
    slots: [],
  },
];

export async function getBookings() {
  let res = await axios.get("/api/bookings/");
  // console.log(res.data);
  return res.data;
}

export async function createBooking(booking) {
  booking = {
    name: "Consultancy 1h £50",
    availableSlots,
    notAvailableSlots: [
      {
        day: "Monday",
        date: new Date(2023, 7, 11, 9), // 2023-08-11T09:00:00.000 ps: not GMT
        slot: "09:00",
      },
    ],
    bookedSlots: [],
  };
  let res = await axios.post(`/api/bookings/`, booking);
  console.log(res.data);
  //   return res.data;
}

export async function deleteBooking(bookingId) {
  let res = await axios.delete(`/api/bookings/${bookingId}`);
  console.log(res.data);
  //   return res.data;
}

export async function setAvailability(bookingId, selectedSlots) {
  let res = await axios.put(`/api/bookings/${bookingId}`, selectedSlots);
  console.log(res.data);
  //   return res.data;
}

export async function bookSlots(data) {
  let bookingId = "649f00ee3b9e4fedcede0f2b";
  let res = await axios.post(`/api/bookings/${bookingId}/book-slots`, { data });
  console.log(res.data);
  //   return res.data;
}
