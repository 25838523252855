import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CartProvider } from "./contexts/CartContext";
import { PopUpProvider } from "./contexts/PopUpContext";
import { UserProvider } from "./contexts/UserContext";
import { ProductProvider } from "./contexts/ProductsContext";
import { SocketProvider } from "./contexts/SocketContext";
import App from "./App";
import ErrorPage from "./ErrorPage";
import "./index.css";
import Order from "./Components/Orders/Order";
import Home from "./Components/Home/Home";
import SignInForm from "./Components/Auth/SignInForm";
import SignUp from "./Components/Auth/SignUp";
import Marketplace from "./Components/Marketplace/Marketplace";
import ContactUs from "./Components/ContactUs/ContactUs";
import Profile from "./Components/Profile/Profile";
import AboutUs from "./Components/AboutUs/AboutUs";
import Cart from "./Components/Cart/Cart";
import Checkout from "./Components/Checkout/Checkout";
import CustomOrder from "./Components/CustomOrder/CustomOrder";
import BookingCalendar from "./Components/BookingCalendar/BookingCalendar";
import CustomCheckout from "./Components/Checkout/CustomCheckout";
import Accounting from "./Components/Accounting/Accounting";
import CreateNewBudget from "./Components/Accounting/CreateNewBudget/CreateNewBudget";
import CreateBusinessForm from "./pages/businessPage/CreateBusinessForm";
import BusinessDetails from "./pages/businessPage/BusinessDetails";
import InventoryItemsPage from "./pages/businessPage/InventoryItems/InventoryItemsPage";
import CreateNewItem from "./pages/businessPage/InventoryItems/CreateNewItem";
import EditBusinessDetails from "./pages/businessPage/EditBusinessDetails";
import EditInventoryItem from "./pages/businessPage/InventoryItems/EditInventoryItem";
import SalesPage from "./pages/businessPage/Sales/SalesPage";
import SaleDetails from "./pages/businessPage/Sales/SaleDetails";
import TransactionsPage from "./pages/businessPage/Transactions/TransactionsPage";
import TransactionDetails from "./pages/businessPage/Transactions/TransactionDetails";
import FilePage from "./pages/filePage/FilePage";
import BusinessesPage from "./pages/businessPage/BusinessesPage";
import SignUpForm from "./Components/Auth/SignUpForm";
import EditSale from "./pages/businessPage/Sales/EditSale";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "orders/:orderId",
        element: <Order />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "uploads/:filePath", // Add a new route for the "uploads" page
        element: <FilePage />,
      },
      {
        path: "/sign-in",
        element: <SignInForm />,
      },
      {
        path: "/sign-up",
        element: <SignUpForm />,
      },
      {
        path: "/marketplace",
        element: <Marketplace />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/checkout/:step",
        element: <Checkout />,
      },
      {
        path: "checkout/:orderId/:step",
        element: <CustomCheckout />,
      },
      {
        path: "/custom-order",
        element: <CustomOrder />,
      },
      {
        path: "/book-consultancy",
        element: <BookingCalendar />,
      },
      {
        path: "/accounting",
        element: <Accounting />,
      },
      {
        path: "/create-new-budget",
        element: <CreateNewBudget />,
      },
      {
        path: "/business",
        element: <BusinessesPage />,
      },
      {
        path: "/create-business",
        element: <CreateBusinessForm />,
      },
      {
        path: "/business/:businessId",
        element: <BusinessDetails />,
      },
      {
        path: "/business/:businessId/edit",
        element: <EditBusinessDetails />,
      },
      {
        path: "/business/:businessId/inventory-items",
        element: <InventoryItemsPage />,
      },
      {
        path: "/business/:businessId/inventory-items/:inventoryItemId",
        element: <EditInventoryItem />,
      },
      {
        path: "/business/:businessId/inventory-items/create-new",
        element: <CreateNewItem />,
      },
      {
        path: "/business/:businessId/sales",
        element: <SalesPage />,
      },
      {
        path: "/business/:businessId/sales/:saleId",
        element: <SaleDetails />,
      },
      {
        path: "/business/:businessId/sales/:saleId/edit",
        element: <EditSale />,
      },
      {
        path: "/business/:businessId/transactions",
        element: <TransactionsPage />,
      },
      {
        path: "/business/:businessId/transactions/:transactionId",
        element: <TransactionDetails />,
      },
      { path: "/recover-password/:resetToken", element: <ResetPassword /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SocketProvider>
      <UserProvider>
        <ProductProvider>
          <PopUpProvider>
            <CartProvider>
              <RouterProvider router={router} />
            </CartProvider>
          </PopUpProvider>
        </ProductProvider>
      </UserProvider>
    </SocketProvider>
  </React.StrictMode>
);
