import React, { useState } from "react";
import { FaTelegram, FaFacebook, FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const subjectOptions = [
  "quote",
  "hire dev",
  "ecommerce template",
  "consultancy",
  "request demo",
  "custom order",
  "other",
];

const whenOptions = [
  "Any time",
  "Email only",
  "Message only",
  "Tomorrow by 5pm",
  "Today by 5pm",
  "Today by 12am",
  "Tomorrow by 12am",
];

const ContactUs = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: "",
    company: "",
    email: "",
    mobile: "",
    subject: "",
    when: "",
    message: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    console.log(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!isFormValid()) {
    //   console.log("Please fill in all the required fields.");
    //   return;
    // }

    try {
      const response = await axios.post("api/contact-us", formData);
      console.log(response.data);
      navigate(-1);
    } catch (error) {
      console.error("An error occurred while submitting the form:", error);
    }
    // Add logic to handle the form submission (e.g., sending an email)
    console.log("Form submitted:", formData);
    // Clear the form after submission
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const isFormValid = () => {
    return Object.values(formData).every((value) => value.trim() !== "");
  };

  return (
    <div className="md:min-h-screen mb-32">
      <div className="text-center bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-2">
        <h1 className="text-5xl xs:text-2xl font-bold text-gray-900 mb-4">
          <span>Anything unclear or</span>
          <span className="font-serif italic pl-4">need help</span>
          <span>?</span>
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row flex-wrap gap-4 mr-4 mb-16 justify-center items-center lg:items-stretch">
        <div className="bg-white rounded-md shadow-md overflow-hidden p-4 max-w-96">
          <div className="p-4">
            <h3 className="text-xl font-semibold">Email Us</h3>
            <p className="text-gray-700">
              Send us an email, we will help you as soon as possible
            </p>
            <p className="mb-4">
              <span className="text-[#8e2421] text-sm md:text-xl">
                werewolf.solutions.info@gmail.com
              </span>
            </p>
            <a
              className="bg-[#8e2421] hover:bg-[#8d3c39] px-2 py-2 rounded-md font-medium text-[#ffffff]"
              href="mailto:werewolf.solutions.info@gmail.com"
            >
              Send Email
            </a>
          </div>
        </div>

        <div className="bg-white rounded-md shadow-md overflow-hidden p-4 max-w-96">
          <div className="p-4">
            <h3 className="text-xl font-semibold">Call Us</h3>
            <p className="text-gray-700">
              Call us, we will answer you as soon as possible
            </p>
            <p className="mb-4">
              <span className="text-[#8e2421] text-sm md:text-xl">
                +447765805186
              </span>
            </p>
            <a
              className="bg-[#8e2421] hover:bg-[#8d3c39] px-2 py-2 rounded-md font-medium text-[#ffffff]"
              href="tel:+447765805186"
            >
              Call now
            </a>
          </div>
        </div>

        <div className="bg-white rounded-md shadow-md overflow-hidden p-4 max-w-96">
          <div className="p-4">
            <h3 className="text-xl font-semibold">Message Us</h3>
            <p className="text-gray-700">
              Send us a message to seek help from us, we will get back to you as
              soon as possible
            </p>
            <div className="flex flex-row justify-center gap-8 mt-4">
              <a
                href="https://www.facebook.com/profile.php?id=61557991095346"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#8d3c39]"
              >
                <FaFacebook size={30} />
              </a>
              <a
                href="https://t.me/+IgwxaWzM-U9kUsG_"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#8d3c39]"
              >
                <FaTelegram size={30} />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=447765805186"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-[#8d3c39]"
              >
                <FaWhatsapp size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mr-4 md:mr-0">
        <div className="bg-white rounded-md shadow-md overflow-hidden p-6 max-w-lg mx-auto">
          <h3 className="text-xl font-semibold mb-4">Message Us</h3>
          <p className="text-gray-700 mb-6">
            Send us a message to seek help from us, we will get back to you as
            soon as possible
          </p>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Message:
              </label>
              <textarea
                id="message"
                name="message"
                required
                className="w-full border border-gray-300 rounded-md px-3 py-2 h-32"
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="bg-[#8e2421] text-white font-semibold px-4 py-2 rounded-md hover:bg-[#8d3c39]"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center min-h-screen">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <div className="md:mb-18 text-center">
          <h1 className="font-heading text-5xl xs:text-6xl font-bold text-gray-900 mb-4">
            <span>Anything unclear or</span>
            <span className="font-serif italic pl-4">need help</span>
            <span>?</span>
          </h1>
        </div>
        <div className="flex flex-wrap justify-center">
          <div className="px-4 xl:px-10 mb-10 md:mb-0 mt-6">
            <div className="py-8 px-6 bg-white border border-gray-200 rounded-5xl">
              <div className="max-w-2xs text-center">
                <h5 className="text-2xl font-bold text-gray-900 mb-3">
                  Contact Us
                </h5>
                <p className="text-xl text-gray-500 font-semibold mb-3">
                  Send us an email, we will help you as soon as possible
                </p>
                <span className="block text-xl text-orange-900 mb-8">
                  werewolf.solutions.info@gmail.com
                </span>
                <a
                  className="bg-[#8e2421] w-[200px] rounded-md font-medium my-3 p-3 text-[#ffffff]"
                  href="mailto:werewolf.solutions.info@gmail.com"
                >
                  Send Email
                </a>
              </div>
            </div>
          </div>
          <div className="px-4 xl:px-10 mb-10 md:mb-0 mt-6">
            <div className="py-8 px-6 bg-white border border-gray-200 rounded-5xl">
              <div className="max-w-2xs text-center">
                <h5 className="text-2xl font-bold text-gray-900 mb-3">
                  Customer Service
                </h5>
                <p className="text-xl text-gray-500 font-semibold mb-3">
                  Call us, we will answer you as soon as possible
                </p>
                <span className="block text-xl text-orange-900 mb-8">
                  +447765805186
                </span>
                <a
                  className="bg-[#8e2421] w-[200px] rounded-md font-medium my-3 p-3 text-[#ffffff]"
                  href="tel:+447765805186"
                >
                  Call Now
                </a>
              </div>
            </div>
          </div>
          <div className="px-4 xl:px-10 mb-10 md:mb-0 mt-6">
            <div className="py-8 px-6 bg-white border border-gray-200 rounded-5xl">
              <div className="max-w-2xs mx-auto text-center">
                <h5 className="text-2xl font-bold text-gray-900 mb-3">
                  Send A Message
                </h5>
                <p className="text-xl text-gray-500 font-semibold mb-3">
                  Send us a message to seek help from us, we will get back to
                  you as soon as possible
                </p>
                <div className="flex flex-row justify-center gap-8 my-4">
                  <a
                    href="https://www.facebook.com/profile.php?id=61557991095346"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook size={30} />
                  </a>
                  <a
                    href="https://t.me/+Oil_0GcvuolmMTlk"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTelegram size={30} />
                  </a>
                  <a
                    href="https://api.whatsapp.com/send?phone=447765805186"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp size={30} />
                  </a>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col items-center gap-3">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 focus:placeholder:opacity-100"
                    />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 focus:placeholder:opacity-100"
                    />
                    <textarea
                      id="message"
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      className="rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 focus:placeholder:opacity-100"
                    />
                    <button
                      type="submit"
                      className="bg-[#8e2421] w-[200px] rounded-md font-medium my-3 mx-auto py-3 text-[#ffffff]"
                    >
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="map" className="mt-12 mb-10">
          <iframe
            title="This is a unique title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12345.67890!2d-74.005972!3d40.712776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjfCsDM0JzM1LjciTiA3NMKwMDcnMDQuMCJX!5e0!3m2!1sen!2sus!4v1234567890123"
            width="100%"
            height="480"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
