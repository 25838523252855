import React, { useEffect, useState } from "react";

const { REACT_APP_PUBLIC_URL, NODE_ENV } = process.env;
const PUBLIC_URL =
  NODE_ENV === "production" ? REACT_APP_PUBLIC_URL : "http://localhost:5000/";

const ImageUploader = ({ onFileChange, image }) => {
  const [file, setFile] = useState(null);
  const [img, setImg] = useState(null);

  // useEffect(() => setImg(image ? image : null), [image]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      onFileChange(selectedFile);
      setImg(selectedFile);
    }
  };

  const removeImage = () => {
    setImg("");
    onFileChange("");
  };

  useEffect(() => {
    console.log(image);
    if (image) {
      if (image.backgroundImg) {
        console.log("Image has background");
        setImg(URL.createObjectURL(image.backgroundImg));
      } else if (image.url) {
        console.log("Image has url");
        setImg(`${PUBLIC_URL}${image.url}`);
      } else if (typeof image === "string") {
        // If image is a URL
        console.log("Image typeof image === string = true");
        setImg(image.startsWith("blob:") ? image : `${PUBLIC_URL}${image}`);
      } else if (image instanceof File) {
        console.log("image instanceof File = true");
        // If image is a File object
        setImg(URL.createObjectURL(image));
        // setFile(image);
      } else {
        console.log("Is image null?");
        setImg(null);
      }
    } else {
      console.log("Image is null or undefined");
      setImg(null);
    }
  }, [image]);

  const imagePreview = img && (
    <div className="flex justify-around items-center m-2">
      <img
        src={img} //src={URL.createObjectURL(img)}
        style={{ width: "70px", height: "auto" }}
      />
      <button
        className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
        onClick={() => removeImage(img)}
      >
        Remove
      </button>
    </div>
  );

  return (
    <div className="flex items-center flex-wrap">
      {img ? (
        imagePreview
      ) : (
        <label htmlFor="image" className="cursor-pointer">
          {/* <span className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg">
            Choose File
          </span> */}
          <input
            id="image"
            type="file"
            // className="hidden"
            className="text-sm text-stone-500 file:mr-5 file:py-1 file:px-3 file:border-[1px] file:text-xs file:font-medium file:bg-stone-50 file:text-stone-700 hover:file:cursor-pointer hover:file:bg-blue-50 hover:file:text-blue-700"
            onChange={handleFileChange}
            required
          />
        </label>
      )}
    </div>
  );
};

export default ImageUploader;
