import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { usePopUp } from "../../contexts/PopUpContext";
import { useUser } from "../../contexts/UserContext";

const CustomOrder = () => {
  const { openPopUp } = usePopUp();
  const { user, customOrder } = useUser();
  const navigate = useNavigate();
  const [orderType, setOrderType] = useState("");
  const [timeframe, setTimeframe] = useState({ duration: 3, unit: "days" });
  const [totalAmount, setTotalAmount] = useState(100);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState(user && !user.demo ? user.email : "");
  const [hasDesign, setHasDesign] = useState(false);

  const handleOrderTypeChange = (e) => {
    setOrderType(e.target.value);
  };

  const handleTimeframeChange = (e) => {
    setTimeframe({ ...timeframe, [e.target.name]: e.target.value });
  };

  const handleTotalAmountChange = (e) => {
    setTotalAmount(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleHasDesignChange = (e) => {
    setHasDesign(e.target.checked);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let duration =
      timeframe.unit === "days" && timeframe.duration < 3
        ? 3
        : timeframe.duration
        ? timeframe.duration
        : 1;
    let order = {
      email,
      orderType,
      timeframe: { duration, unit: timeframe.unit },
      totalAmount,
      description,
      hasDesign,
    };
    let response = await customOrder(order);
    console.log(response);
    openPopUp(
      "Thank you for your order. This will be reviewed by the team and get back to you ASAP.",
      3000
    );
    navigate(`/orders/${response.data.new_order._id}`);
  };

  return (
    <div className="min-h-screen mt-4 mr-4 md:mr-0 mb-32 md:mb-0">
      <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">
          Custom Order
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="orderType"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Order Type:
            </label>
            <select
              id="orderType"
              value={orderType}
              onChange={handleOrderTypeChange}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2"
            >
              <option value="">Select an option</option>
              <option value="single-page">Single Page Website</option>
              <option value="multi-page">Multiple Pages Website</option>
              <option value="saas">SaaS</option>
              <option value="ecommerce">Ecommerce</option>
              <option value="dapp">dApp</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="timeframe"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Timeframe (min 3 days):
            </label>
            <div className="flex space-x-2">
              <input
                type="number"
                id="duration"
                name="duration"
                value={
                  timeframe.unit === "days" && timeframe.duration < 3
                    ? 3
                    : timeframe.duration
                    ? timeframe.duration
                    : 1
                }
                onChange={handleTimeframeChange}
                min="1"
                required
                className="w-1/2 border border-gray-300 rounded-md px-3 py-2"
              />
              <select
                id="unit"
                name="unit"
                value={timeframe.unit}
                onChange={handleTimeframeChange}
                required
                className="w-1/2 border border-gray-300 rounded-md px-3 py-2"
              >
                <option value="days">Days</option>
                <option value="weeks">Weeks</option>
                <option value="months">Months</option>
              </select>
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="totalAmount"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Budget (min 100 USD):
              <a
                href="https://chat.openai.com/share/5d30c274-b623-452d-9578-ff5bd71b22f7"
                className="text-blue-500 hover:underline ml-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ask chatGPT
              </a>
            </label>
            <input
              type="number"
              id="totalAmount"
              value={totalAmount ? totalAmount : 100}
              onChange={handleTotalAmountChange}
              min="100"
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Description of the idea:
            </label>
            <textarea
              id="description"
              value={description}
              onChange={handleDescriptionChange}
              required
              className="w-full border border-gray-300 rounded-md px-3 py-2"
            />
          </div>

          <div className="mb-4 flex items-center">
            <input
              type="checkbox"
              id="hasDesign"
              checked={hasDesign}
              onChange={handleHasDesignChange}
              className="h-4 w-4 text-blue-600 border-gray-300 rounded"
            />
            <label
              htmlFor="hasDesign"
              className="ml-2 block text-sm font-medium text-gray-700"
            >
              Do you already have a design?
            </label>
          </div>

          <div className="flex justify-between">
            <button
              type="submit"
              className="bg-green-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-green-600"
            >
              Confirm
            </button>
            <Link
              to="/marketplace"
              className="bg-gray-500 text-white font-semibold px-4 py-2 rounded-md hover:bg-gray-600"
            >
              Marketplace
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomOrder;
