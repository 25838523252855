import React, { useState } from "react";
import { Dialog } from "@mui/material";
import AddIncomeDialog from "./AddIncomeDialog";
import { deleteIncome } from "../../../../apiCalls/accountingCalls";
import EditIncome from "./EditIncome";

export default function IncomesDialog(props) {
  const { budget } = props;

  const [addIncomeDialog, setAddIncomeDialog] = useState(false);
  const [editIncomeDialog, setEditIncomeDialog] = useState(false);
  const [incomeSelected, setIncomeSelected] = useState(false);

  const handleEditIncomeDialog = (income) => {
    if (income) {
      setIncomeSelected(income);
    }
    setEditIncomeDialog(!editIncomeDialog);
  };

  const handleAddIncomeDialog = () => {
    setAddIncomeDialog(!addIncomeDialog);
  };
  return (
    <div>
      <AddIncomeDialog
        open={addIncomeDialog}
        onClose={handleAddIncomeDialog}
        updateUser={props.updateUser}
        budget={budget}
        updateBudget={props.updateBudget}
      />
      <EditIncome
        open={editIncomeDialog}
        onClose={handleEditIncomeDialog}
        updateUser={props.updateUser}
        budget={budget}
        income={incomeSelected}
        updateBudget={props.updateBudget}
      />
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <span className="table-title">
          <h1>
            Incomes {budget?.tot_incomes} {budget?.currency}
          </h1>
          <button onClick={handleAddIncomeDialog}>add income</button>
        </span>
        {budget?.incomes?.map((income) => (
          <>
            <span className="table-name">
              {income?.name}
              <button
                onClick={async () => {
                  await deleteIncome(budget._id, income._id);
                  props.updateUser();
                  props.updateBudget();
                }}
              >
                delete
              </button>
              <button onClick={() => handleEditIncomeDialog(income)}>
                edit
              </button>
            </span>

            <span className="table-sub-name">
              Amount:
              <span className="table-props-text">
                {income?.amount} {income?.currency}
              </span>
            </span>
            <span className="table-sub-name">
              Date:
              <span className="table-props-text">{income?.formatted_date}</span>
            </span>
            <span className="table-sub-name">
              To: <span className="table-props-text">{income?.to?.name}</span>
            </span>
            {/* <span className="table-sub-name">From: {income?.from}</span> */}
            {/* <span className="table-sub-name">
              Notes: <span className="table-props-text">{income?.notes}</span>
            </span> */}
            <span className="table-sub-name">
              Shift:
              <span className="table-props-text">
                {income?.shift?.hours} hs & {income?.shift?.minutes} ms
              </span>
            </span>
            <br />
          </>
        ))}
      </Dialog>
    </div>
  );
}
