import React, { useState } from "react";
import { Dialog } from "@mui/material";
import moment from "moment";
import { updateBudget } from "../../../../apiCalls/accountingCalls";

export default function EditIncome(props) {
  const [state, setState] = useState();
  const [date, setDate] = useState();
  const [bankAccount, setBankAccount] = useState();

  const handleDateSelect = (e) => {
    setDate(e.target.value);
  };

  const handleBankAccountSelect = (e) => {
    for (let i = 0; i < props.budget?.bank_accounts?.length; i++) {
      if (props.budget.bank_accounts[i]._id == e.target.value) {
        setBankAccount(props.budget.bank_accounts[i]);
      }
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <h1>Edit income</h1>
        <div className="input-row">
          <h1>name*</h1>
          <input
            id="name"
            onChange={handleChange}
            className="name-input"
            value={
              state?.name || state?.name === ""
                ? state.name
                : props.income?.name
            }
          />
        </div>
        <div className="input-row">
          <h1>amount*</h1>
          <input
            id="amount"
            onChange={handleChange}
            className="name-input"
            value={
              state?.amount || state?.amount === ""
                ? state.amount
                : props.income?.amount
            }
          />
        </div>
        <div className="input-row-date">
          <h1>Date*</h1>
          <input
            type="date"
            value={date ? date : moment(props.income.date).format("YYYY-MM-DD")}
            onChange={handleDateSelect}
          />
        </div>
        <div className="input-row">
          <h1>To*</h1>
          <select
            name="to"
            id="to"
            onChange={handleBankAccountSelect}
            value={props.income?.to?._id}
          >
            {props.budget?.bank_accounts?.map((bank) => (
              <option value={bank._id}>{bank.name}</option>
            ))}
          </select>
        </div>
        <span className="save-btn">
          <button
            onClick={async () => {
              let updatedData = {
                income: {
                  _id: props.income._id,
                  date: date ? date : props.income.date,
                  amount: state?.amount ? state?.amount : props.income.amount,
                  to: bankAccount ? bankAccount : props.income.to,
                  name: state?.name ? state?.name : props.income.name,
                },
              };
              let res = await updateBudget(props.budget._id, updatedData);
            }}
          >
            save
          </button>
        </span>
      </Dialog>
    </div>
  );
}
