import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export default function SalesPage() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [sales, setSales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOption, setSortOption] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchSales = async () => {
    try {
      const res = await axios.get(`/api/v1/erp/${businessId}/sales`);
      if (res.data.sales) {
        setSales(res.data.sales);
      }
    } catch (error) {
      console.error("Error fetching sales details:", error);
    }
  };

  const deleteSale = async (sale) => {
    try {
      await axios.delete(`/api/v1/erp/${businessId}/sales/${sale._id}`);
      fetchSales();
    } catch (error) {
      console.error("Error deleting sale:", error);
    }
  };

  useEffect(() => {
    fetchSales();
  }, [businessId]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSort = (e) => {
    setSortOption(e.target.value);
  };

  const handleSortOrder = (e) => {
    setSortOrder(e.target.value);
  };

  const filteredSales = sales
    .filter((sale) => {
      return (
        sale._id.includes(searchTerm) ||
        sale.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .filter((sale) => {
      if (statusFilter === "") return true;
      return sale.status === statusFilter;
    })
    .sort((a, b) => {
      if (sortOption === "amount") {
        return sortOrder === "asc" ? a.amount - b.amount : b.amount - a.amount;
      }
      return sortOrder === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    });

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-2 md:px-4 mb-32 m-8">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg md:p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-[#8e2421]">Sales</h1>
          <button
            onClick={() => navigate(-1)}
            className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
        </div>

        <div className="flex flex-wrap items-center mb-6 gap-4">
          <input
            type="text"
            placeholder="Search by ID or status"
            value={searchTerm}
            onChange={handleSearch}
            className="w-1/3 p-2 border border-gray-300 rounded-lg"
          />
          <select
            value={statusFilter}
            onChange={handleFilter}
            className="w-1/5 p-2 border border-gray-300 rounded-lg"
          >
            <option value="">All Statuses</option>
            <option value="paid">Paid</option>
            <option value="unpaid">Unpaid</option>
            <option value="processing">Processing</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
          </select>
          <div className="w-1/3 flex space-x-2">
            <select
              value={sortOption}
              onChange={handleSort}
              className="w-1/2 p-2 border border-gray-300 rounded-lg"
            >
              <option value="date">Sort by Date</option>
              <option value="amount">Sort by Amount</option>
            </select>
            <select
              value={sortOrder}
              onChange={handleSortOrder}
              className="w-1/2 p-2 border border-gray-300 rounded-lg"
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          {filteredSales.length > 0 ? (
            filteredSales.map((sale) => (
              <div key={sale._id} className="bg-gray-50 p-4 rounded-lg shadow">
                <div className="flex justify-between items-center mb-2">
                  <p className="text-lg font-semibold text-gray-700">
                    Status: {sale.status}
                  </p>
                  <div className="flex flex-wrap space-x-2">
                    <button
                      onClick={() => navigate(`${sale._id}`)}
                      className="bg-blue-500 text-white py-1 px-3 rounded-full font-semibold hover:bg-blue-600 hover:shadow-lg"
                    >
                      Details
                    </button>
                    {/* <button
                      onClick={() => deleteSale(sale)}
                      className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-red-600 hover:shadow-lg"
                    >
                      Delete
                    </button> */}
                    {/* <button
                      onClick={() => navigate(`${sale._id}/edit`)}
                      className="bg-green-500 text-white py-1 px-3 rounded-full font-semibold hover:bg-green-600 hover:shadow-lg"
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
                <p className="text-gray-600">
                  Timestamp: {moment(sale.date).format("DD-MMM-YYYY @hh:mm")}
                </p>
                <p className="text-gray-600">Sale ID: {sale._id}</p>
                <p className="text-gray-600">
                  Amount: {(sale.amount / 100).toFixed(2)} {sale.currency}
                </p>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No sales found.</p>
          )}
        </div>
      </div>
    </div>
  );
}
