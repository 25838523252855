import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { useSocket } from "../../contexts/SocketContext";
import axios from "axios";
import moment from "moment";

import EditOrder from "./EditOrder";

export default function Order() {
  const { orderId } = useParams();
  const { user } = useUser();
  const socket = useSocket();
  const [order, setOrder] = useState(null);
  const [editOrder, setEditOrder] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`/api/orders/${orderId}`);
      setOrder(response.data);
      console.log(response.data);
    } catch (error) {
      // Handle error
      console.error("Error fetching order details:", error);
    }
  };

  const downloadFile = () => {
    // Create a virtual anchor element
    const link = document.createElement("a");
    link.href = order.file; // Set the file URL as the href
    link.setAttribute("download", "filename"); // Set the desired filename for the download

    // Append the anchor to the DOM and click it programmatically to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the anchor element after download
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log(process.env.PUBLIC_URL);
    if (!socket) return;

    socket.on("orderUpdate", (data) => {
      // Handle the updated order status here
      fetchOrderDetails();
    });
    socket.on("order_update", (data) => {
      // Handle the updated order status here
      fetchOrderDetails();
    });

    fetchOrderDetails();

    // Clean up the socket event listener when the component unmounts
    return () => {
      socket.off("orderUpdate");
      socket.off("order_update");
    };
  }, [orderId, socket]);

  const renderStepButtons = () => {
    if (order) {
      switch (order.currentStep) {
        case 1:
          return (
            <>
              {user?.isAdmin ? (
                <div>
                  {(order.status === "pending" ||
                    order.status === "processing") && (
                    <>
                      {order.devAccepted ? (
                        <>
                          <button
                            onClick={() => setEditOrder(!editOrder)}
                            className="button"
                          >
                            Edit Order
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="button"
                            onClick={async () => {
                              let res = await axios.put(
                                `/api/orders/${order._id}/dev-accepts`
                              );
                              console.log(res);
                              // fetchOrderDetails();
                              socket.emit("orderUpdate", res);
                            }}
                          >
                            Start reviewing
                          </button>
                        </>
                      )}
                    </>
                  )}
                  {order.status === "updated" && (
                    <>
                      {order.clientAccepted ? (
                        <>
                          <button
                            className="button"
                            onClick={async () => {
                              let res = await axios.put(
                                `/api/orders/${order._id}/dev-accepts`
                              );
                              console.log(res);
                              // fetchOrderDetails();
                              socket.emit("orderUpdate");
                            }}
                          >
                            Start reviewing
                          </button>
                          <button
                            onClick={() => setEditOrder(!editOrder)}
                            className="button"
                          >
                            Edit Order
                          </button>
                        </>
                      ) : (
                        <span className="txt-green">
                          Waiting for client response
                        </span>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  {(order.status === "pending" ||
                    order.status === "processing") && (
                    <span className="font-extrabold text-[#8e2421]">
                      Wait for order confirmation
                    </span>
                    // <button
                    //   onClick={() => setEditOrder(!editOrder)}
                    //   className="bg-[#8e2421] text-white text-sm leading-6 font-medium py-1 px-4 rounded-md hover:bg-[#8e3f3d] z-0"
                    // >
                    //   Edit Order
                    // </button>
                  )}
                  {order.status === "updated" && (
                    <>
                      {order.devAccepted ? (
                        <>
                          <button
                            className="button"
                            onClick={async () => {
                              let res = await axios.put(
                                `/api/orders/${order._id}/client-accepts`
                              );
                              console.log(res);
                              // fetchOrderDetails();
                              socket.emit("orderUpdate");
                            }}
                          >
                            Accept the changes
                          </button>
                          <button
                            onClick={() => setEditOrder(!editOrder)}
                            className="button"
                          >
                            Edit Order
                          </button>
                        </>
                      ) : (
                        <span className="txt-green">
                          Waiting for dev response
                        </span>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          );
        case 2:
          return (
            <>
              {user?.isAdmin ? (
                <>
                  {order.status === "reviewing" && (
                    <>
                      <button
                        onClick={() => setEditOrder(!editOrder)}
                        className="button"
                      >
                        Edit Order
                      </button>
                      <button
                        className="button"
                        onClick={async () => {
                          let res = await axios.put(
                            `/api/orders/${order._id}/dev-accepts`
                          );
                          console.log(res);
                          // fetchOrderDetails();
                          socket.emit("orderUpdate");
                        }}
                      >
                        Accept Order
                      </button>
                    </>
                  )}
                  {order.status === "updated" && (
                    <>
                      {order.clientAccepted ? (
                        <>
                          <button
                            onClick={() => setEditOrder(!editOrder)}
                            className="button"
                          >
                            Edit Order
                          </button>
                          <button
                            className="button"
                            onClick={async () => {
                              let res = await axios.put(
                                `/api/orders/${order._id}/dev-accepts`
                              );
                              console.log(res);
                              // fetchOrderDetails();
                              socket.emit("orderUpdate");
                            }}
                          >
                            Accept Updates
                          </button>
                        </>
                      ) : (
                        <span className="txt-green">
                          Waiting for client to accept updates
                        </span>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {order.status === "reviewing" && (
                    <span className="txt-green">Reviewing</span>
                  )}
                  {order.status === "updated" && (
                    <>
                      {order.devAccepted ? (
                        <>
                          <Link
                            to={`/checkout/${order._id}/1`}
                            className="button"
                          >
                            Accept & Make payment
                          </Link>
                          <button
                            onClick={() => setEditOrder(!editOrder)}
                            className="button"
                          >
                            Edit Order
                          </button>
                        </>
                      ) : (
                        <span className="txt-green">
                          Waiting for dev to accept
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          );
        case 3:
          return (
            <>
              {user?.isAdmin ? (
                <>
                  {order.status === "accepted" && (
                    <>
                      {order.paymentIntents.map((paymentIntent, index) =>
                        paymentIntent.paymentStep === order.currentStep - 1 &&
                        paymentIntent.status === "succeeded" ? (
                          <button onClick={() => setEditOrder(!editOrder)}>
                            Upload demo link
                          </button>
                        ) : (
                          <span className="txt-green">
                            Waiting for client to make first payment
                          </span>
                        )
                      )}
                      {order.paymentIntents.length === 0 && (
                        <span className="txt-green">
                          Waiting for client to make first payment
                        </span>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {order.clientAccepted && (
                    <Link to={`/checkout/${order._id}/1`} className="button">
                      Accept & Make payment
                    </Link>
                  )}
                </>
              )}
            </>
          );
        case 4:
          return (
            <>
              {user?.isAdmin ? (
                <>
                  {order.status === "demo" && order.clientAccepted ? (
                    <button onClick={() => setEditOrder(!editOrder)}>
                      Upload final demo link
                    </button>
                  ) : (
                    <span className="txt-green">
                      Waiting for client to accept
                    </span>
                  )}
                </>
              ) : (
                <>
                  {order.status === "demo" && order.demoLink ? (
                    <>
                      <Link
                        to={order.demoLink}
                        className="button"
                        target="_blank"
                      >
                        Demo
                      </Link>
                      <Link to={`/checkout/${order._id}/1`} className="button">
                        Accept & Make payment
                      </Link>
                      {/* <button
                        onClick={() => setEditOrder(!editOrder)}
                        className="button"
                      >
                        Edit Order
                      </button> */}
                    </>
                  ) : (
                    <span className="txt-green">Waiting for demo link</span>
                  )}
                </>
              )}
            </>
          );
        case 5:
          return (
            <>
              {user?.isAdmin ? (
                <>
                  {order.status === "ready" && (
                    <span className="txt-green">
                      Waiting for client to make last payment
                    </span>
                  )}

                  {order.status === "final" && (
                    <button
                      onClick={async () => {
                        let res = await axios.put(
                          `/api/orders/${order._id}/dev-accepts`
                        );
                        console.log(res);
                        // fetchOrderDetails();
                        socket.emit("orderUpdate", res);
                      }}
                    >
                      Mark as ready
                    </button>
                  )}
                </>
              ) : (
                <>
                  {order.status === "ready" && (
                    <>
                      <Link
                        to={order.demoLink}
                        className="button"
                        target="_blank"
                      >
                        Demo
                      </Link>
                      <Link to={`/checkout/${order._id}/1`} className="button">
                        Make final payment
                      </Link>
                    </>
                  )}
                  {order.status === "final" && (
                    <div className="select-wrapper">
                      {/* <label htmlFor="hosting-options">Choose an option</label>
                      <select
                        id="hosting-options"
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        <option value="">Select an option</option>
                        <option value="self-hosted">
                          I will host it on my own
                        </option>
                        <option value="subscribe-hosting">
                          I want to subscribe for hosting services
                        </option>
                      </select>
                      {selectedOption === "subscribe-hosting" && (
                        <>
                          <label htmlFor="subcription-plan">
                            Choose a subscription plan
                          </label>
                          <select
                            id="subcription-plan"
                            value={selectedPlan}
                            onChange={handlePlanChange}
                          >
                            <option value="">Select an option</option>
                            <option value="basic">£5/month</option>
                            <option value="business">£10/month</option>
                          </select>
                        </>
                      )} */}
                      <span className="txt-green">
                        Waiting for finilization
                      </span>
                    </div>
                  )}
                  {order.status === "delivered" && (
                    // <button className="button" onClick={downloadFile}>
                    //   Download file
                    // </button>
                    <span className="txt-green">
                      Check your email for the file to download
                    </span>
                  )}
                </>
              )}
            </>
          );
        case 6:
          return (
            <>
              {user?.isAdmin ? (
                <>
                  {order.status === "final" && (
                    <button
                      onClick={async () => {
                        let res = await axios.put(
                          `/api/orders/${order._id}/dev-accepts`
                        );
                        console.log(res);
                        // fetchOrderDetails();
                        socket.emit("orderUpdate");
                      }}
                    >
                      Mark as delivered
                    </button>
                  )}
                  {order.status === "delivered" && (
                    <span className="txt-green">
                      Last payment succeeded. Send final product
                    </span>
                  )}
                </>
              ) : (
                <>
                  {order.status === "ready" && (
                    <div className="select-wrapper">
                      <Link to={`/checkout/${order._id}/1`} className="button">
                        Make final payment
                      </Link>
                    </div>
                  )}
                  {order.status === "final" && (
                    <span className="txt-green">Waiting for finilization</span>
                  )}
                  {order.status === "delivered" && (
                    // <button className="button" onClick={downloadFile}>
                    //   Download file
                    // </button>
                    <span className="txt-green">
                      Check your email for the file to download
                    </span>
                  )}
                </>
              )}
            </>
          );
        default:
          return;
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8">
      {order &&
      order.orderType !== "template" &&
      order.orderType !== "booking" ? (
        !editOrder ? (
          <div className="order-details bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold mb-4">Order Details</h2>
            <p className="mb-2">
              <span className="font-semibold">Order id:</span> {order._id}
            </p>
            <p className="mb-2">
              <span className="font-semibold">Status:</span>
              <span className="text-green-600 ml-2">{order.status}</span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Delivery time:</span>
              <span className="ml-2">
                {order.timeframe?.duration} {order.timeframe?.unit}
              </span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Budget:</span>
              <span className="ml-2">
                {order.currency} {order.totalAmount}
              </span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Order Type:</span>
              <span className="ml-2">{order.orderType}</span>
            </p>
            <p className="mb-2">
              <span className="font-semibold">Description:</span>
              <span className="ml-2">{order.description}</span>
            </p>
            <p className="mb-4">
              <span className="font-semibold">Creation Date:</span>
              <span className="ml-2">
                {moment(order.date).format("DD MMM YY @ HH:mm")}
              </span>
            </p>
            <div className="mb-4">
              <p className="font-semibold">Current Step #{order.currentStep}</p>
              <div className="space-y-2">{renderStepButtons()}</div>
            </div>
          </div>
        ) : (
          <EditOrder
            handleEditOrder={() => setEditOrder(!editOrder)}
            order={order}
          />
        )
      ) : (
        <>
          {order &&
          (order.orderType === "template" || order.orderType === "booking") ? (
            <div className="order-details bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-semibold mb-4">Order Details</h2>
              <p className="mb-2">
                <span className="font-semibold">Order id:</span> {order._id}
              </p>
              <p className="mb-2">
                <span className="font-semibold">Status:</span>
                <span className="text-green-600 ml-2">{order.status}</span>
              </p>
              <p className="mb-2">
                <span className="font-semibold">Total Amount:</span>
                <span className="ml-2">
                  {order.currency} {order.totalAmount}
                </span>
              </p>
              <p className="mb-2">
                {order.orderType === "booking" ? (
                  <>
                    <span className="font-semibold">Booking date:</span>
                    <span className="ml-2">{order.slot.formattedDate}</span>
                  </>
                ) : (
                  <>
                    <span className="font-semibold">Creation Date:</span>
                    <span className="ml-2">
                      {moment(order.date).format("DD MMM YY @ HH:mm")}
                    </span>
                  </>
                )}
              </p>
              {order.status === "delivered" ? (
                user.isAdmin ? (
                  <span className="text-green-600 font-medium">
                    Order delivered! Send email with project
                  </span>
                ) : (
                  <span className="text-green-600 font-medium">
                    Order delivered! Check your email to download the file.
                  </span>
                )
              ) : user && user.isAdmin ? (
                <button
                  onClick={async () => {
                    let res = await axios.put(
                      `/api/orders/${order._id}/dev-accepts`
                    );
                    console.log(res);
                    socket.emit("orderUpdate");
                  }}
                  className="bg-green-500 text-white py-2 px-4 rounded hover:bg-[#8e2421] transition-colors duration-300"
                >
                  Mark as Delivered
                </button>
              ) : order.orderType === "booking" ? (
                <span className="text-green-600 font-medium">
                  Check your email for instructions.
                </span>
              ) : (
                <span className="text-green-600 font-medium">
                  Dev is preparing the order
                </span>
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </>
      )}
    </div>
  );
}
