import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MultipleImageUploader from "../MultipleImageUploader/MultipleImageUploader";
import { utils } from "../../js/utils";

const ProductForm = ({
  onSubmit,
  handleUploadImages,
  images,
  colours,
  handleChange,
  state,
  displayPrice,
}) => {
  const { businessId, inventoryItemId } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [img, setImg] = useState();
  const [newImg, setNewImg] = useState(false);
  const [inventoryItem, setInventoryItem] = useState();

  async function uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);
      const res = await axios.post(
        `/api/v1/erp/${businessId}/inventory/upload-img`,
        formData
      );
      return res.data.img;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let uploadedImg;
      const createdItem = await createItem({
        category: state.category,
        totQuantity: state.totQuantity,
        name: state.name,
        price: state.price,
        description: state.description,
        colours: colours,
      });
      let uploadedImages = [];
      console.log(images);
      for (let i = 0; i < images.length; i++) {
        uploadedImg = await uploadImage(images[i]);
        console.log(uploadedImg);
        uploadedImages.push(uploadedImg);
      }
      // uploadedImg = await uploadImage(file);
      // console.log(uploadedImg);
      // Update the product with the image URL
      createdItem.imgs = uploadedImages;
      console.log(createdItem);

      // Update the product on the server
      let res = await updateItem(createdItem);

      console.log(res);
      if (res.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  async function updateItem(item) {
    try {
      const res = await axios.put(
        `/api/v1/erp/${businessId}/inventory/${item._id}`,
        item
      );
      return res;
    } catch (error) {
      console.error("Error updating item:", error);
    }
  }

  async function createItem(item) {
    try {
      const res = await axios.post(
        `/api/v1/erp/${businessId}/inventory`,
        item,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log(res);
      return res.data.newItem;
    } catch (error) {
      console.error("Error creating item:", error);
    }
  }

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImg(file);
      setFile(file);
    }
    // if (e.target.files[0]) {
    //   setNewImg(true);
    //   setFile(e.target.files[0]);
    //   let image = URL.createObjectURL(e.target.files[0]);
    //   setImg(image);
    // }
  };

  const imagePreview = img && (
    <div className="flex justify-around items-center m-2">
      <img
        src={URL.createObjectURL(img)}
        style={{ width: "70px", height: "auto" }}
      />
      <button
        className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
        onClick={() => removeImage(img)}
      >
        Remove
      </button>
    </div>
  );

  const removeImage = () => {
    setImg("");
  };

  return (
    <form onSubmit={onSubmit /*handleSubmit*/} className="flex flex-col">
      {/* <div className="mb-3">
        <label className="font-semibold text-gray-600">Item images</label>
        <div className="flex items-center py-2">
          <label htmlFor="image" className="cursor-pointer">
            <span className="font-extrabold cursor-pointer text-[#ffff] text-sm py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg">
              Choose File
            </span>
            <input
              id="image"
              type="file"
              className="hidden"
              onChange={onFileChange}
            />
          </label>
        </div>
      </div>
      {imagePreview} */}
      <MultipleImageUploader handleUploadImages={handleUploadImages} />
      <input
        id="name"
        placeholder="Name"
        onChange={handleChange}
        className="mb-2 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
        required
      />
      <input
        id="category"
        placeholder="Category"
        onChange={handleChange}
        className="mb-2 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
        required
      />
      <div className="flex flex-row gap-2 items-center mb-2">
        <span className="font-bold px-4">Price: </span>
        <input
          id="price"
          placeholder="0"
          type="number"
          step="any"
          value={state.price}
          onChange={handleChange}
          className="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
          style={{ width: `${state.price ? state.price.length + 7 : 7}ch` }}
          required
        />
        <p>
          <span>
            {utils.normalizeCurrency("GBP")} {displayPrice}
          </span>
        </p>
      </div>
      <input
        id="totQuantity"
        placeholder="Total Quantity"
        type="number"
        onChange={handleChange}
        className="mb-2 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
        required
      />
      <textarea
        id="description"
        placeholder="Description"
        onChange={handleChange}
        className="mb-2 appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
        required
      />
      <div className="flex flex-row gap-4 justify-center mt-4">
        <button
          className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
          type="submit"
        >
          Confirm
        </button>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
        >
          Back
        </button>
      </div>
    </form>
  );
};

export default ProductForm;
