import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

export default function TransactionsPage() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOption, setSortOption] = useState("date");
  const [sortOrder, setSortOrder] = useState("desc");

  const fetchTransactions = async () => {
    try {
      const res = await axios.get(`/api/v1/erp/${businessId}/transactions`);
      if (res.data.transactions) {
        setTransactions(res.data.transactions);
      }
    } catch (error) {
      console.error("Error fetching transactions details:", error);
    }
  };

  const deleteTransaction = async (transaction) => {
    try {
      await axios.delete(
        `/api/v1/erp/${businessId}/transactions/${transaction._id}`
      );
      fetchTransactions();
    } catch (error) {
      console.error("Error deleting transaction:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [businessId]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleSort = (e) => {
    setSortOption(e.target.value);
  };

  const handleSortOrder = (e) => {
    setSortOrder(e.target.value);
  };

  const filteredTransactions = transactions
    .filter((transaction) => {
      return (
        transaction._id.includes(searchTerm) ||
        transaction.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .filter((transaction) => {
      if (statusFilter === "") return true;
      return transaction.status === statusFilter;
    })
    .sort((a, b) => {
      if (sortOption === "amount") {
        return sortOrder === "asc" ? a.amount - b.amount : b.amount - a.amount;
      }
      return sortOrder === "asc"
        ? new Date(a.date) - new Date(b.date)
        : new Date(b.date) - new Date(a.date);
    });

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-2 md:px-4 mb-32 m-8">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg md:p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-[#8e2421]">Transactions</h1>
          <button
            onClick={() => navigate(-1)}
            className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
        </div>

        <div className="flex flex-wrap items-center mb-6 gap-4">
          <input
            type="text"
            placeholder="Search by ID or status"
            value={searchTerm}
            onChange={handleSearch}
            className="w-1/3 p-2 border border-gray-300 rounded-lg"
          />
          <select
            value={statusFilter}
            onChange={handleFilter}
            className="w-1/5 p-2 border border-gray-300 rounded-lg"
          >
            <option value="">All Statuses</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
            <option value="failed">Failed</option>
            <option value="refunded">Refunded</option>
          </select>
          <div className="w-1/3 flex space-x-2">
            <select
              value={sortOption}
              onChange={handleSort}
              className="w-1/2 p-2 border border-gray-300 rounded-lg"
            >
              <option value="date">Sort by Date</option>
              <option value="amount">Sort by Amount</option>
            </select>
            <select
              value={sortOrder}
              onChange={handleSortOrder}
              className="w-1/2 p-2 border border-gray-300 rounded-lg"
            >
              <option value="desc">Descending</option>
              <option value="asc">Ascending</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6">
          {filteredTransactions.length > 0 ? (
            filteredTransactions.map((transaction) => (
              <div
                key={transaction._id}
                className="bg-gray-50 p-4 rounded-lg shadow"
              >
                <div className="flex justify-between items-center mb-2">
                  <p className="text-lg font-semibold text-gray-700">
                    Status: {transaction.status}
                  </p>
                  <div className="flex flex-wrap space-x-2">
                    <button
                      onClick={() => navigate(`${transaction._id}`)}
                      className="bg-blue-500 text-white py-1 px-3 rounded-full font-semibold hover:bg-blue-600 hover:shadow-lg"
                    >
                      Details
                    </button>
                    {/* <button
                      onClick={() => deleteTransaction(transaction)}
                      className="bg-[#8e2421] text-white py-1 px-3 rounded-full font-semibold hover:bg-red-600 hover:shadow-lg"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() =>
                        navigate(`/edit-transaction/${transaction._id}`)
                      }
                      className="bg-green-500 text-white py-1 px-3 rounded-full font-semibold hover:bg-green-600 hover:shadow-lg"
                    >
                      Edit
                    </button> */}
                  </div>
                </div>
                <p className="text-gray-600">
                  Timestamp:{" "}
                  {moment(transaction.date).format("DD-MMM-YYYY @hh:mm")}
                </p>
                <p className="text-gray-600">
                  Transaction ID: {transaction._id}
                </p>
                <p className="text-gray-600">
                  Amount: {transaction.amount} {transaction.currency}
                </p>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No transactions found.</p>
          )}
        </div>
      </div>
    </div>
  );
}
