import { useEffect, useState } from "react";

const { REACT_APP_PUBLIC_URL, NODE_ENV } = process.env;
const PUBLIC_URL =
  NODE_ENV === "production" ? REACT_APP_PUBLIC_URL : "http://localhost:5000/";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="relative max-h-60 p-2">
      <button
        onClick={handlePrev}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
      >
        &lt;
      </button>
      <img
        className="max-h-60"
        src={images[currentIndex] && PUBLIC_URL + images[currentIndex]}
        alt={`carousel-${currentIndex}`}
      />
      <button
        onClick={handleNext}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
      >
        &gt;
      </button>
    </div>
  );
};

export default ImageCarousel;
