import React, { useState } from "react";
import BankAccountsDialog from "./BankAccounts/BankAccountsDialog";
import FixedCostsDialog from "./FixedCosts/FixedCostsDialog";
import FutureCostsDialog from "./FutureCosts/FutureCostsDialog";
import IncomesDialog from "./Incomes/IncomesDialog";
import SelectWorkingDays from "./SelectWorkingDaysModal";
import VariableCostsDialog from "./VariableCosts/VariableCostsDialog";

export default function ExpandedBudget(props) {
  const { budget } = props;
  const [fixedCostsDialog, setFixedCostsDialog] = useState(false);
  const [variableCostsDialog, setVariableCostsDialog] = useState(false);
  const [futureCostsDialog, setFutureCostsDialog] = useState(false);
  const [incomesDialog, setIncomesDialog] = useState(false);
  const [bankAccountsDialog, setBankAccountsDialog] = useState(false);
  const [workingDaysDialog, setWorkingDaysDialog] = useState(false);

  const handleFixedCostsDialog = () => {
    setFixedCostsDialog(!fixedCostsDialog);
  };
  const handleVariableCostsDialog = () => {
    setVariableCostsDialog(!variableCostsDialog);
  };
  const handleFutureCostsDialog = () => {
    setFutureCostsDialog(!futureCostsDialog);
  };
  const handleIncomesDialog = () => {
    setIncomesDialog(!incomesDialog);
  };
  const handleBankAccountsDialog = () => {
    setBankAccountsDialog(!bankAccountsDialog);
  };
  const handleWorkingDaysDialog = () => {
    setWorkingDaysDialog(!workingDaysDialog);
  };
  return (
    <div>
      <FixedCostsDialog
        open={fixedCostsDialog}
        onClose={handleFixedCostsDialog}
        budget={budget}
        updateUser={props.updateUser}
        updateBudget={props.updateBudget}
      />
      <VariableCostsDialog
        open={variableCostsDialog}
        onClose={handleVariableCostsDialog}
        budget={budget}
        updateUser={props.updateUser}
        updateBudget={props.updateBudget}
      />
      <FutureCostsDialog
        open={futureCostsDialog}
        onClose={handleFutureCostsDialog}
        budget={budget}
        updateUser={props.updateUser}
        updateBudget={props.updateBudget}
      />
      <IncomesDialog
        open={incomesDialog}
        onClose={handleIncomesDialog}
        budget={budget}
        updateUser={props.updateUser}
        updateBudget={props.updateBudget}
      />
      <BankAccountsDialog
        open={bankAccountsDialog}
        onClose={handleBankAccountsDialog}
        budget={budget}
        updateUser={props.updateUser}
        updateBudget={props.updateBudget}
      />
      <span className="budget-details-span">
        To earn:
        <span className="budget-details">
          {budget?.to_earn} {budget?.currency}
        </span>
      </span>
      <span className="budget-details-span">
        Spendable:
        <span className="budget-details">
          {budget?.spendable} {budget?.currency}
        </span>
      </span>
      <span className="budget-details-span">
        Working days:
        <span className="budget-details">{budget?.working_days}</span>
      </span>
      <button className="working-days-btn" onClick={handleWorkingDaysDialog}>
        Select working days
      </button>
      <SelectWorkingDays
        open={workingDaysDialog}
        onClose={handleWorkingDaysDialog}
        budget={budget}
        updateUser={props.updateUser}
      />
      <span className="budget-span">
        Fixed costs:
        <span className="cost-amount">
          {budget?.tot_fixed_costs} {budget?.currency}
        </span>
        <button onClick={handleFixedCostsDialog}>show</button>
      </span>
      <span className="budget-span">
        Variable costs:
        <span className="cost-amount">
          {budget?.tot_variable_costs} {budget?.currency}
        </span>
        <button onClick={handleVariableCostsDialog}>show</button>
      </span>
      <span className="budget-span">
        Future costs:
        <span className="cost-amount">
          {budget?.tot_future_costs} {budget?.currency}
        </span>
        <button onClick={handleFutureCostsDialog}>show</button>
      </span>
      <span className="budget-span">
        Incomes:
        <span className="cost-amount">
          {budget?.tot_incomes} {budget?.currency}
        </span>
        <button onClick={handleIncomesDialog}>show</button>
      </span>
      <span className="budget-span">
        Bank accounts:
        <span className="cost-amount">
          {budget?.tot_bank_accounts} {budget?.currency}
        </span>
        <button onClick={handleBankAccountsDialog}>show</button>
      </span>
    </div>
  );
}
