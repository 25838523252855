import React, { useState } from "react";
import axios from "axios";

const { REACT_APP_PUBLIC_URL, NODE_ENV } = process.env;
const PUBLIC_URL =
  NODE_ENV === "production" ? REACT_APP_PUBLIC_URL : "http://localhost:5000/";

const MultipleImageUploader = ({ handleUploadImages }) => {
  const [images, setImages] = useState([]);
  const columns = 3;

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const newImages = [...images, selectedFile];
      console.log(newImages);
      setImages(newImages);
      handleUploadImages(newImages);
    }
  };

  const removeImage = (index) => {
    let prevImgs = images;
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    let newImgs = prevImgs.filter((_, i) => i !== index);
    console.log(newImgs);
    handleUploadImages(newImgs);
  };

  const renderChooseFileButton = () => (
    <label
      htmlFor="image-upload"
      className="flex justify-center items-center cursor-pointer shadow-lg rounded-lg"
      style={{
        width: "120px",
        height: "120px",
        border: "1px dashed #ccc",
        minHeight: "120px",
      }}
    >
      <input
        id="image-upload"
        type="file"
        // className="hidden"
        className="cursor-pointer w-full m-2"
        onChange={handleFileChange}
        required={images.length === 0}
      />
    </label>
  );

  return (
    <div className="flex  items-center mb-4">
      <div className="grid grid-cols-3 gap-4 items-center">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={URL.createObjectURL(image)}
              className="w-40 h-40 rounded-lg object-contain"
              alt={`upload-${index}`}
            />
            <button
              className="absolute bottom-2 left-1/2 transform -translate-x-1/2 font-extrabold cursor-pointer text-[#ffff] text-sm py-1 px-2 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              onClick={() => removeImage(index)}
              type="button"
            >
              Remove
            </button>
          </div>
        ))}
        {images.length === 0 && (
          <div className="mt-4">{renderChooseFileButton()}</div>
        )}
        {images.length % columns != 0 && (
          <div className="mt-4">{renderChooseFileButton()}</div>
        )}
      </div>
      {images.length > 0 && images.length % columns === 0 && (
        <div className="mt-4">{renderChooseFileButton()}</div>
      )}
    </div>
  );
};

export default MultipleImageUploader;
