import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PrintableArea from "../../PrintableArea/PrintableArea";
import ProductForm from "../../../Components/ProductForm/ProductForm";
import CustomizableProductForm from "../../../Components/CustomizableProductForm/CustomizableProductForm";
import MultipleImageUploader from "../../../Components/MultipleImageUploader/MultipleImageUploader";
import ColourSelector from "../../../Components/ColourSelector/ColourSelector";
import SizeSelector from "../../../Components/SizeSelector/SizeSelector";
import { utils } from "../../../js/utils";

export default function CreateNewItem() {
  const { businessId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({});
  const [file, setFile] = useState();
  const [img, setImg] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const [newImg, setNewImg] = useState(false);
  const [customizable, setCustomizable] = useState(false);
  const [step, setStep] = useState(2);
  const [ready, setReady] = useState(false);
  const [openDesignArea, setOpenDesignArea] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState({
    front: false,
    back: false,
    right: false,
    left: false,
  });
  const [customItems, setCustomItems] = useState([
    {
      colour: "None",
      sizes: [{ size: "None", quantity: 0 }],
      images: [],
      customImages: [],
    },
  ]);
  const [images, setImages] = useState([]);
  const [selectedSize, setSelectedSize] = useState("");

  const [selectedColour, setSelectedColour] = useState("None");
  const [sizes, setSizes] = useState([]);
  const [selectedCustomImages, setSelectedCustomImages] = useState([]);
  const [displayPrice, setDisplayPrice] = useState("");

  useEffect(() => {
    const colourObject = customItems.find(
      (colourObj) => colourObj.colour === selectedColour
    );
    console.log(colourObject);
    setSizes(colourObject ? colourObject.sizes : []);
    setSelectedCustomImages(
      colourObject && colourObject.customImages ? colourObject.customImages : []
    );
  }, [selectedColour, selectedSize, customItems]);

  const handleCustomizableChange = (isCustomizable) => {
    setCustomizable(isCustomizable);
  };

  const handleImageUpload = (side, newImage) => {
    setImagesUploaded({ ...imagesUploaded, [side]: true });

    console.log(side, newImage);
    console.log(customItems);

    const newImageDetails = {
      backgroundImg: newImage,
      saved: false,
      designArea: null,
      percCoords: null,
      side: side,
    };

    let customImg = {
      backgroundImg: newImage,
      name: side,
      url: "",
      style: {
        front: { top: "", left: "", width: "", height: "" },
        back: { top: "", left: "", width: "", height: "" },
        right: { top: "", left: "", width: "", height: "" },
        left: { top: "", left: "", width: "", height: "" },
      },
    };

    setCustomItems((prevItems) => {
      return prevItems.map((prevItem) => {
        if (prevItem.colour === selectedColour) {
          if (!prevItem.customImages || prevItem.customImages.length === 0) {
            // If customImages is empty, add the new image
            return {
              ...prevItem,
              customImages: [customImg],
            };
          } else {
            // Otherwise, update the existing images
            const updatedImages = prevItem.customImages.map((imageSet) => {
              if (imageSet.name === side) {
                return { ...imageSet, ...customImg };
              }
              return imageSet;
            });

            // Check if the image with the matching side was found and updated
            const imageUpdated = updatedImages.some(
              (image) => image.name === side
            );

            // If no matching image was found, add the new image
            if (!imageUpdated) {
              updatedImages.push(customImg);
            }

            return {
              ...prevItem,
              customImages: updatedImages,
            };
          }
        }
        return prevItem;
      });
    });
  };

  const handleImageSave = (side) => {
    console.log(`Customizable area for ${side} saved`);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });

    if (e.target.id === "price") {
      const formattedPrice = (Number(e.target.value) / 100).toFixed(2);
      setDisplayPrice(formattedPrice);
    }
  };

  const onFileChange = (event) => {
    if (event.target.files[0]) {
      setNewImg(true);
      setFile(event.target.files[0]);
      let image = URL.createObjectURL(event.target.files[0]);
      setImg(image);
    }
  };

  const handleSetDesignArea = () => {
    setOpenDesignArea(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(selectedColour);
    console.log(selectedSize);
    console.log(customItems);
    let totQuantity = 0;
    customItems.forEach((item) =>
      item.sizes.forEach((size) => (totQuantity += size.quantity))
    );
    console.log(totQuantity);

    try {
      let uploadedImg;
      const createdItem = await createItem({
        category: state.category,
        totQuantity: totQuantity, // Updated to use total quantity from state
        name: state.name,
        price: state.price,
        description: state.description,
      });
      console.log(createdItem);
      if (customizable) {
        let customImages = [],
          customImg = {
            name: "",
            designArea: {
              front: { top: "", left: "", width: "", height: "" },
              back: { top: "", left: "", width: "", height: "" },
              right: { top: "", left: "", width: "", height: "" },
              left: { top: "", left: "", width: "", height: "" },
            },
          };
        let customItem = {
          customImages: [],
        };
        // Loop customItems
        for (let i = 0; i < customItems.length; i++) {
          customItem = {
            colour: customItems[i].colour,
            sizes: customItems[i].sizes,
            customImages: [],
          };
          // Loop customItems[i].customImages
          for (let j = 0; j < customItems[i].customImages.length; j++) {
            // Upload images
            uploadedImg = await uploadImage(
              customItems[i].customImages[j].backgroundImg
            );
            console.log(uploadedImg);

            customImg.url = uploadedImg;

            const side = customItems[i].customImages[j].name;
            const designArea = customItems[i].customImages[j].percCoords;
            console.log(side);
            console.log(designArea);
            console.log(customImg);

            customImg.name = side;
            customImg.designArea[side] = {
              top: designArea.top.toString(),
              left: designArea.left.toString(),
              width: designArea.width.toString(),
              height: designArea.height.toString(),
            };
            console.log(customImg);
            console.log(JSON.parse(JSON.stringify(customImg)));

            customImages.push(JSON.parse(JSON.stringify(customImg)));
          }
          customItem.customImages = customImages;
          console.log(customImages);
          console.log(customItem);
          createdItem.customItems.push(customItem);
        }
        console.log(createdItem);

        let uploadedImages = [];
        for (let i = 0; i < images.length; i++) {
          uploadedImg = await uploadImage(images[i]);
          uploadedImages.push(uploadedImg);
        }
        createdItem.imgs = uploadedImages;

        let res = await updateItem(createdItem);
        if (res.status === 200) {
          navigate(-1);
        }
      } else {
        // let uploadedImages = [];
        // console.log(images);
        // for (let i = 0; i < images.length; i++) {
        //   uploadedImg = await uploadImage(images[i]);
        //   console.log(uploadedImg);
        //   uploadedImages.push(uploadedImg);
        // }
        // // uploadedImg = await uploadImage(file);
        // // console.log(uploadedImg);
        // // Update the product with the image URL
        // createdItem.imgs = uploadedImages;
        // console.log(createdItem);

        // // Update the product on the server
        // let res = await updateItem(createdItem);

        // console.log(res);
        // if (res.status === 200) {
        //   navigate(-1);
        // }
        let uploadedImages = [];
        for (let i = 0; i < images.length; i++) {
          uploadedImg = await uploadImage(images[i]);
          uploadedImages.push(uploadedImg);
        }
        createdItem.imgs = uploadedImages;

        let res = await updateItem(createdItem);
        if (res.status === 200) {
          navigate("/inventory-items");
        }
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleUploadImages = (imgs) => {
    setImages(imgs);
  };

  async function uploadImage(file) {
    try {
      const formData = new FormData();
      formData.append("file", file, file.name);

      const res = await axios.post(
        `/api/v1/erp/${businessId}/inventory/upload-img`,
        formData,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return res.data.img;
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  async function createItem(item) {
    try {
      const res = await axios.post(
        `/api/v1/erp/${businessId}/inventory`,
        item,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return res.data.newItem;
    } catch (error) {
      console.error("Error creating item:", error);
    }
  }

  async function updateItem(item) {
    try {
      const res = await axios.put(
        `/api/v1/erp/${businessId}/inventory/${item._id}`,
        item,
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      return res;
    } catch (error) {
      console.error("Error updating item:", error);
    }
  }

  const handleDesignAreaSet = (customItem) => {
    console.log(customItem);
    console.log(customItems);

    const {
      backgroundImg,
      designArea,
      percCoords,
      saved,
      selectedColour,
      side,
    } = customItem;

    setCustomItems((prevItems) => {
      return prevItems.map((prevItem) => {
        if (prevItem.colour === selectedColour) {
          if (prevItem.customImages.length === 0) {
            throw new Error("customImages is empty");
          } else {
            // Update the existing images
            const updatedImages = prevItem.customImages.map((imageSet) => {
              if (imageSet.name === side) {
                return { ...imageSet, designArea, percCoords };
              }
              return imageSet;
            });

            return {
              ...prevItem,
              customImages: updatedImages,
            };
          }
        }
        return prevItem;
      });
    });
  };

  const handleColourSelected = (colour) => {
    setSelectedColour(colour);
    console.log(`Colour selected: ${colour}`);
    console.log(customItems);
    const colourObject = customItems.find(
      (colourObj) => colourObj.colour === colour
    );
    console.log(colourObject);
    setSizes(colourObject ? colourObject.sizes : []);
    setSelectedCustomImages(
      colourObject && colourObject.customImages ? colourObject.customImages : []
    );
  };

  const handleSizeSelected = (size) => {
    setSelectedSize(size);
    console.log(`Size selected: ${size}`);
  };

  if (!customizable) {
    return (
      <div className="min-h-screen">
        <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
          <h1 className="text-[#8e2421] font-extrabold text-xl md:text-3xl mb-4">
            Create New Item
          </h1>
          <div className="justify-center flex items-center mt-4 mb-4">
            <button
              className="bg-[#8e2421] hover:bg-[#8a4e4b] text-white font-bold py-2 px-4 rounded"
              onClick={() => handleCustomizableChange(true)}
            >
              Make it Customizable
            </button>
          </div>
          <ProductForm
            onSubmit={handleSubmit}
            handleUploadImages={handleUploadImages}
            selectedColour={selectedColour}
            selectedSize={selectedSize}
            images={images}
            customItems={customItems}
            handleChange={handleChange}
            state={state}
            displayPrice={displayPrice}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="min-h-screen mb-16">
        <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
          <h1 className="text-[#8e2421] font-extrabold text-xl md:text-3xl mb-4">
            Create Customizable Item
          </h1>
          <div className="flex flex-col items-center mb-4">
            <button
              onClick={() => handleCustomizableChange(false)}
              className="bg-[#8e2421] hover:bg-[#8a4e4b] text-white font-bold py-2 px-6 rounded-md shadow-md transition duration-200 ease-in-out transform hover:scale-105"
            >
              Make it Not Customizable
            </button>
            <span className="text-gray-600 font-medium flex flex-row gap-1">
              <strong className="block text-gray-800">Note:</strong>
              visit{" "}
              <a
                href="https://www.remove.bg/"
                target="_blank"
                className="text-blue-600 hover:text-blue-800 underline transition-colors duration-200"
              >
                this website
              </a>{" "}
              to remove background for free.
            </span>
          </div>

          <ColourSelector
            handleColourSelected={handleColourSelected}
            customItems={customItems}
            setCustomItems={setCustomItems}
          />
          <SizeSelector
            handleSizeSelected={handleSizeSelected}
            customItems={customItems}
            selectedColour={selectedColour}
            setCustomItems={setCustomItems}
            sizes={sizes}
          />
          <form
            onSubmit={handleSubmit}
            className="flex flex-col space-y-2 mt-4"
          >
            <CustomizableProductForm
              side="front"
              onImageUpload={handleImageUpload}
              onDesignAreaSet={handleDesignAreaSet}
              customItems={customItems}
              selectedCustomImage={selectedCustomImages.find(
                (img) => img.name === "front"
              )}
              selectedColour={selectedColour}
            />
            <CustomizableProductForm
              side="back"
              onImageUpload={handleImageUpload}
              onDesignAreaSet={handleDesignAreaSet}
              customItems={customItems}
              selectedCustomImage={selectedCustomImages.find(
                (img) => img.name === "back"
              )}
              selectedColour={selectedColour}
            />
            <CustomizableProductForm
              side="right"
              onImageUpload={handleImageUpload}
              onDesignAreaSet={handleDesignAreaSet}
              customItems={customItems}
              selectedCustomImage={selectedCustomImages.find(
                (img) => img.name === "right"
              )}
              selectedColour={selectedColour}
            />
            <CustomizableProductForm
              side="left"
              onImageUpload={handleImageUpload}
              onDesignAreaSet={handleDesignAreaSet}
              customItems={customItems}
              selectedCustomImage={selectedCustomImages.find(
                (img) => img.name === "left"
              )}
              selectedColour={selectedColour}
            />
            <MultipleImageUploader handleUploadImages={handleUploadImages} />
            <input
              id="name"
              placeholder="Name"
              onChange={handleChange}
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              required
            />
            <input
              id="category"
              placeholder="Category"
              onChange={handleChange}
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              required
            />
            <div className="flex flex-row gap-2 items-center mb-2">
              <span className="font-bold px-4">Price: </span>
              <input
                id="price"
                placeholder="0"
                type="number"
                step="any"
                value={state.price}
                onChange={handleChange}
                className="appearance-none block bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
                style={{
                  width: `${state.price ? state.price.length + 7 : 7}ch`,
                }}
                required
              />
              <p>
                <span className="font-bold px-4">Display price: </span>
                <span>
                  {utils.normalizeCurrency("GBP")} {displayPrice}
                </span>
              </p>
            </div>
            <span className="px-2 text-gray-600 font-medium flex flex-row gap-1">
              <strong className="block text-gray-800">Note:</strong>
              please enter the price as if it was multiplied by 100 (£20.99 =
              2099)
            </span>
            <textarea
              id="description"
              placeholder="Description"
              onChange={handleChange}
              className="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded-lg h-10 px-4"
              required
            />
            <div className="">
              <button
                className="font-extrabold cursor-pointer text-[#ffff] py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
                type="submit"
              >
                Confirm
              </button>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="font-extrabold cursor-pointer text-[#ffff]  py-2 px-4 rounded-full bg-[#8e2421] hover:bg-[#8b4341] hover:shadow-lg"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
