import React, { useRef, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import {
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiFillHome,
  AiFillCalculator,
  AiOutlineShop,
  AiOutlineShoppingCart,
  AiOutlineLogout,
} from "react-icons/ai";
import { MdOutlineDashboardCustomize, MdAccountCircle } from "react-icons/md";
import { FaInfoCircle, FaShoppingCart } from "react-icons/fa";
import { IoIosArrowDropleft } from "react-icons/io";
import { BiSolidBusiness } from "react-icons/bi";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useCart } from "../../contexts/CartContext";
import { useUser } from "../../contexts/UserContext";
import Logo from "../Header/Logo";
import logo from "../../assets/ws-logo3.png";

export default function MenuDrawer({ isOpen, handleMenuDrawer }) {
  const { cart } = useCart();
  const { signOut } = useUser();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const Menus = [
    { title: "Home", src: "/", icon: <AiFillHome className="h-8 mx-2" /> },
    {
      title: "Profile",
      src: "/profile",
      icon: <MdAccountCircle className="h-8 mx-2" />,
    },
    {
      title: "Business",
      src: "/business",
      icon: <BiSolidBusiness className="h-8 mx-2" />,
    },
    {
      title: "Cart",
      src: "/cart",
      icon: <CartIcon cart={cart} />,
    },
    {
      title: "About Us",
      src: "/about-us",
      icon: <FaInfoCircle className="h-8 mx-2" />,
    },
  ];

  return (
    <div className="flex fixed top-0 z-10">
      <div
        className={` ${
          open ? "w-32" : "w-16"
        } bg-dark-purple h-screen p-5 relative duration-300 bg-[#e6e4e4]`}
      >
        <IoIosArrowDropleft
          className={`absolute cursor-pointer -right-3 top-9 w-7 h-7 border-[#e6e4e4]
           border-2 rounded-full  ${!open && "rotate-180"}`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex mb-2 items-center">
          <button onClick={() => navigate("/")}>
            {open ? (
              <img
                className={`cursor-pointer duration-500 h-8 ${
                  open && "rotate-[360deg]"
                }`}
                src={require("../../assets/ws-full-logo.png")}
              />
            ) : (
              <img
                src={logo}
                className={`cursor-pointer duration-500 h-8 ${
                  open && "rotate-[360deg]"
                }`}
              />
            )}
          </button>
        </div>
        <ul className="">
          {Menus.map((Menu, index) => (
            <li
              key={index}
              className="flex rounded-md cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4
            bg-light-white"
            >
              <NavLink
                to={Menu.src}
                className={({ isActive }) =>
                  isActive
                    ? "flex items-center text-white bg-[#8e2421] rounded-lg"
                    : "flex items-center text-black"
                }
              >
                <div className="group flex relative">
                  {Menu.icon}
                  {!open && (
                    <span className="group-hover:opacity-100 transition-opacity text-white bg-[#8e2421] px-1 text-sm rounded-md absolute -translate-y-1/2 translate-x-4 opacity-0 m-4">
                      {Menu.title}
                    </span>
                  )}
                </div>
                <span
                  className={`${
                    !open && "hidden"
                  } origin-left duration-200 mr-2`}
                >
                  {Menu.title}
                </span>
              </NavLink>
            </li>
          ))}
          <li
            className="flex rounded-md cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4
            bg-light-white"
          >
            <button
              onClick={signOut}
              className={"flex items-center text-black"}
            >
              <div className="group flex relative">
                <AiOutlineLogout className="h-8 mx-2" />
                {!open && (
                  <span className="group-hover:opacity-100 transition-opacity text-white bg-[#8e2421] px-1 text-sm rounded-md absolute -translate-y-1/2 translate-x-4 opacity-0 m-4">
                    Logout
                  </span>
                )}
              </div>
              <span
                className={`${!open && "hidden"} origin-left duration-200 mr-2`}
              >
                Logout
              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="fixed top-0">
      {/* Menu Drawer when closed */}
      {!isOpen && (
        <div className="bg-[#e6e4e4] w-16 h-screen">
          <button onClick={handleMenuDrawer} className="text-black m-4">
            <AiOutlineMenuUnfold className="h-8 w-8" />
          </button>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "flex items-center mx-4 text-white bg-[#8e2421] rounded-lg"
                : "flex items-center mx-4 text-black"
            }
          >
            <AiFillHome className="h-8 w-8 mx-1" />
          </NavLink>
          <NavLink
            to="/profile"
            className={({ isActive }) =>
              isActive
                ? "flex items-center mx-4 text-white bg-[#8e2421] rounded-lg"
                : "flex items-center mx-4 text-black"
            }
          >
            <MdAccountCircle className="h-8 w-8 mx-1" />
          </NavLink>
          <NavLink
            to="/business"
            className={({ isActive }) =>
              isActive
                ? "flex items-center mx-4 text-white bg-[#8e2421] rounded-lg"
                : "flex items-center mx-4 text-black"
            }
          >
            <BiSolidBusiness className="h-8 w-8 mx-1" />
          </NavLink>
          <button onClick={signOut} className="flex mx-4 text-black">
            <AiOutlineLogout className="h-8 w-8 mx-1" />
          </button>
        </div>
      )}

      {/* Menu Drawer when open */}
      {isOpen && (
        <div className="bg-gray-800 w-64 h-screen flex flex-col">
          <div className="right-0 p-4">
            <button onClick={handleMenuDrawer} className="text-black">
              <AiOutlineMenuFold className="h-8 w-8" />
            </button>
          </div>
          <div className="p-4">
            {/* Your menu items go here */}
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center mb-4 text-black bg-[#8e2421]"
                  : "flex items-center mb-4 text-white"
              }
            >
              <AiFillHome className="mr-2" />
              <span className="text-lg font-bold">Home</span>
            </NavLink>
            <a href="#" className="text-white block py-2">
              Menu Item 2
            </a>
            <a href="#" className="text-white block py-2">
              Menu Item 3
            </a>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`${
        isOpen ? "block" : "hidden"
      } bg-gray-800 text-white w-64 h-full overflow-y-auto fixed top-0 left-0 z-50`}
    >
      <div className="p-4">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "flex items-center mb-4 text-white bg-[#8e2421]"
              : "flex items-center mb-4 text-white"
          }
        >
          <AiFillHome className="mr-2" />
          <span className="text-lg font-bold">Home</span>
        </NavLink>
        <button
          onClick={handleMenuDrawer}
          className="close-menu-icon text-white"
        >
          <AiOutlineMenuFold className="close-menu-icon" />
        </button>
      </div>
      <div className="border-t border-gray-700">
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            isActive
              ? "flex items-center text-white bg-[#8e2421]"
              : "flex items-center text-white"
          }
        >
          <MdAccountCircle className="mr-2" />
          <span>Profile</span>
        </NavLink>
        <NavLink
          to="/business"
          activeClassName="active"
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <BiSolidBusiness className="mr-2" />
          <span>Business</span>
        </NavLink>
        <NavLink
          to="/accounting"
          activeClassName="active"
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <AiFillCalculator className="mr-2" />
          <span>Accounting</span>
        </NavLink>
        <NavLink
          to="/marketplace"
          activeClassName="active"
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <AiOutlineShop className="mr-2" />
          <span>Marketplace</span>
        </NavLink>
        <NavLink
          to="/cart"
          activeClassName="active"
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <AiOutlineShoppingCart className="mr-2" />
          <span>Cart</span>
          <CartIcon cart={cart} />
        </NavLink>
        <NavLink
          to="/about-us"
          exact
          activeClassName="active"
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <BsFillInfoCircleFill className="mr-2" />
          <span>About Us</span>
        </NavLink>
        <button
          onClick={signOut}
          className="menu-drawer-row flex items-center py-2 px-4"
        >
          <AiOutlineLogout className="mr-2" />
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
}

const CartIcon = ({ cart }) => {
  const itemCount = cart.reduce((total, item) => total + item.quantity, 0);
  return (
    <div className="relative">
      <FaShoppingCart className="h-8 mx-2" />
      {itemCount > 0 && (
        <span className="absolute -top-2 right-4 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
          {itemCount}
        </span>
      )}
    </div>
  );
};
