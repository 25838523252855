import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";

export default function TransactionDetails() {
  const { businessId, transactionId } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState();

  const fetchTransaction = async () => {
    try {
      const res = await axios.get(
        `/api/v1/erp/${businessId}/transactions/${transactionId}`
      );
      console.log(res.data);
      if (res.data.transaction) {
        setTransaction(res.data.transaction);
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  useEffect(() => {
    fetchTransaction();
  }, [transactionId]);

  return (
    <div className="bg-gray-100 py-8 px-4 mt-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-[#8e2421]">
            Transaction Details
          </h1>
          <button
            onClick={() => navigate(-1)}
            className="bg-[#8e2421] text-white py-2 px-4 rounded-full font-bold hover:bg-[#8b4341] hover:shadow-lg"
          >
            Back
          </button>
        </div>
        {transaction ? (
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Transaction Information
            </h2>
            <p className="mb-2">
              <strong>Transaction ID:</strong> {transaction._id}
            </p>
            <p className="mb-2">
              <strong>Status:</strong>
              <span className="text-green-600">{transaction.status}</span>
            </p>
            <p className="mb-2">
              <strong>Type:</strong> {transaction.type}
            </p>
            <p className="mb-2">
              <strong>Amount:</strong> {transaction.amount}
            </p>
            <p className="mb-2">
              <strong>Client ID:</strong> {transaction.clientId}
            </p>
            {transaction.business && (
              <p className="mb-2">
                <strong>Business ID:</strong> {transaction.business}
              </p>
            )}
            {transaction.saleId && (
              <p className="mb-2">
                <strong>Sale ID:</strong> {transaction.saleId}
              </p>
            )}
            <p className="mb-2">
              <strong>Creation Date:</strong>{" "}
              {moment(transaction.createdAt).format("DD MMM YY @ HH:mm")}
            </p>
          </div>
        ) : (
          <p className="text-center text-gray-500">
            Loading transaction details...
          </p>
        )}
      </div>
    </div>
  );
}
