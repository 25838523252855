import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

const SOCKET_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_SOCKET_URL
    : "http://localhost:5000";

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Replace 'YOUR_SOCKET_SERVER_URL' with the URL of your Socket.IO server
    const socket = io(SOCKET_URL);
    socket.on("connection", () => console.log("Connected to server"));
    setSocket(socket);

    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);
