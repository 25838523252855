import React from "react";

export default function EditSale() {
  return (
    <div className="content">
      <div className="wrap-card">
        <h1>EditSale</h1>
      </div>
    </div>
  );
}
