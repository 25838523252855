import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import video from "../../assets/print-shop-showcase.mp4";
import shopVideo from "../../assets/ecommerce/hardwareshop.mp4";
import dappVideo from "../../assets/dapp/dapp-demo-gig.mp4";
import createBusinessAccount from "../../assets/create-business-tutorial.mp4";
import TypingSimulator from "../TypingSimulator/TypingSimulator";
import axios from "axios";

const { NODE_ENV, REACT_APP_API_URL } = process.env;

const API_URL =
  NODE_ENV === "production"
    ? REACT_APP_API_URL
    : "http://localhost:5000/api/v1/";

const buttonClass =
  "bg-[#8e2421] text-white py-2 px-4 mx-4 rounded-md font-extrabold hover:bg-[#8b3a38] transition text-center";

const Home = () => {
  const navigate = useNavigate();

  const [metrics, setMetrics] = useState({
    apiIntegrations: 0,
    apiCallsPerSecond: 0,
    apiUsage: 0,
    openSourceProjects: 0,
    satisfiedClients: 0,
    users: 0,
    businesses: 0,
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await axios.get(`${API_URL}metrics`);
        const { metrics } = response.data;
        if (metrics) {
          setMetrics(metrics);
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    };

    fetchMetrics();
  }, []);

  return (
    <div className="min-h-screen mb-20 mt-5">
      <div className="md:px-4 md:py-8 pr-4">
        <div className="bg-white rounded-lg shadow-md overflow-hidden p-6 mb-8">
          <div className="text-center mb-6">
            <TypingSimulator
              strings={["...where everything begins"]}
              typeSpeed={100}
              backSpeed={50}
            />
          </div>
          <div className="text-center mb-8">
            <h1 className="text-4xl font-bold mb-4">Welcome!</h1>
            <p className="text-xl">
              We are a software company trying to make the world a better place
              with open-sourced solutions.
            </p>
          </div>
          <div className="flex flex-wrap justify-center gap-2">
            <Link to="/marketplace" className={buttonClass}>
              Our Solutions
            </Link>
            <Link to="/contact-us" className={buttonClass}>
              Contact Us
            </Link>
            <Link to="/book-consultancy" className={buttonClass}>
              Book a Consultancy
            </Link>
            <Link to="/custom-order" className={buttonClass}>
              Custom Order
            </Link>
            <Link to="/sign-up" className={buttonClass}>
              Register For Free
            </Link>
            <Link to="/about-us" className={buttonClass}>
              About Us
            </Link>
            {/* <Link to="/blog" className={buttonClass}>
              Blog
            </Link> */}
            {/* <Link to="/api-docs" className={buttonClass}>
              API Docs
            </Link> */}
          </div>
        </div>

        <section className="bg-gray-100 p-2 md:p-6 rounded-lg mb-10">
          <h1 className="text-center text-2xl font-bold mb-4">Showcase</h1>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 ">
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <video className="h-48 object-cover" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-4">
                <h3 className="text-xl font-semibold">Print shop</h3>
                <p className="text-gray-700">
                  This is a video to show how to make a business account for a
                  print shop business.
                </p>
                <Link
                  to="https://your-print-shop.netlify.app/"
                  className="text-[#8e2421] underline hover:text-[#6b1f1a] transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Print Shop Website
                </Link>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <video className="h-48 object-cover" controls>
                <source src={shopVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-4">
                <h3 className="text-xl font-semibold">E-commerce</h3>
                <p className="text-gray-700">
                  This is a video to show how to make a business account for an
                  e-commerce.
                </p>
                <Link
                  to="https://dapp-demo1.netlify.app/"
                  className="text-[#8e2421] underline hover:text-[#6b1f1a] transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Standard E-commerce Website
                </Link>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <video className="h-48 object-cover" controls>
                <source src={dappVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-4">
                <h3 className="text-xl font-semibold">Dapp</h3>
                <p className="text-gray-700">
                  This is a video to show how to make a business account for a
                  dApp.
                </p>
                <Link
                  to="https://dapp-demo1.netlify.app/"
                  className="text-[#8e2421] underline hover:text-[#6b1f1a] transition-colors"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dapp Website
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 p-2 md:p-6 rounded-lg mb-10">
          <h1 className="text-center text-2xl font-bold mb-4">Tutorial</h1>
          <div className="flex justify-center">
            <div className="bg-white rounded-lg shadow-md overflow-hidden max-w-sm">
              <video className="w-full h-auto" controls>
                <source src={createBusinessAccount} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="p-4">
                <h3 className="text-xl font-semibold">
                  How to create a business
                </h3>
                <p className="text-gray-700">
                  This is a video to show how to create a business account for a
                  print shop business.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold mb-4 text-center">
            API and Software Numbers
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.apiIntegrations}
              </h3>
              <p className="text-gray-700">API Integrations</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.openSourceProjects}
              </h3>
              <p className="text-gray-700">Open Source Projects</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.satisfiedClients}
              </h3>
              <p className="text-gray-700">Satisfied Clients</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.users}
              </h3>
              <p className="text-gray-700">Users</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.businesses}
              </h3>
              <p className="text-gray-700">Businesses</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.apiCallsPerSecond}
              </h3>
              <p className="text-gray-700">API Calls/Second</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-4">
              <h3 className="text-xl font-semibold text-[#8e2421]">
                {metrics.apiUsage}
              </h3>
              <p className="text-gray-700">API Usage</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
