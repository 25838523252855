import React, { useState } from "react";
import { Dialog } from "@mui/material";
import AddFutureCostDialog from "./AddFutureCostDialog";
import {
  addVariableCost,
  deleteFutureCost,
} from "../../../../apiCalls/accountingCalls";
import EditFutureCost from "./EditFutureCost";

export default function FutureCostsDialog(props) {
  const { budget } = props;

  const [addFutureCostDialog, setAddFutureCostDialog] = useState(false);
  const [editFutureCostDialog, setEditFutureCostDialog] = useState(false);
  const [futureCostSelected, setFutureCostSelected] = useState(false);

  const handleEditFutureCostDialog = (cost) => {
    if (cost) {
      setFutureCostSelected(cost);
    }
    setEditFutureCostDialog(!editFutureCostDialog);
  };

  const handleAddFutureCostDialog = () => {
    setAddFutureCostDialog(!addFutureCostDialog);
  };
  return (
    <div>
      <AddFutureCostDialog
        open={addFutureCostDialog}
        onClose={handleAddFutureCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        updateBudget={props.updateBudget}
      />
      <EditFutureCost
        open={editFutureCostDialog}
        onClose={handleEditFutureCostDialog}
        updateUser={props.updateUser}
        budget={budget}
        futureCost={futureCostSelected}
        updateBudget={props.updateBudget}
      />
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <span className="table-title">
          <h1>
            Future costs {budget?.tot_future_costs} {budget?.currency}
          </h1>
          <button onClick={handleAddFutureCostDialog}>add</button>
        </span>
        {budget?.future_costs?.map((cost) => (
          <>
            <span className="table-name">
              {cost?.name}
              {cost?.is_passed ? (
                <button
                  onClick={async () => {
                    console.log(cost);
                    await addVariableCost(budget._id, cost);
                    await deleteFutureCost(budget._id, cost._id);
                    props.updateUser();
                    props.updateBudget();
                  }}
                >
                  accept
                </button>
              ) : null}
              <button
                onClick={async () => {
                  await deleteFutureCost(budget._id, cost._id);
                  props.updateUser();
                  props.updateBudget();
                }}
              >
                delete
              </button>
              <button onClick={() => handleEditFutureCostDialog(cost)}>
                edit
              </button>
            </span>

            <span className="table-sub-name">
              Amount:
              <span className="table-props-text">
                {cost?.amount} {cost?.currency}
              </span>
            </span>
            <span className="table-sub-name">
              Date:
              <span className="table-props-text">{cost?.formatted_date}</span>
            </span>
            {/* <span className="table-sub-name">
              To: <span className="table-props-text">{cost?.to}</span>
            </span> */}
            <span className="table-sub-name">
              From: <span className="table-props-text">{cost?.from?.name}</span>
            </span>
            {/* <span className="table-sub-name">
              Notes: <span className="table-props-text">{cost?.notes}</span>
            </span> */}
            <br />
          </>
        ))}
      </Dialog>
    </div>
  );
}
