import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";

const { REACT_APP_PUBLIC_URL } = process.env;

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/"
    : REACT_APP_PUBLIC_URL;

export default function BusinessesPage() {
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState();

  const getUserBusinesses = async () => {
    let res = await axios.get("/api/v1/erp/business");
    console.log(res.data);
    if (res.data.businesses) {
      setBusinesses(res.data.businesses);
    }
  };

  useEffect(() => {
    // updateUser();
    getUserBusinesses();
  }, []);
  return (
    <div className="flex flex-col items-center min-h-screen">
      <div className="bg-white rounded-md shadow-md p-4 mr-4 mt-8 mb-8">
        <div>
          <button
            onClick={() => navigate("/create-business")}
            className="bg-[#8e2421] text-white py-1 px-2 mx-4 rounded-md font-extrabold"
          >
            create a new business
          </button>
        </div>
        <div className="mt-4">
          <h1 className="font-extrabold text-xl md:text-3xl">
            List of Businesses
          </h1>
          <div className="mt-4">
            {businesses?.map((business) => (
              <div className="flex flex-wrap items-center gap-4 mb-4">
                <img src={PUBLIC_URL + business.logo} className="h-12" />
                <span className="font-extrabold md:text-xl">
                  {business.name}
                </span>
                <button
                  onClick={() => navigate(`/business/${business._id}`)}
                  className="bg-[#8e2421] text-white py-1 px-2 mx-4 rounded-md font-extrabold"
                >
                  details
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
