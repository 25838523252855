import React, { useState } from "react";
import {
  addBankAccount,
  addBudget,
  addFixedCost,
  addFutureCost,
  updateBudget,
} from "../../../apiCalls/accountingCalls";
import AddBankAccount from "./AddBankAccount";
import AddFixedCost from "./AddFixedCost";
import AddFutureCost from "./AddFutureCost";
import BudgetName from "./BudgetName";
import SelectWorkingDays from "../Budget/SelectWorkingDays";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SetNetWorthGoal from "./SetNetWorthGoal";

export default function CreateNewBudget() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [budget, setBudget] = useState({});
  const [bankAccount, setBankAccount] = useState({});
  const [workingDays, setWorkingDays] = useState([]);
  const [date, setDate] = useState(new Date());

  const [state, setState] = useState({
    currency: "GBP",
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  const handleSelectWorkingDays = async (e) => {
    const currentIndex = workingDays.indexOf(e.target.value);
    const newWorkingDays = [...workingDays];

    if (currentIndex === -1) {
      newWorkingDays.push(e.target.value);
    } else {
      newWorkingDays.splice(currentIndex, 1);
    }
    setWorkingDays(newWorkingDays);
  };

  const handleDateSelect = (e) => {
    setDate(e.target.value);
  };

  const handleNext = async (e) => {
    e.preventDefault();
    let updatedData = {};
    // Budget name and currency
    if (activeStep === 0) {
      let newBudget = {
        name: state.budget_name,
        currency: state.currency,
      };
      let { new_budget } = await addBudget(newBudget);
      console.log(new_budget);
      setBudget(new_budget);
      setActiveStep(activeStep + 1);
    }
    // Add bank account
    if (activeStep === 1) {
      let date = new Date();
      let bankAccount = {
        name: state.bank_name,
        amount: state.bank_amount,
        start_amount: state.bank_amount,
        currency: state.currency,
        date,
      };
      let { bank_account } = await addBankAccount(budget._id, bankAccount);
      console.log(bank_account);
      setBankAccount(bank_account);
      setActiveStep(activeStep + 1);
    }
    // select working days
    if (activeStep === 2) {
      // updatedData.working_days = workingDays;
      // let res = await updateBudget(budget._id, updatedData);
      // console.log(res);
      setActiveStep(activeStep + 1);
    }
    // add fixed cost
    if (activeStep === 3) {
      console.log(bankAccount);
      let fixed_cost = {
        name: state.fixed_name,
        amount: state.fixed_amount,
        currency: budget.currency,
        date: date,
        from: bankAccount,
      };
      await addFixedCost(budget._id, fixed_cost);
      setActiveStep(activeStep + 1);
    }
    // add future cost
    if (activeStep === 4) {
      let future_cost = {
        name: state.future_name,
        amount: state.future_amount,
        currency: budget.currency,
        date: date,
        from: bankAccount,
      };
      await addFutureCost(budget._id, future_cost);
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 5) {
      updatedData.time = state.time;
      updatedData.timeframe = state.timeframe;
      updatedData.goal = state.goal;
      let res = await updateBudget(budget._id, updatedData);
      console.log(res);
      navigate("/accounting");
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <BudgetName
              state={state}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          </>
        );
      case 1:
        return (
          <>
            <AddBankAccount
              state={state}
              handleChange={handleChange}
              handleNext={handleNext}
            />
          </>
        );
      case 2:
        return (
          <>
            <SelectWorkingDays
              handleSelectWorkingDays={handleSelectWorkingDays}
              workingDays={workingDays}
              handleNext={handleNext}
              budget={budget}
            />
          </>
        );
      case 3:
        return (
          <>
            <AddFixedCost
              date={date}
              handleChange={handleChange}
              handleDateSelect={handleDateSelect}
              handleNext={handleNext}
            />
          </>
        );
      case 4:
        return (
          <>
            <AddFutureCost
              date={date}
              handleChange={handleChange}
              handleDateSelect={handleDateSelect}
              handleNext={handleNext}
            />
          </>
        );
      case 5:
        return (
          <SetNetWorthGoal
            budget={budget}
            handleNext={handleNext}
            handleChange={handleChange}
            state={state}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <div className="content budgets--wrapper">
      <div className="budget-card">
        <h1>Create new budget</h1>
        {getStepContent(activeStep)}
        {activeStep === 0 && (
          <button onClick={() => navigate("/accounting")}>Back</button>
        )}
      </div>
    </div>
  );
}
