import React, { useState } from "react";
import { Dialog } from "@mui/material";
import AddBankAccountDialog from "./AddBankAccountDialog";
import { deleteBankAccount } from "../../../../apiCalls/accountingCalls";
import EditBankAccount from "./EditBankAccount";

export default function BankAccountsDialog(props) {
  const { budget } = props;

  const [addBankAccountDialog, setAddBankAccountDialog] = useState(false);
  const [editBankAccountDialog, setEditBankAccountDialog] = useState(false);
  const [bankAccountSelected, setBankAccountSelected] = useState(false);

  const handleAddBankAccountDialog = () => {
    setAddBankAccountDialog(!addBankAccountDialog);
  };

  const handleEditBankAccountDialog = (account) => {
    if (account) {
      setBankAccountSelected(account);
    }
    setEditBankAccountDialog(!editBankAccountDialog);
  };
  return (
    <div>
      <AddBankAccountDialog
        open={addBankAccountDialog}
        onClose={handleAddBankAccountDialog}
        updateUser={props.updateUser}
        budget={budget}
        updateBudget={props.updateBudget}
      />
      <EditBankAccount
        open={editBankAccountDialog}
        onClose={handleEditBankAccountDialog}
        updateUser={props.updateUser}
        budget={budget}
        bankAccount={bankAccountSelected}
        updateBudget={props.updateBudget}
      />
      <Dialog
        PaperProps={{
          style: {
            background: "#e6e4e4",
            width: "100%",
            minWidth: "50%",
          },
        }}
        open={props.open}
        onClose={props.onClose}
      >
        <span className="table-title">
          <h1>
            Bank accounts {budget?.tot_bank_accounts} {budget?.currency}
          </h1>
          <button onClick={handleAddBankAccountDialog}>add</button>
        </span>
        {budget?.bank_accounts?.map((account) => (
          <>
            <span className="table-name">
              {account?.name}
              <button
                onClick={async () => {
                  await deleteBankAccount(budget._id, account._id);
                  await props.updateUser();
                  // props.updateBudget();
                }}
              >
                delete
              </button>
              <button onClick={() => handleEditBankAccountDialog(account)}>
                edit
              </button>
            </span>

            <span className="table-sub-name">
              Start amount:
              <span className="table-props-text">
                {account?.start_amount} {account?.currency}
              </span>
            </span>
            <span className="table-sub-name">
              Amount:
              <span className="table-props-text">
                {account?.amount} {account?.currency}
              </span>
            </span>
            <span className="table-sub-name">
              Date:
              <span className="table-props-text">
                {account?.formatted_date}
              </span>
            </span>
            {/* <span className="table-sub-name">
              Bank name:
              <span className="table-props-text">{account?.name}</span>
            </span> */}
            <br />
          </>
        ))}
      </Dialog>
    </div>
  );
}
